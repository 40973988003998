<template>
  <div class="registerData">
    <div class="title">Registration by July 15 is eligible for a discounted early bird fee. Please read the following registration instructions.</div>
    <div class="title2" style="margin-top: 40px;">Registration Fee</div>
    <RegisterPrice />
    <!-- <div class="title">The registration fees are priced in RMB as per the bank regulatory requirements. The EUR pricing is converted according to the exchange rate (0.14 CNY/EUR as of February 8, 2023) and is for reference only.</div> -->
    <div class="title2" style="margin-top: 40px;">Notes</div>
    <!-- <div class="txt2">	All attendees, including presenters and non-presenters, are required to register online and enter the conference venue with a conference pass.</div>
    <div class="title2">Accommodation</div> -->
    <!-- <div class="txt2">Accommodation is not included in registration fee, the delegates need reserve rooms themselves,we advise you reserve rooms as early as you can. Except the conference hotel, you are free to reserve rooms nearby .</div> -->
    <!-- <div class="title2">Notes</div> -->
    <div class="txt3">
      <div class="li">All attendees, including presenters and non-presenters, are required to register online and enter the conference venue with a conference pass. </div>
      <div class="li">The registration fee for on-site participation includes an access to the symposium, a symposium material package, lunch during the symposium, a welcome dinner, industrial tours, social events, the symposium proceedings, and a conference gift. The registration fee for online participation includes an online access to the symposium and the conference proceedings. Accommodation is not included; you may choose the partner hotel of the Organizing Committee at a discounted rate.</div>
      <div class="li">If registering as a presenter, please register with your paper information after the full paper has been accepted in approximately June 2023.</div>
      <div class="li">If registering as a student, please submit an electronic copy of a valid letter of enrollment, student ID, or other supporting documents. If the student is a presenter, you will be charged the student registration fee.</div>
      <div class="li">After completing the registration, international registrants may visit "My Account - Order" to view your order information and receipt. Chinese registrants may visit the CDHA platform to view registration results and obtain invoices.</div>
    </div>
    <div class="title2" style="margin-top: 40px;">Registration Process</div>
    <div class="title3box">
      <div class="box">
        <div class="item">Read the Registration instructions
          <span class="right"></span>
        </div>
        <div class="item">Click on the registration link at the bottom
          <span class="right"></span>
        </div>
        <div class="item">Fill in personal information
          <span class="right"></span>
        </div>
        <div class="item">Make a payment
          <span class="right"></span>
        </div>
        <div class="item">Check payment records
          <span class="right"></span>
        </div>
      </div>
    </div>
    <div class="title2" style="margin-top: 40px;">Payment Methods</div>
    <div class="txt5">
      <div class="txt5title">International registrants:</div>
      <div class="txt5text">
        <div class="txt5textImg"></div>
        <div class="txt5textImg"></div>
      </div>
      <div class="txt5title" style="margin-top: 20px;">Chinese registrants:</div>
      <div class="txt5text">Pay with WeChat Pay or transfer to public account through the CDHA platform.</div>
    </div>
    <div class="title2" style="margin-top: 40px;">Cancellation & Refund Policy</div>
    <div class="txt3">
      <div class="li">For cancellations submitted before July 15 2023, the registration fee will be refunded with a ¥ 400 cancellation fee deducted. </div>
      <div class="li">Cancellations submitted between July 1 and August 30 2023 will be eligible for a refund of 50% of the paid registration fee.</div>
      <div class="li">For cancellations after September 1 2023, no refunds can be made.</div>
      <div class="li">Bank administration fees and credit card fees will not be refunded.</div>
    </div>
    <div class="title4box">
      <div class="title4btn" @click="toReg">Registration Entrance</div>
    </div>
  </div>
</template>

<script>

import RegisterPrice from './price.vue';

export default {
  name: 'RegisterData',
  data() {
  },
  props: {},
  components: {
    RegisterPrice
  },
  setup() {
  
  },
  methods: {
    toReg() {
      // return
      this.$router.push(
          {
            path: '/registration/pre'
          }
        );
    }
  }

  
}
</script>

<style lang="less" scoped>
.registerData {
  width: 100%;
  .title {
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    font-size: 20px;
    line-height: 48px;
    padding: 10px;
    margin-top: 12px;
  }
  .title2 {
    position: relative;
    color: rgba(0, 123, 101, 1);
    font-family: OPPOSans-Heavy;
    font-size: 20px;
    line-height: 44px;
    height: 44px;
    padding-left: 32px;
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      background: rgba(0, 192, 153, 1);
      border-radius: 50%;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .txt2 {
    width: 1280px;
    background: rgba(251, 250, 250, 1);
    padding: 20px 32px;
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  .txt5 {
    width: 1280px;
    padding: 20px 32px;
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    line-height: 28px;
    .txt5title {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .txt5text {
      font-size: 19px;
      margin-top: 20px;
      .txt5textImg {
        width: 200px;
        height: 100px;
        display: inline-block;
        &:nth-child(1) {
          background-image: url('../../assets/img/register/1.png');
        }
        &:nth-child(2) {
          background-image: url('../../assets/img/register/2.png');
        }
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
      }
    }
  }
  .txt3 {
    width: 1280px;
    background: rgba(251, 250, 250, 1);
    padding: 20px 32px;
    .li {
      position: relative;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      word-spacing: 2px;
      color: rgba(102, 102, 102, 1);
      padding-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      &::before {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: rgba(255, 204, 0, 1);
      }
    }
  }
  .title3box {
    margin-top: 40px;
    .box {
      display: flex;
      .item {
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        width: 200px;
        height: 100px;
        line-height: 20px;
        text-align: center;
        background: #00c099;
        padding: 0 50px;
        overflow: hidden;
        color: #fff;
        &::before {
          content: '';
          position: absolute;
          left: -70px;
          top: 0;
          width: 100px;
          height: 100px;
          transform: rotate(45deg);
          background: #fff;
        }
        &::after {
          content: '';
          position: absolute;
          left: 150px;
          top: 0;
          width: 100px;
          height: 100px;
        }
        .right {
          position: absolute;
          left: 150px;
          top: 0;
          width: 50px;
          height: 100px;
          z-index: 99;
          background: #fff;
          overflow: hidden;
          &::before {
            content: '';
            position: absolute;
            left: -70px;
            top: 0;
            width: 100px;
            height: 100px;
            transform: rotate(45deg);
            background: #00c099;
          }
        }
      }
    }
  }
  .title4box {
    margin-top: 40px;
    .title4btn {
      cursor: pointer;
      // pointer-events: none;
      // cursor: not-allowed;
      margin: 0 auto;
      width: 600px;
      text-align: center;
      height: 60px;
      line-height: 60px;
      background: #00c099;
      // background: #dedede;
      border-radius: 10px;
      color: #fff;
      font-size: 25px;
    }
  }
}
</style>
