<template>
  <div class="inforNewsCard">
    <div class="card" v-for="(item, i) in data" :key="i">
      <div class="imgBox">
        <div class="date1">{{momentFn(item.date, 'DD')}}</div>
        <div class="date2">{{momentFn(item.date, 'YYYY-MM')}}</div>
      </div>
      <div class="box" @click="handle(item.id)">
        <div class="title">{{item.title}}</div>
        <div class="txt home--news__list-text" v-html="unescapeHtmlEntities(item.txt)"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  name: 'InforNewsCard',
  props: {
    changeTab: {
      type: Function
    },
    data: {
      type: Array
    }
  },
  methods: {
    handle(id) {
      this.changeTab(id);
    },
    momentFn(date, format) {
      return this.$moment(date).format(format);
    },
    unescapeHtmlEntities(str) {
      const tempEle = document.createElement("div");
      tempEle.innerHTML = str;
      return tempEle.innerText;
    }
  }
}
</script>

<style lang="less" scoped>
.inforNewsCard {
  padding-bottom: 40px;
  .card {
    display: flex;
    width: 1280px;
    height: 152px;
    border-radius: 8px;
    margin-top: 40px;
    &:nth-of-type(1){
      margin-top: 0;
    }
    .imgBox {
      width: 152px;
      height: 152px;
      border-radius: 8.7px;
      background: rgba(0, 192, 153, 1);
      text-align: center;
      color: rgba(255, 255, 255, 1);
      .date1 {
        font-weight: bold;
        font-size: 48px;
        line-height: 52.2px;
        margin-top: 38px;
      }
      .date2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 30.5px;
        margin-top: 10px;
      }
    }
    .box {
      flex: 1;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      margin-left: 20px;
      cursor: pointer;
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans-Heavy;
        font-size: 24px;
        line-height: 32px;
      }
      .txt {
        color: rgba(102, 102, 102, 1);
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        margin-top: 8px;
        overflow: hidden; // 溢出隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        display:-webkit-box;// 作为弹性伸缩盒子模型显示。
        -webkit-box-orient:vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
        -webkit-line-clamp: 2; // 显示的行数
      }
    }
  }
}
</style>
