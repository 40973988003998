<template>
  <div class="paperPublication">
    <div class="headerTitle">Selected papers will be invited to prepare an extended version and be recommended to SCIE-indexed journals including Building Simulation. In addition, selected papers will be offered the opportunity to increase their impact in China. With the authors' consent, the papers will be potentially translated into Chinese and published in core Chinese journals.</div>
    <div class="headerTitleSub">Building Simulation</div>
    <div class="contentBox">
      <div class="left"><span style="font-style: oblique;">Building Simulation: An International Journal</span> publishes original, high quality, peer-reviewed research papers and review articles dealing with modeling and simulation of buildings including their systems. The goal is to promote the field of building science and technology to such a level that modeling will eventually be used in every aspect of building construction as a routine instead of an exception. Of particular interest are papers that reflect recent developments and applications of modeling tools and their impact on advances of building science and technology.</div>
      <div class="right"></div>
    </div>
    <div class="contentBootomBox">
      <p>2021 Journal Impact Factor: 4.008</p>
      <p>Indexed in: Science Citation Index Expanded</p>
      <p>(SCIE), EI Compendex, etc.</p>
      <p><a href="https://www.springer.com/journal/12273">Journal website</a></p>
    </div>
    <!--  -->
    <div class="headerTitleSub">District Heating (In Chinese)</div>
    <div class="contentBox contentBox2">
      <div class="left"><span style="font-style: oblique;">District Heating</span> is the official journal of China District Heating Association (CDHA), a technical publication with domestic and international circulation, founded in 1982. The magazine aims to publicize the national policies on the construction of heating facilities, introduce the research and application of advanced technologies, new materials, new equipment, and new technologies in heat production and distribution at home and abroad, and publish articles on urban heating planning, design, construction and management. Its mission is to promote energy conservation, reduce environmental pollution and improve people's living environment.</div>
      <div class="right"></div>
    </div>
    <div class="contentBootomBox">
      <p>2022 Composite Impact Factor: 0.658</p>
      <p>Indexed in: CNKI, Wanfang, etc.</p>
      <p><a href="https://navi.cnki.net/knavi/journals/QYGR/detail?uniplatform=NZKPT">Journal website</a></p>
    </div>
    <!--  -->
    <div class="content">
      <div class="card" v-for="(item, i) in publication" :key="i">
        <div class="imgBox">
          <img :src="item.covers" alt="">
        </div>
        <div class="right">
          <div class="title">{{item.title}}</div>
          <div class="txt">{{item.subtitle}}</div>
          <div class="site">{{item.summary}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperPublication',
  props: {
    publication: Array
  },
  components: {
  }
}
</script>

<style lang="less" scoped>
  .paperPublication {
    width: 1280px;
    margin: 0 auto;
    .headerTitle {
      text-align: justify;
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      margin-top: 40px;
    }
    .headerTitleSub {
      margin-top: 40px;
      color: #00c099;
      font-size: 26px;
      font-family: OPPOSans-Heavy;
      font-weight: bold;
    }
    .contentBootomBox {
      padding: 0 140px;
      font-size: 20px;
      color: #00c099;
      font-family: OPPOSans-Heavy;
      font-weight: bold;
      a {
        text-decoration: none;
        // color: #00c099;
      }
    }
    .contentBox2 {
      .right {
        width: 335px;
        height: 449px;
        background: url('../../../assets/img/paper/222.png') no-repeat 100% !important;
      }
    }
    .contentBox {
      display: flex;
      margin-top: 20px;
      padding: 0 100px;
      .left {
        font-size: 20px;
        flex: 1;
        margin-right: 100px;
        line-height: 46px;
      }
      .right {
        width: 335px;
        height: 449px;
        background: url('../../../assets/img/paper/111.png') no-repeat 100%;
      }
    }
    .content {
      display: inline-block;
      width: 100%;
    }
    .card {
      display: flex;
      width: 1280px;
      height: 374px;
      margin-top: 8px;
      padding: 24px;
      &:nth-of-type(1) {
        margin-top: 32px;
      }
      .imgBox {
        width: 233px;
        height: 326px;
        background: rgba(229, 220, 220, 1);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      }
      .right {
        flex: 1;
        margin-left: 24px;
        .title {
          margin-top: 31px;
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 32px;
          line-height: 32px;
        }
        .txt {
          margin-top: 20px;
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 20px;
          line-height: 32px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          padding-bottom: 16px;
        }
        .site {
          margin-top: 20px;
          color: rgba(102, 102, 102, 1);
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
</style>
