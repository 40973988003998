<template>
  <div v-html="formData"></div>
</template>
<script lang="js">
export default {
  name: 'postOrderPage',
  data () {
    return {
      formData: ''
    }
  },
  mounted () {
    this.formData = this.$route.query.formData
    this.$nextTick(() => {
      document.forms[0].submit()
    })
  }
}
</script>
