import { createRouter, createWebHistory } from "vue-router";
// import Cookies from "js-cookie";
import  { store } from '../store/';
// import { refreshToken } from '../api/login';
//
import Home from "../pages/home/index";
import Receipt from "../pages/receipt/index";
import Register from "../pages/register/index";
import RegisterUser from "../pages/register/register";
import RegisterOrder from "../pages/register/registerOrder";
import RegisterPre from "../pages/register/registerPre";
import RegisterNext from "../pages/register/registerNext";
import Login from "../pages/login/index";
import About from "../pages/about/index";
import Committee from "../pages/dhc/index";
import Programs from "../pages/event/index";
import Information from "../pages/information/index";
import InformationNews from "../pages/information/news";
import Papers from "../pages/paper/index";
import Personal from "../pages/personal/index";
import Venues from "../pages/venues/index";
import Cooperation from "../pages/cooperation/index";
import PersonalCancelOrder from "../pages/personal/cancelorder/index";
import PostPage from "../pages/register/postPage";
// import { createGuard } from "./guard";
const routes = [
  { path: "/", component: Home, meta: { requiresAuth: false, title: "Home" } },
  { path: "/receipt", component: Receipt, meta: { requiresAuth: false, title: "Receipt" } },
  { path: "/about", component: About, meta: { requiresAuth: false, title: "About" } },
  { path: "/committee", component: Committee, meta: { requiresAuth: false, title: "Committee" } },
  { path: "/programs", component: Programs, meta: { requiresAuth: false, title: "Programs" } },
  { path: "/information", component: Information, meta: { requiresAuth: false, title: "Information" } },
  { path: "/information_news", component: InformationNews, meta: { requiresAuth: false, title: "InformationNews" } },
  { path: "/papers", component: Papers, meta: { requiresAuth: false, title: "Papers" } },
  { path: "/personal", component: Personal, meta: { requiresAuth: true, title: "Personal" } },
  { path: "/personal/cancelorder", component: PersonalCancelOrder, meta: { requiresAuth: true, title: "PersonalCancelOrder" } },
  { path: "/registration", component: Register, meta: { requiresAuth: false, title: "Registration" } },
  { path: "/registration/user", component: RegisterUser, meta: { requiresAuth: false, title: "Registration" } },
  { path: "/registration/order", component: RegisterOrder, meta: { requiresAuth: false, title: "Registration" } },
  { path: "/registration/postpage", component: PostPage, meta: { requiresAuth: false, title: "Registration" } },
  { path: "/registration/pre", component: RegisterPre, meta: { requiresAuth: false, title: "Registration" } },
  { path: "/registration/next", component: RegisterNext, meta: { requiresAuth: true, title: "Registration" } },
  { path: "/login", component: Login, meta: { requiresAuth: false, title: "Login" } },
  { path: "/venues", component: Venues, meta: { requiresAuth: false, title: "Venues" } },
  { path: "/cooperation", component: Cooperation, meta: { requiresAuth: false, title: "Cooperation" } }
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
console.log('store', store)
// 新增路由首卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'DHC2023';
  const hasToken = Boolean(store.state?.user?.token);
  // const freshToken = Cookies.get('framework-refresh-token');
  // Boolean(freshToken) && to.path !== '/login' && (async () => {
  //   await refreshToken(freshToken);
  // })();
  if (to.meta.requiresAuth && !hasToken) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
});
//
export function setupRouter(app) {
  app.use(router);
  // createGuard(router);
}

export default router;
