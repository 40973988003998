<template>
  <div class="inforDownload">
    <div class="card" v-for="(item, i) in download" :key="i">
      <div class="left">
        <img :src="item.images" alt="">
      </div>
      <div class="center">
        <div class="title">{{item.title}}</div>
        <div class="desc"></div>
      </div>
      <div class="right">
        <a class="btnA" :href="item.files" :download="item.title">
          <span class="icon"></span>
        </a>
      </div>
  
      </div>
  </div>
</template>

<script>
export default {
  name: 'InforDownload',
  props: {
    download: Array
  }
}
</script>

<style lang="less" scoped>
.inforDownload {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 40px 0;
  margin-left: 20px;
  .card {
    display: flex;
    align-items: center;
    width: 628px;
    height: 104px;
    border-radius: 8px;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: rgba(251, 250, 250, 1);
    padding: 24px;
    margin-top: 24px;
    &:hover {
      border: 1px solid rgba(0, 192, 153, 1);
    }
    &:nth-of-type(1), &:nth-of-type(2) {
      margin-top: 0;
    }
    &:nth-of-type(2n+1) {
      margin-right: 24px;
    }
    .left, .left img {
      width: 56px;
      height: 56px;
    }
    .center {
      width: 452px;
      height: 52px;
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
      }
      .desc {
        color: rgba(153, 153, 153, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        margin-top: 16px;
      }
    }
    .left {
      margin-right: 16px;
    }
    .right {
      .icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url('../../../assets/img/infor/icon_download11@3x.png') no-repeat;
        background-size: 100%;
      }
    }
  }
  .btnA {
    text-decoration: none;
    color: white;
  }
}
</style>
