<template>
  <div class="paperUpload">
    <div class="txt">
      DHC2023 onference regular registration will be opened between 19th of April 2023 and 10th of August 2023. If registering before 1st of July general participants qualify for a reduced early bird fee.
    </div>
    <div class="padding">
      <div class="title">Please upload your paper</div>
      <div class="upload">
        <div class="left">
          <div class="box">
            <div class="img">
              <img :src="require('../../../assets/img/paper/icon_上传文件@3x.png')" alt="">
            </div>
            <div class="leftTitle">Drag & Drop your files here</div>
            <div class="leftTxt">OR</div>
            <input type="file" class="input" id="upload_file">
            <label class="btn" htmlFor="upload_file">Browse Files</label>
          </div>
        </div>
        <div class="right">
          <div class="rightTitle">Upload progress</div>
          <div class="rightBox">
            <div class="img">
              <img :src="require('../../../assets/img/paper/icon_PDF@3x.png')" alt="">
            </div>
            <div class="content">
              <div class="up">
                <div class="upTitle">My Paper.pdf</div>
                <div class="upSize">56%</div>
              </div>
              <div class="down"></div>
            </div>
            <div class="close">
              <img :src="require('../../../assets/img/paper/取消@3x.png')" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 32px;">Please leave your email</div>
    <el-input class="text" type="textarea" placeholder="Fill in your email address"></el-input>
    <div class="btnBox">
      <div class="btn">Confirm & Submission</div>
    </div>
    <div class="bottom">
      <div class="bottomTitle">Notice of Paper</div>
      <div class="bottomTxt">Paper presenters must register within two weeks after the acceptance of the paper to receive the discounted fee.</div>
      <div class="bottomTxt">Accompanying persons may register through the registration category (General, Presenter, Student) of the principal applicant. They will have access to all the facilities available to the principal applicant.</div>
      <div class="bottomTxt">The number of participants that can join the symposium in person is limited. Please register ASAP to save your seat.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperUpload'
}
</script>

<style lang="less" scoped>
  .paperUpload {
    width: 1280px;
    margin: 0 auto;
    .padding {
      margin: 0 10px;
    }
    .title {
      position: relative;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      padding-left: 32px;
      margin-top: 20px;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        display: inline-block;
        transform: translateY(-50%);
        content: '';
        width: 16px;
        height: 16px;
        background: rgba(0, 192, 153, 1);
        border-radius: 50%;
      }
    }
    .txt {
      margin-top: 28px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
    }
    .upload {
      margin-top: 16px;
      display: flex;
      .left, .right {
        flex: 1;
        width: 640px;
        height: 248px;
        border-radius: 8px;
        box-sizing: border-box;
      }
      .left {
        border: 1px dashed rgba(0, 122, 255, 1);
        box-sizing: border-box;
        background: rgba(0, 122, 255, 0.08);
        margin-right: 40px;
        .box {
          width: 287px;
          height: 188px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 30px auto;
          flex-direction: column;
          .img {
            width: 64px;
            height: 64px;
          }
          .leftTitle {
            text-align: center;
            color: rgba(0, 192, 153, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            margin-top: 20px;
          }
          .leftTxt {
            text-align: center;
            color: rgba(0, 192, 153, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            margin: 12px auto;
          }
          .btn {
            width: 154px;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            text-align: center;
            background: rgba(0, 192, 153, 1);
            box-shadow: 0px 4px 16px rgba(0, 119, 95, 0.4);
            color: rgba(255, 255, 255, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 16px;
            cursor: pointer;
          }
          .input {
            display: none;
          }
        }
      }
      .right {
        background: rgba(0, 192, 153, 0.12);
        padding: 32px;
        .rightTitle {
          color: rgba(153, 153, 153, 1);
          font-size: 20px;
          line-height: 24px;
        }
        .rightBox {
          height: 64px;
          width: 100%;
          display: flex;
          margin-top: 64px;
          align-items: center;
          .img {
            width: 56px;
            height: 56px;
          }
          .content {
            flex: 1;
            margin: 0 20px;
            .up {
              display: flex;
              .upTitle {
                display: inline-block;
                color: rgba(51, 51, 51, 1);
                font-family: OPPOSans;
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
              }
              .upSize {
                margin-left: auto;
                display: inline-block;
                color: rgba(0, 192, 153, 1);
                font-family: OPPOSans-Heavy;
                font-size: 16px;
                line-height: 16px;
              }
            }
            .down {
              width: 100%;
              position: relative;
              height: 7.6px;
              border-radius: 19.3px;
              background: rgba(0, 192, 153, 0.16);
              margin-top: 16px;
              &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 166px;
                height: 7.6px;
                border-radius: 21.1px;
                background: rgba(0, 192, 153, 1);
              }
            }
          }
          .close {
            width: 26.7px;
            height: 26.7px;
            color: rgba(0, 192, 153, 0.32);
          }
        }
      }
    }
    .text {
      margin-top: 16px;
      /deep/ .el-textarea__inner {
        width: 1280px;
        height: 156px;
        border: 1px dashed rgba(0, 192, 153, 1);
        box-sizing: border-box;
        background: rgba(0, 192, 153, 0.08);
        padding: 20px 32px;
          &::placeholder {
            font-weight: 500;
            font-size: 16px;
            line-height: 32px;
          }
      }
    }
    .btnBox {
      margin-top: 56px;
      text-align: center;
      .btn {
        display: inline-block;
        width: 359px;
        height: 56px;
        line-height: 56px;
        border-radius: 8px;
        background: rgba(0, 192, 153, 1);
        box-shadow: 0px 4px 24px rgba(0, 119, 95, 0.24);
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .bottom {
      margin-top: 56px;
      margin-bottom: 112px;
      width: 1280px;
      height: 252px;
      border-radius: 8px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      .bottomTitle {
        color: rgba(51, 51, 51, 1);
        font-size: 20px;
        line-height: 40px;
      }
      .bottomTxt {
        position: relative;
        margin-top: 12px;
        padding-left: 60px;
        color: rgba(102, 102, 102, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        &::before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 25px;
          background: rgba(0, 192, 153, 1);
          border-radius: 50%;
        }
      }
    }
  }
</style>
