<template>
  <div class="homeHeader">
    <HeaderHome :class="isMenu ? 'isMenu homeHeaderTxt' : 'homeHeaderTxt'" id="home_header_txt"/>
    <video
      id="home_header_video"
      class="bg"
      src="../../assets/home.mp4?ver=1.0"
      preload="auto"
      autoPlay
      muted
      @canplay="canplay"
      @loadstart="loadHandle"
      @durationchange="durationchange"
      controlsList="nofullscreen"
    ></video>
  </div>
  <div class="haibao" ref="haibao">
    <div class="up" v-if="haibao1">
      <div class="imgBox" @click="tolink">
        <img :src="require('../../assets/img/home/Frame_483@3x.png')" alt="">
      </div>
      <div class="iconBox">
        <span class="icon" @click="close('haibao1')"></span>
      </div>
    </div>
    <div class="down" v-if="haibao2">
      <div class="imgBox">
        <a class="tipTxtA" href="./Call for papers_ex_dhc2023.pdf" download="Call for papers_ex_dhc2023.pdf"><img :src="require('../../assets/img/home/下载海报@3x.png')" alt=""></a>
      </div>
      <div class="iconBox">
        <span class="icon" @click="close('haibao2')"></span>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderHome from '../../components/header/home.vue';

export default {
  name: 'HomeHeader',
  components: {
    HeaderHome
  },
  data() {
    return {
      haibao1: true,
      haibao2: true,
      isMenu: false
    };
  },
  mounted() {
    this.$nextTick(function () {
      // this.changeTop();
    });
    window.addEventListener('resize', this.changeTop);
  },
  props: {},
  beforeUnmount() {
    window.removeEventListener('resize', this.changeTop);
  },
  methods: {
    tolink() {
      this.$router.push({path: '/cooperation'});
    },
    changeTop() {
      this.isMenu = true;
      const source = document.getElementById('home_header_video');
      const target = document.getElementById('home_header_txt');
      this.$refs['haibao'].style.top = source.offsetHeight + 'px';
      console.log('source.offsetHeight', source.offsetHeight)
      target.style.top = `${source.offsetHeight * 380 / 546}px`;
      target.style.transform = `translate(-50%, 0) scale(${source.offsetHeight / 546})`;
      target.style.transformOrigin = 'top';
    },
    canplay() {
      this.changeTop();
    },
    durationchange() {
    },
    loadHandle() {
    },
    close(key) {
      if (key === 'haibao1') {
        this.haibao1 = false;
      } else {
        this.haibao2 = false;
      }
    }
  }
}
</script>

<style scoped lang="less">
.haibao {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  .up, .down {
    .imgBox {
      cursor: pointer;
    }
    .imgBox, .imgBox img {
      width: 176px;
      height: 220px;
    }
    .iconBox {
      margin-top: 12px;
      width: 100%;
      height: 32px;
      text-align: center;
      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url('../../assets/img/home/downicon.png') no-repeat;
        background-size: 100%;
        cursor: pointer;
      }
    }
  }
  .up {
    margin-bottom: 24px;
  }
}
  .homeHeader {
    position: relative;
    line-height: 0;
    .bg {
      width: 100%;
      height: inherit;
    }
    .homeHeaderTxt {
      visibility: inherit;
      position: absolute;
      top: 359px;
      left: 50%;
      transform: translate(-50%, 0) scale(0);
      z-index: 1000;
      &.isMenu {
        visibility: visible;
      }
    }
  }
</style>
