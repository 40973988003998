<template>
  <div class="menuIndex">
    <div
      :class="tabKey === item.key ? 'active title' : 'title'"
      v-for="(item, i) in data"
      :key="i"
      @click="changeTabKey(item.key)" >{{item.title}}</div>
  </div>
</template>

<script>
export default {
  name: 'MenuIndex',
  props: {
    data: Array,
    tabKey: String,
    tabMargin: String,
    changeTab: Function
  },
  methods: {
    changeTabKey(key){
      this.changeTab(key);
    }
  }
}
</script>

<style lang="less" scoped>
.menuIndex {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 1280px;
  height: 128px;
  margin: 32px auto;
  padding: 16px 88px;
  background: rgba(0, 192, 153, 0.08);
  .title {
    display: inline-block;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    cursor: pointer;
    &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
      margin-bottom: 32px;
    }
    &:nth-of-type(3n + 1) {
      width: 262px;
      margin-right: 184.5px;
      text-align: left;
    }
    &:nth-of-type(3n + 2) {
      text-align: center;
      width: 312px;
      margin-right: 184.5px;
    }
    &:nth-of-type(3n + 3) {
      text-align: right;
      width: 161px;
    }
    &.active {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
    }
  }
}
</style>
