<template>
  <div class="eventTexhnology">
    <!-- <Card :data="data" /> -->
    <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div>
  </div>
</template>

<script>
// import Card from '../../../components/card/paper_scientific.vue';

export default {
  name: 'EventTexhnology',
  data() {
    return {
      data: [
        {
          img: require('../../../assets/po/Frame_26@3x.png'),
          title: 'Nigel Wright',
          txt: 'Beijing Heat Group Co., Ltd. is a wholly state-owned company approved by the municipal government. It was formally established on June 6, 2000……',
          city: 'Nottingham Trent University',
          site: require('../../../assets/img/city/6.png')
        },
         {
          img: require('../../../assets/po/Frame_26@3x.png'),
          title: 'Nigel Wright',
          txt: 'Associate Dean for Research',
          city: 'Nottingham Trent University',
          site: require('../../../assets/img/city/6.png')
        },
         {
          img: require('../../../assets/po/image-2@3x.png'),
          title: 'Nigel Wright',
          txt: 'Associate Dean for Research',
          city: 'Nottingham Trent University',
          site: require('../../../assets/img/city/6.png')
        },
         {
          img: require('../../../assets/po/image-2@3x.png'),
          title: 'Nigel Wright',
          txt: 'Associate Dean for Research',
          city: 'Nottingham Trent University',
          site: require('../../../assets/img/city/6.png')
        }
      ]
    }
  },
  components: {
    // Card
  }
}
</script>

<style lang="less" scoped>
.eventTexhnology {
  width: 1280px;
  margin: 40px auto 80px;
  .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
}
</style>
