<template>
  <HeaderIndex />
  <BgMenu title="Registration" :uri="require('../../assets/img/register/register.png')" />
  <div class="registerIndex">
    <!-- <TitleIndex title="Register of Instructions" /> -->
    <RegisterData />
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
// import TitleIndex from '../../components/title/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import RegisterData from './data.vue';

export default {
  name: 'RegisterIndex',
  props: {},
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    // TitleIndex,
    RegisterData
  }
}
</script>

<style lang="less" scoped>
.registerIndex {
  width: 1280px;
  margin: 40px auto;
  .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
}
</style>
