<template>
  <HeaderIndex />
  <BgMenu title="Programs" :uri="require('../../assets/img/event/Event.png')" />
  <Menu :data="data" :changeTab="changeTab" :tabKey="tabKey" :tabMargin="tabMargin" />
  <div class="main">
    <Schedule v-if="tabKey === 'schedule'" :schedule="schedule" />
    <Opening v-if="tabKey === 'opening'" />
    <Sessions v-if="tabKey === 'sessions'" />
    <Industrial v-if="tabKey === 'industrial'" />
    <Technology v-if="tabKey === 'technology'" />
    <Social v-if="tabKey === 'social'" />
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import Menu from '../../components/menu/event.vue';
//
import Schedule from './schedule/index.vue';
import Opening from './opening/index.vue';
import Sessions from './sessions/index.vue';
import Industrial from './industrial/index.vue';
import Technology from './technology/index.vue';
import Social from './social/index.vue';
//
import axios from 'axios';
import { getPageInfo } from '../../api/data';

export default {
  name: 'EventIndex',
  data() {
    return {
      tabKey: 'schedule',
      tabMargin: '131.3px',
      data: [
        {
          title: 'Schedule Overview',
          key: 'schedule'
        },
        {
          title: 'Opening & Keynotes',
          key: 'opening'
        },
        {
          title: 'Sessions',
          key: 'sessions'
        },
        {
          title: 'Technology Exhibition',
          key: 'technology'
        },
        {
          title: 'Industrial Tour',
          key: 'industrial'
        },
        {
          title: 'Social Events',
          key: 'social'
        }
      ],
      schedule: []
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
    Schedule,
    Opening,
    Sessions,
    Industrial,
    Technology,
    Social
  },
  beforeMount() {
    axios.all([
      getPageInfo('programs-schedule'),
    ]).then(axios.spread((schedule) => {
     this.schedule = schedule.records
    }));
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 32px auto;
}
</style>
