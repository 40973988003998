<template>
  <div class="menu">
    <div class="container">
      <div :class="key === item.router ? 'active txt' : 'txt'" v-for="(item, i) in link" :key="i" @click="handle(item.router)">{{item.txt}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  data() {
    return {
      link: [
        {
        txt: 'Home',
        router: '/'
      },{
        txt: 'Committee',
        router: '/committee'
      },{
        txt: 'Papers',
        router: '/papers'
      },{
        txt: 'Programs',
        router: '/programs'
      },{
        txt: 'Registration',
        router: '/registration'
      },{
        txt: 'Venues',
        router: '/venues'
      }
      // ,{
      //   txt: 'DHC2023',
      //   router: '/dhc'
      // }
      ,{
        txt: 'Information Center',
        router: '/information'
      }
      // ,{
      //   txt: 'About Beijing',
      //   router: '/about'
      // }
    ],
      key: ''
    };
  },
  mounted() {
    this.key = this.$route.path;
  },
  props: {},
  methods: {
    handle(key) {
      this.$router.push(
        {
          path: key
        }
      );
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  display: inline-block;
  width: 100%;
  height: 80px;
  .container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 1320px;
    height: 76px;
    margin: 0 auto;
    box-sizing: border-box;
    .txt {
      display: inline-block;
      margin: 0 8px;
      color: rgba(0, 192, 153, 1);
      // font-family: OPPOSans-Heavy;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      padding: 10px 24px;
      &.active {
        height: 40px;
        border-radius: 4px;
        background: rgba(0, 192, 153, 1);
        color: #ffffff;
      }
    }
  }
}
</style>
