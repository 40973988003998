<template>
  <div class="aboutEnergyplan">
    <div class="text1">Recently, the National Development and Reform Commission and the National Planning and Energy Administration issued the "14th Five-Year Plan" Energy System (the following modern version of the "Planning").
The "Planning" clearly points out that after development and security, we must break the situation and seek all-out efforts to ensure security first, ensure that the mountains and forests can guarantee modern energy construction, and practice coping capabilities. Clear waters and lush mountains are gold, silver and silver mines. Adhering to the concept of ecological priority, low-carbon and green development, terraced fields adjust the energy structure, and at the same time promote energy security and carbon transformation.
"Daily Economic News" million reporters noticed that the "Planning" put forward major indicators: by 2025, the domestic energy comprehensive annual reaches more than 46 tons of standard coal; the proportion of non-fossil energy consumption will increase to about 20%; GDP is 13.5% every morning. .</div>
    <div class="imageBox">
      <ImageGallery :data="data" />
    </div>
    <div class="text2">practice coping capabilities. Clear waters and lush mountains are gold, silver and silver mines. Adhering to the concept of ecological priority, low-carbon and green development, terraced fields adjust the energy structure, and at the same time promote energy security and carbon transformation.
"Daily Economic News" million reporters noticed that the "Planning" put forward major indicators: by 2025, the domestic energy comprehensive annual reaches more than 46 tons of standard coal; the proportion of non-fossil energy consumption will increase to about 20%; GDP is 13.5% every morning. .</div>
    <div class="text3">
"Daily Economic News" million reporters noticed that the "Planning" put forward major indicators: by 2025, the domestic energy comprehensive annual reaches more than 46 tons of standard coal; the proportion of non-fossil energy consumption will increase to about 20%; GDP is 13.5% every morning. .</div>
  </div>
</template>

<script>
import ImageGallery from '../../../components/image/gallery.vue';

export default {
  name: 'AboutEnergyplan',
  data() {
    return {
     data: [
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/5a6c5c9476a6ea10.jpeg',
          title: '1'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/8f664b54edbc0bec.jpg',
          title: '2'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/3272abbc43985ae4.jpg',
          title: '3'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/1882b5a7725466e6.png',
          title: '4'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/e0550375c1d4077c.jpg',
          title: '5'
        }
      ],
    };
  },
  components: {
    ImageGallery
  }
}
</script>

<style lang="less" scoped>
.aboutEnergyplan {
  .text1, .text2, .text3 {
    color: rgba(51, 51, 51, 1);
    font-family: OPPOSans;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    padding: 0 10px;
  }
  .text3 {
    margin-top: 50px;
  }
  .imageBox {
    width: 1320px;
    height: 1012px;
    margin: 24px auto 40px;
  }
}
</style>
