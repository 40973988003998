<template>
  <div class="dHCSpeech">
    <div class="down">
      <div class="right">
        <img class="img" :src="welcome[0].images" alt="">
        <div class="title">
          <div class="header">{{welcome[0].title.split('\\n')[0]}}</div>
          <div class="footer">{{welcome[0].title.split('\\n')[1]}}</div>
        </div>
      </div>
      <div class="left dhc--speech__content-txt">
        <div class="txt" v-html="welcome[0].content"></div>
      </div>
    </div>
    <div class="up">
      <div class="left dhc--speech__content-txt">
        <div class="txt" v-html="welcome[1].content"></div>
      </div>
      <div class="right">
        <img class="img" :src="welcome[1].images" alt="">
        <div class="title">
          <div class="header">{{welcome[1].title.split('\\n')[0]}}</div>
          <div class="footer">{{welcome[1].title.split('\\n')[1]}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DHCSpeech',
  props: {
    welcome: Array
  }
}
</script>

<style lang="less" scoped>
.dHCSpeech {
  margin: 32px auto 112px;
  .up, .down {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    .img {
      width: 340px;
      height: 358px;
      border-radius: 4px;
      overflow: hidden;
    }
    .left {
      flex: 1;
      border-radius: 8px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
      }
    }
    .right {
      width: 340px;
      height: 498px;
      margin-left: 24px;
      .title {
        margin-top: 20px;
        padding: 0 16px;
        text-align: center;
        .header {
          color: rgba(51, 51, 51, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
        }
        .footer {
          color: rgba(0, 192, 153, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
          margin-top: 12px;
        }
      }
    }
  }
  .down {
    .right {
      margin-left: 0;
    }
    .left {
      margin-left: 24px;
    }
  }
  .up {
    margin-top: 32px;
  }
}
</style>
