<template>
  <HeaderIndex />
  <BgMenu title="Registration" :uri="require('../../assets/img/register/register.png')" />
  <div class="registerUser">
    <TitleIndex title="Registration" />
    <div class="header">
      <div class="title">Hi,Dear</div>
      <div class="text">Please fill in the corresponding information</div>
    </div>
    <el-form
      :model="fromData"
      :rules="rules"
      ref="fromDataRef">
      <el-form-item prop="email">
        <div class="center">
          <div class="text">Please fill in your email address</div>
          <el-input class="input" v-model="fromData.email" />
        </div>
      </el-form-item>
      <el-form-item prop="pwd">
        <div class="footer">
          <div class="text">Please enter a new password</div>
          <el-input class="input" v-model="fromData.pwd" />
        </div>
      </el-form-item>
      <el-form-item>
        <div class="btnBox">
          <div class="cancel" @click="cancel">Cancel</div>
          <div class="submission" @click="sub">Submission</div>
        </div>
      </el-form-item>
    </el-form>
    <!-- <div class="textBox"> -->
      <!-- <div class="text">If you want to attend the meeting, please <span class="link" @click="link">click here</span></div> -->
    <!-- </div> -->
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import TitleIndex from '../../components/title/index.vue';
import BgMenu from '../../components/bg/menu.vue';
//
import { postRegisterUser } from '../../api/data';
//
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus';
import md5 from 'js-md5'

export default defineComponent({
  name: 'RegisterUser',
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    TitleIndex
  },
  setup() {
    const defaultData = {email: '', pwd: ''};
    const fromData = ref(defaultData);
    const fromDataRef = ref(null);
    const router = useRouter();
    const store = useStore();

    const sub = () => {
      fromDataRef.value.validate(async $ => {

        if ($) {
          const result = await postRegisterUser(fromData.value.email, md5(fromData.value.pwd)).catch(e => {
            if (e && e.code === 400)  ElMessage({
              type: 'info',
              message: e.msg,
            })
            return Error(e);
          });
          if (Object.prototype.toString.call(result).match(/Error/)) {
            // alert('error register.');
            return;
          }

          // 注册之后执行登录
          store.dispatch('LoginByUsername', {
            username: fromData.value.email,
            password: fromData.value.pwd,
            type: 'account'
          }).then(() => {
            fromData.value = defaultData;
            router.push({ path: '/registration/next' });
            // router.push({ path: '/' });
          }).catch(() => {
            console.log("error submit!!");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    };
    const cancel = () => {
      fromDataRef.value.resetFields();
      router.push({ path: '/registration/pre'});
    }
    const link = () => {
      router.push({ path: '/registration/order'});
    }
    // const validatePass = (_, value, callback) => {
    //   // 密码校验 长度不能小于8位且不能大于20位字符,必须包含大写字母、小写字母、数字和特殊符号
    //   const ISPWD =/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\._\+(){}])[0-9a-zA-Z!@#$%^&*,\\._\+(){}]{8,20}$/;
    //   if (!ISPWD.test(fromData.value.pwd)) {
    //     callback(new Error("用户密码必须包含大写字母、小写字母、数字和特殊符号"));
    //   } else {
    //     callback();
    //   }
    // }
    const rules = {
      email: [
        { required: true, message: 'Please enter email.', trigger: 'blur' },
        { type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change'] }],
      pwd: [
        { required: true, trigger: "blur", message: "Please enter your password." },
        { min: 8, max: 16, message: "Please enter an 8 - 16 length password.", trigger: 'blur' }
        // { required: true, validator: validatePass, trigger: 'blur' }
      ]
    }
    return {fromData, fromDataRef, sub, cancel, link, rules};
  }
});
</script>

<style scoped lang="less">
.registerUser {
  width: 1280px;
  margin: 40px auto;
  .header {
    margin-top: 30px;
    .title {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      margin-top: 12px;
    }
    .text {
      color: rgba(153, 153, 153, 1);
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 16px;
    }
  }
  .center {
    margin-top: 32px;
  }
  .footer {
    margin-top: 24px;
  }
  .center, .footer {
    .text {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: 500;
      font-size: 20px;
    }
    .input {
      width: 1280px;
      height: 48px;
      margin-top: 24px;
      /deep/ .el-input__wrapper {
        border-radius: 4px;
        border: 1px solid rgba(203, 203, 203, 1);
        box-sizing: border-box;
        background: #fbfafa;
      }
    }
  }
  .btnBox {
    margin-top: 64px;
    text-align: center;
    .cancel, .submission {
      display: inline-block;
      width: 172px;
      height: 48px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 20px;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
    }
    .cancel {
      border: 2px solid rgba(0, 192, 153, 1);
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 192, 153, 1);
      margin-right: 40px;
    }
    .submission {
      background: rgba(0, 192, 153, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  .textBox {
    margin: 64px 0;
    .text {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      .link {
        color: rgba(0, 192, 153, 1);
        cursor: pointer;
      }
    }
  }
}
</style>
