<template>
  <div class="paperInstruc">
    <!-- <div class="menu">
      <div
        :class="tabKey === item.key ? 'tab active' : 'tab'"
        @click="handle(item.key)"
        v-for="item in data" :key="item.key">{{item.title}}</div>
    </div> -->
    <div class="instructions" v-if="tabKey === 'instructions'">
      <div class="txtBox">
        <div class="txt">Full-paper submission is open for now. Please make sure you have read the instructions on this page carefully before you submit.</div>
        <div class="txt" style="margin-top:20px;">The full paper should be written in English and follow the format in the full-paper template below. Papers should not exceed eight single column A4 pages including figures, tables, diagrams, and references. Please submit your full paper in PDF format. Use three to four keywords to highlight your research.</div>
      </div>
      <div class="tipBox" style="margin-top: 40px;">
        <div class="tipBtn">
          <img class="tipIcon" :src="require('../../../assets/po/icon_word@3x.png')"/>
          <span class="tipTxt"><a class="tipTxtA" href="./PaperTemplate_DHC2023.docx" download="PaperTemplate_DHC2023.docx">PaperTemplate_DHC2023.docx</a></span>
        </div>
      </div>
      <div class="tipBox2" style="margin-top: 24px;">
        <div class="tipTxt">Deadline for Papers: June 12, 2023, at 24:00 (UTC+8)</div>
        <div class="tipTxt" style="margin-top: 20px;">Notification on Paper Acceptance: June 26, 2023</div>
      </div>
      <div class="txtBox3" style="margin-top: 30px;">
        <div class="txt">You may alter and resubmit your paper prior to the submission deadline.</div>
        <div class="txt" style="margin-top: 20px;">All submissions will be reviewed by the Scientific Committee (SC) based on relevance to topics of interest, originality, technical quality and significance. Authors should observe high standards regarding publication ethics. All submitted papers should report original, previously unpublished research results.</div>
        <div class="txt" style="margin-top: 20px;">IEA DHC gives out 5 awards for DHC research excellence that come with 1000 € prize money!</div>
        <div class="txt" style="margin-top: 20px;">The SC will decide on the presentation form of each paper and inform the authors: oral presentation or poster presentation. You may only report and publish your research if your abstract and full paper are accepted.</div>
        <div class="txt" style="margin-top: 20px;">When accepted, you can choose whether to have your paper included in the proceedings, which are not publicly available. After the symposium, you can opt to submit your extended paper to our recommended journals or other international journals.</div>
      </div>
      <div class="conterTitle">Submission Method:</div>
      <div class="txtBox4" style="margin-top: 8px;">
        <div class="txt">Please submit your abstract directly via: <a style="text-decoration: none;" class="green" href="mailto:submission@dhc2023.com.cn">submission@dhc2023.com.cn</a></div>
      </div>
      <div class="conterTitle" style="margin-top: 20px;">Email subject:</div>
      <div class="txtBox4" style="margin-top: 8px; margin-bottom: 40px;">
        <div class="txt">Paper ID_Title of paper</div>
      </div>

      <!-- <div class="downBox">
        <div class="down">
          Download
          <span class="icon"></span>
        </div>
        <div class="upload" @click="toUpload">
          Upload
          <span class="icon"></span>
        </div>
      </div> -->
    </div>
    <!-- <div class="abstracts" v-if="tabKey === 'abstracts'">
      <div class="txtBox">
        <div class="txt">Abstracts can be submitted for symposium or poster presentation. It should be original work and submitted by the presenting author. The Abstracts are to be written in English and using MS Word format only. All abbreviations must be spelled out on first use. Abstracts should be no more than 300 words.</div>
      </div>
      <CardRegister :data="listData" class="cardBox"/>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'PaperInstruc',
  props: {
    changeTab: Function
  },
  data() {
    return {
      listData0: [
        {
          title: '',
          txt: [
            'The title of the Abstract (keep it short for communication purposes)',
            'The research focus (problem description, short background)',
            'The research methods used',
            'The key results/findings of the research',
            'The main conclusions and recommendations'
          ]
        },
      ],
      listData: [
        {
          title: '',
          txt: [
            'The title of the Abstract (keep it short for communication purposes)',
            'The research focus (problem description, short background)',
            'The research methods used',
            'The key results/findings of the research',
            'The main conclusions and recommendations'
          ]
        },
      ],
      tabKey: 'instructions',
      data: [
        {
          title: 'Instructions',
          key: 'instructions'
        },
        {
          title: 'Abstracts',
          key: 'abstracts'
        }
      ]
    }
  },
  components: {
  },
  methods: {
    handle (key) {
      this.tabKey = key;
    },
    toUpload() {
      this.changeTab('upload');
    },
    changeHereTab(){
      this.changeTab('research');
    }
  }
}
</script>

<style lang="less" scoped>
.paperInstruc {
  width: 1320px;
  margin: 0 auto;
  .menu {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    height: 56px;
    margin-top: 28px;
    .tab {
      flex: 1;
      border-radius: 0px 8px 8px 0pxpx;
      background: rgba(0, 192, 153, 0.08);
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      height: 100%;
      line-height: 56px;
      cursor: pointer;
      &.active {
        border-radius: 8px 0px 0px 8pxpx;
        background: rgba(0, 192, 153, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .instructions {
    padding-top: 8px;
    width: 1280px;
    margin: 0 auto;
    .cardBox1 {
      margin-top: 12px;
      font-size: 18px;
      /deep/ .bg {
        padding: 20px;
        .txt {
          font-size: 18px;
          margin-bottom: 16px;
          color: rgba(102, 102, 102, 1);
          font-weight: 500;
          padding-left: 30px;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          &::before {
            width: 14px;
            height: 14px;
            left: 0;
          }
        }
      }
    }
    .green {
      color: rgba(0, 192, 153, 1);
      cursor: pointer;
      // text-decoration-line: underline;
    }
    .txt {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      // padding: 0 10px;
      // margin-bottom: 40px;
      .color {
        color: rgb(255, 153, 0);
      }
    }
    .txtBox2 {
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .txtBox3 {
      margin-top: 12px;
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .conterTitle {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin-top: 32px;
    }
    .tipBox {
      margin-top: 12px;
      .tipBtn {
        display: flex;
        width: 400px;
        height: 64px;
        border-radius: 8px;
        background: rgba(251, 250, 250, 1);
        padding: 16px 20px;
        align-items: center;
        cursor: pointer;
        .tipIcon {
          display: inline-block;
          object-fit: cover;
          width: 32px;
          height: 32px;
        }
        .tipTxt {
          text-align: justify;
          color: rgba(0, 122, 255, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 18px;
          line-height: 32px;
          margin-left: 12px;
        }
      }
    }


    .downBox {
      text-align: right;
      margin-bottom: 64px;
      .down {
        .icon {
          background: url('../../../assets/img/paper/icon_下载@3x.png');
        }
      }
      .upload {
        .icon {
          background: url('../../../assets/img/paper/icon_上传@3x.png');
        }
      }
      .down, .upload {
        display: inline-block;
        width: 224px;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        .icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-size: 100%;
          vertical-align: middle;
          margin-left: 12px;
        }
      }
      .down {
        background: rgba(255, 204, 0, 1);
        margin-right: 40px;
      }
      .upload {
        background: rgba(0, 192, 153, 1);
      }
    }
  }
  .abstracts {
    padding-top: 20px;
    .txtBox {
      width: 1280px;
      margin: 0 auto;
      .txt {
        padding: 0 10px;
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .cardBox {
      margin-top: 24px;
    }
  }
  .tipTxtA {
    text-decoration: none;
    color: #007aff;
  }
  .tipBox2 {
    .tipTxt {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }
  }
  .txtBox4 {
    margin-top: 8px;
    .txt {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
    }
  }
}
</style>
