<template>
  <HeaderIndex :min="true" />
  <HomeHeader />
  <div class="homeTitle">
    <div class="titleBox home--publice__index-title">
      <div class="title">{{welcome.title}}</div>
      <div class="txt" v-html="welcome.content"></div>
    </div>
    <TitleIndex title="Important dates" style="margin-top: 40px;"/>
    <HomePic />
    <div class="box">
      <router-link to="/papers?key=research">
        <div class="card card1">
          <div class="content">
            <div class="up">Call for papers</div>
          </div>
          <img :src="require('../../assets/img/home/icon_论文@3x.png')" alt="">
        </div>
      </router-link>
      <router-link to="/papers">
        <div class="card card2">
          <div class="content">
            <div class="up">Submission</div>
          </div>
          <img :src="require('../../assets/img/home/icon_cloud@3x.png')" alt="">
        </div>
      </router-link>
      <router-link to="/registration">
        <div class="card card3">
          <div class="content">
            <div class="up">Registration</div>
          </div>
          <img :src="require('../../assets/img/home/icon_regist@3x.png')" alt="">
        </div>
      </router-link>
    </div>
  </div>
  <HomeNews :news="news" />
  <div class="contact">
    <TitleIndex title="Contact"/>
    <div class="box">
      <div class="card">
        <img :src="require('../../assets/img/home/头像_Property_1_男_1@3x2.png')" alt="">
        <div class="cardContent">
          <!-- <div class="title">contacts</div> -->
          <div class="txt">Mr. Dexi SUN</div>
        </div>
      </div>
      <div class="card">
        <img :src="require('../../assets/img/home/Frame_1@3x.png')" alt="">
        <div class="cardContent">
          <!-- <div class="title">Contact way</div> -->
          <div class="txt" style="margin-top: 5px;word-wrap:break-word;word-break:break-all; ">Email: contact@dhc2023.com.cn</div>
          <div class="txt" style="margin-top: 5px;">Tel: +86-13261857219</div>
        </div>
      </div>
      <div class="card">
        <img :src="require('../../assets/img/home/icon_工作时间@3x.png')" alt="">
        <div class="cardContent">
          <div class="title">Working time</div>
          <!-- <div class="txt">Monday to Friday <br><p style="margin-top:4px;margin-bottom: 0;"></p>9 AM – 6 PM</div> -->
          <div class="txt">9 AM – 6 PM (UTC+8)</div>
        </div>
      </div>
    </div>
  </div>
  <div class="organizers">
    <TitleIndex title="Organizers & Supporter" style="margin-top: 40px;"/>
    <div class="box">
      <div class="list" v-for="(item, i) in organizers" :key="i">
        <div :class="'card card' + (i + 1)">
          <a :href="item.web" target="_blank">
            <img class="img" :src="item.images" alt="">
          </a>
        </div>
        <div class="title"><a :href="item.web" target="_blank">{{item.title}}</a></div>
      </div>
    </div>
  </div>
  <HomeDatas :sponsor="sponsor" :supporter="supporter"/>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import HomeHeader from './header.vue';
import HomeNews from './news.vue';
import HomeDatas from './datas.vue';
import HomePic from './pic.vue';
import FooterIndex from '../../components/footer/index.vue';
import TitleIndex from '../../components/title/index.vue';
//
import axios from 'axios';
import { getPageInfo } from '../../api/data';

export default {
  name: 'HomeIndex',
  data() {
    return {
      welcome: {title: '', content: ''},
      news: [],
      contact: [],
      organizers: [],
      sponsor: [
        {images: require('../../assets/img/cc/图片1.png'), src: 'https://www.tsxbgd.com/'},
        {images: require('../../assets/img/cc/图片2.png'), src: 'https://www.huntsman.cn/'},
        {images: require('../../assets/img/cc/图片3.png'), src: 'http://www.revoheat.com'},
        {images: require('../../assets/img/cc/图片4.png'), src: 'http://www.lcarbo.cn'},
        {images: require('../../assets/img/cc/图片5.png'), src: 'http://www.danfoss.cn'},
        {images: require('../../assets/img/cc/图片6.png'), src: 'http://www.powerbeijinghytm.com'},
        {images: require('../../assets/img/cc/图片7.png'), src: 'http://www.runachina.com/theme/default/defaultIndex.jsp'},
        {images: require('../../assets/img/cc/图片8.png'), src: 'http://www.cnppump.cn'},
        {images: require('../../assets/img/cc/图片9.png'), src: 'https://www.xinxing-pipes.com/'},
        {images: require('../../assets/img/cc/图片10.png'), src: 'http://Www.sfrt.cn'},
      ],
      supporter: []
    }
  },
  props: {},
  components: {
    HeaderIndex,
    HomeHeader,
    HomeNews,
    HomeDatas,
    FooterIndex,
    TitleIndex,
    HomePic
  },
  beforeMount() {
    axios.all([
      getPageInfo('home-welcome'),
      getPageInfo('home-news', 2, "", "time, sort"),
      getPageInfo('home-contact'),
      getPageInfo('home-organizers'),
      getPageInfo('home-sponsor'),
      getPageInfo('home-supporter')
    ]).then(axios.spread((welcome, news, contact, organizers, sponsor, supporter) => {
      this.welcome = {
        title: welcome?.records[0]?.title,
        content: welcome?.records[0]?.content || ''
      };
      this.contact = contact;
      this.news = (news.records || []).slice(0,2).map(_ => ({txt: this.unescapeHtmlEntities(_.content), id: _.id, title: _.title, date: _.time}));
      this.organizers = organizers.records.map(_ => ({images: _.images, title: _.title, web: _.web || '#'}));
      // this.sponsor = sponsor.records.map(_ => ({images: _.images, src: _.src}));
      this.supporter = supporter.records.map(_ => ({images: _.images}));
    }));
  },
  methods: {
    unescapeHtmlEntities(str) {
      const tempEle = document.createElement("div");
      tempEle.innerHTML = str;
      return tempEle.innerText;
    }
  }
}
</script>

<style lang="less" scoped>
.organizers {
  width: 1280px;
  margin: 0 auto;
  .box {
    margin-top: 40px;
  }
  .list {
    display: inline-block;
    width: 405px;
    margin-left: 32px;
    vertical-align: top;
    &:nth-of-type(1) {
      margin-left: 0;
      img.img {
        width: 405px;
        height: 200px;
      }
    }
    &:nth-of-type(2) {
      img.img {
        width: 406px;
        height: 200px;
      }
    }
    &:nth-of-type(3) {
      img.img {
        width: 405px;
        height: 200px;
      }
    }
    .title {
      margin-top: 20px;
      text-align: center;
      padding: 0 22px;
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      a {
        color: rgba(51, 51, 51, 1);
        text-decoration: none;
      }
    }
  }
  .card {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0px solid rgba(0, 192, 153, 1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 16px rgba(0, 109, 103, 0.12);
  }
}
.contact {
  width: 1280px;
  margin: 0 auto;
  .box {
    margin-top: 24px;
    .card {
      display: inline-block;
      position: relative;
      height: 120px;
      border-radius: 4px;
      background: rgba(251, 250, 250, 1);
      margin-left: 32px;
      padding: 24px;
      &:nth-of-type(1) {
        margin-left: 0;
        width: 405px;
      }
      &:nth-of-type(2) {
        width: 406px;
      }
      &:nth-of-type(3) {
        width: 405px;
        .cardContent {
          top: 20.5px;
          .txt {
            line-height: 18px;
          }
        }
      }
      .cardContent {
        position: absolute;
        top: 29px;
        left: 116px;
      }
      .title {
        color: rgba(102, 102, 102, 1);
        font-weight: 500;
        font-size: 16px;
      }
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans-Heavy;
        font-size: 18px;
        margin-top: 17px;
      }
      img {
        width: 72px;
        height: 72px;
        margin-right: 20px;
        // border: 2.3px solid rgba(255, 255, 255, 1);
        box-sizing: border-box;
        // background: linear-gradient(rgba(0, 122, 255, 0.08), rgba(0, 122, 255, 0.08)), rgba(255, 255, 255, 1);
      }
    }
  }
}
.homeTitle {
  position: relative;
  width: 1280px;
  margin: 40px auto 0;
  
  .titleBox {
    width: 1280px;
    height: 472px;
    border-radius: 8px;
    background: rgba(251, 250, 250, 1);
    padding: 24px;
  }
  .title {
    color: rgba(0, 192, 153, 1);
    font-family: OPPOSans-Heavy;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
  .txt {
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    line-height: 32px;
    margin-top: 16px;
    white-space: normal;
    // word-break: break-all;
    .bold {
      font-family: OPPOSans-Heavy;
      // font-weight: bold;
    }
  }
  .box {
    width: 1280px;
    height: 96px;
    margin-top: 24px;
    .card {
      display: inline-block;
      position: relative;
      width: 405px;
      height: 96px;
      border-radius: 4px;
      padding: 20px 24px;
      margin-left: 32px;
      &.card1 {
        margin-left: 0;
        background: linear-gradient(94.8deg, rgba(255, 204, 0, 1) 0%, rgba(255, 204, 0, 0.64) 130.92%);
      }
      &.card2 {
        background: linear-gradient(94.8deg, rgba(76, 217, 100, 1) 0%, rgba(76, 217, 100, 0.64) 130.92%);
      }
      &.card3 {
        background: linear-gradient(94.8deg, rgba(0, 122, 255, 1) 0%, rgba(0, 122, 255, 0.64) 130.92%);
      }
      .content {
        display: inline-block;
        height: 56px;
        line-height: 56px;
        .up {
          color: rgba(255, 255, 255, 1);
          font-family: OPPOSans-Heavy;
          font-size: 28px;
        }
      }
      img {
        position: absolute;
        top: 20px;
        right: 24px;
        width: 56px;
        height: 56px;
      }
    }
  }
}
</style>
