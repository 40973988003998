import { createStore } from 'vuex'
import user from './modules/user'
import logs from './modules/logs'
import getters from './getters'

// 创建一个新的 store 实例
const store = createStore({
  state () {
    return {
      count: 0
    }
  },
  getters() {
    return {
        getters
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  modules: {
    user,
    logs
  }
})
export { store }