<template>
  <div class="inforGallery">
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
    <div class="imageIndex">
      <div class="left" @click="carouselLeft"></div>
      <div class="right" @click="carouselRight"></div>
      <el-carousel
        class="carousel"
        :interval="5000"
        arrow="always"
        height="650px"
        indicator-position="none"
        @change="handle"
        ref="carousel">
        <el-carousel-item v-for="item in data" :key="item.url">
          <div class="imageIndexBox">
            <div class="imageIndexBoxSh">
              <img :src="item.url" alt="">
            </div>
          </div>
          <h3 class="imageIndexTitle" text="2xl" justify="center" style="visibility: hidden;">{{ item.title }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bottom">
      <div class="size">
        <div class="box" v-for="item in bottomData" :key="item.url">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InforGallery',
  data() {
    return {
      data: [
        {
          url: require('../../../assets/img/pp/image (1).jpg'),
          title: '1'
        },
        {
          url: require('../../../assets/img/pp/image (2).jpg'),
          title: '2'
        },
        {
          url: require('../../../assets/img/pp/image (3).jpg'),
          title: '3'
        },
        {
          url: require('../../../assets/img/pp/image (4).jpg'),
          title: '4'
        },
        {
          url: require('../../../assets/img/pp/image (5).jpg'),
          title: '5'
        },
        {
          url: require('../../../assets/img/pp/image (6).jpg'),
          title: '6'
        },
        {
          url: require('../../../assets/img/pp/image (7).jpg'),
          title: '7'
        },
        {
          url: require('../../../assets/img/pp/image (8).jpg'),
          title: '8'
        },
        {
          url: require('../../../assets/img/pp/image (9).jpg'),
          title: '9'
        },
        {
          url: require('../../../assets/img/pp/image (10).jpg'),
          title: '10'
        },
        {
          url: require('../../../assets/img/pp/image (11).jpg'),
          title: '11'
        },
        {
          url: require('../../../assets/img/pp/image (12).jpg'),
          title: '12'
        },
        {
          url: require('../../../assets/img/pp/image (13).jpg'),
          title: '13'
        },
        {
          url: require('../../../assets/img/pp/image (14).jpg'),
          title: '14'
        },
        {
          url: require('../../../assets/img/pp/image (15).jpg'),
          title: '15'
        },
        {
          url: require('../../../assets/img/pp/image (16).jpg'),
          title: '16'
        },
        {
          url: require('../../../assets/img/pp/image (17).jpg'),
          title: '17'
        },
        {
          url: require('../../../assets/img/pp/image (18).jpg'),
          title: '18'
        },
        {
          url: require('../../../assets/img/pp/image (19).jpg'),
          title: '19'
        },
        {
          url: require('../../../assets/img/pp/image (20).jpg'),
          title: '20'
        },
        {
          url: require('../../../assets/img/pp/image (21).jpg'),
          title: '21'
        },
        {
          url: require('../../../assets/img/pp/image (22).jpg'),
          title: '22'
        },
        {
          url: require('../../../assets/img/pp/image (23).jpg'),
          title: '23'
        },
        {
          url: require('../../../assets/img/pp/image (24).jpg'),
          title: '24'
        },
        {
          url: require('../../../assets/img/pp/image (25).jpg'),
          title: '25'
        },
      ],
      bottomData: []
    }
  },
  mounted () {             
    this.bottomData = this.data;      
  }, 
  methods: {
    carouselLeft() {
      this.$refs.carousel.prev();
    },
    carouselRight() {
      this.$refs.carousel.next();
    },
    handle(curIndex) {
      const len = this.data.length;
      if (len <= 4) return;
      const data = [this.data[curIndex]];
      let nextIndex = curIndex + 1;
      while(data.length < 4) {
        nextIndex = nextIndex > len - 1 ? 0 : nextIndex;
        data.push(this.data[nextIndex])
        nextIndex = nextIndex + 1;
      }
      this.bottomData = data;       
    }
  }
}
</script>

<style lang="less" scoped>
.inforGallery {
  .imageIndex {
    position: relative;
    width: 1000px;
    height: 670px;
    margin: 0 auto;
    text-align: center;
    .carousel {
      position: relative;
      width: 1000px;
      height: 670px;
      margin: 0 auto;
      overflow-y: hidden;
      /deep/ .el-carousel__arrow--left, /deep/ .el-carousel__arrow--right {
        display: none;
      }
      /deep/ .el-carousel__item.is-active {
        overflow: visible !important;
      }
      .imageIndexBox {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 8px;
        .imageIndexBoxSh {
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
          padding: 20px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .left, .right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .left {
      left: -100px;
      background: url('../../../assets/img/infor/翻页_Property_1_向后@3x.png');
      background-size: cover;
    }
    .right {
      right: -100px;
      background: url('../../../assets/img/infor/翻页_Property_1_向前@3x.png');
      background-size: cover;
    }
  }
  .bottom {
    width: 1320px;
    height: 248px;
    padding: 0 4.5px;
    overflow: hidden;
    .size {
      width: 100%;
      height: 196px;
      overflow-y: hidden;
    }
    .box {
      display: inline-block;
      width: 312px;
      height: 186px;
      margin-right: 21px;
      // padding: 5px;
      border-radius: 5px;
      border: 6.2px solid rgba(255, 255, 255, 1);
      box-sizing: border-box;
      background: rgba(239, 232, 232, 1);
      box-shadow: 0px 0px 8px 2px rgba(0, 129, 137, 0.12);
      margin-bottom: 20px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      img {
        border-radius: 5px;
        // object-fit: contain;
      }
    }
  }
  .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
}
</style>
