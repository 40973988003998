<template>
  <HeaderIndex />
  <BgMenu title="Information<br/>Center" :uri="require('../../../assets/img/infor/infor.png')" />
  <Menu :data="menu" :changeTab="changeTab" :tabKey="tabKey" :tabMargin="tabMargin" />
  <div class="main">
    <div class="inforNews">
      <div class="title">{{data.title}}</div>
      <div class="header">
        <div class="origin">Origin: {{data.origin}}</div>
        <div class="time">Time: {{data.time}}</div>
        <!-- <div class="views">Views: {{data.views}}</div> -->
      </div>
      <div class="hr"></div>
      <!-- <div class="imgBox">
        <img :src="data.img" alt="">
      </div> -->
      <div class="word" v-html="data.content"></div>
      <div v-if="data.files">
        <div class="download"  v-for="(item, i) in data.files" :key="item">
          <a class="btnA" :href="data.files" :download="data.title">{{ data.title }} {{ Boolean(i) ? (i + 1) : '' }} <span class="icon"></span></a>
        </div>
      </div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../../components/header/index.vue';
import FooterIndex from '../../../components/footer/index.vue';
import BgMenu from '../../../components/bg/menu.vue';
import Menu from '../../../components/menu/index.vue';
import { getDetail } from '../../../api/data';

export default {
  name: 'InformationNews',
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
  },
  data() {
    return {
      tabKey: '',
      tabMargin: '127.3px',
      data: {
        title: '',
        origin: '',
        time: '',
        views: '',
        img: '',
        content: ''
      },
      menu: [
        {
          title: 'News',
          key: 'newslist'
        },
        {
          title: 'Download Center',
          key: 'download'
        },
        {
          title: 'Conference History',
          key: 'history'
        },
        {
          title: 'Gallery',
          key: 'gallery'
        },
        {
          title: 'Contact',
          key: 'contact'
        }
      ],
    }
  },
  async beforeMount() {

    const _ = await getDetail(this.$route.query.id)
    this.data = {
      img: _.images,
      title: _.title,
      origin: _.source,
      views: _.viewNum,
      content: _.content,
      id: _.id,
      files: _.files ? _.files.split(',') : void 0,
      time: _.time
    }
  },
  methods:{
    changeTab(text){
      this.$router.push(
        {
          path: '/information',
          query: {
            text: text
          }
        }
      );
    }
  }
}
</script>

<style lang="less" scoped>
.inforNews {
  width: 1280px;
  margin: 32px auto;
  .title {
    width: 1187px;
    height: 108px;
    padding: 10px 0;
    text-align: center;
    color: rgba(51, 51, 51, 1);
    font-family: OPPOSans-Heavy;
    font-size: 24px;
    line-height: 44px;
    margin: 0 auto;
  }
  .header {
    width: 1280px;
    height: 62px;
    background: rgba(251, 250, 250, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 112px;
    box-sizing: border-box;
    margin: 0 auto;
    .origin, .time, .views {
      flex: 1;
      text-align: center;
      color: rgba(153, 153, 153, 1);
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .origin {
      text-align: left !important;
    }
    .views {
      text-align: right !important;
    }
  }
  .hr {
    margin-top: 40px;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid rgba(0, 123, 101, 0.5);
  }
  .imgBox {
    width: 962px;
    height: 560px;
    margin: 24px auto 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .word {
    color: rgba(51, 51, 51, 1);
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 15px;
  }
  .download {
    margin-top: 10px;
  }
  .btnA {
    text-decoration: none;
    color: #00c099;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('../../../assets/img/infor/icon_download11@3x.png') no-repeat;
      background-size: 100%;
      vertical-align: middle;
    }
  }
}
</style>
