<template>
  <div class="footer">
    <div class="content">
      <p>Copyright © China District Heating Association. All Rights Reserved.</p>
      <p class="content-footer"><span class="anbeiimg"></span><a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051440">京公网安备 11010502051440号</a > & <a target="_blank" href="http://beian.miit.gov.cn/">京ICP备 16024496号-3</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterIndex',
  props: {}
}
</script>

<style lang="less" scoped>
.footer {
  display: inline-block;
  width: 100%;
  height: 160px;
  text-align: center;
  background: rgba(0, 192, 153, 1);
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 20px;
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, 1);
    }
    p {
      padding: 0;
      margin: 0;
      &:nth-of-type(2) {
        margin-top: 10px;
      }
    }
    .content-footer {
      font-size: 19px;
    }
  }
  .anbeiimg {
    display: inline-block;
    background: url('../../assets/img/备案图标.png') no-repeat;
    background-size: 100%;
    width: 26px;
    height: 26px;
    vertical-align: bottom;
  }
}
</style>
