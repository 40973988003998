<template>
  <div class="dHCScientific">
    <div class="content">
      <div class="card">
        <div class="imgBox">
          <img class="img" :src="require('../../../assets/img/dhc/夏建军.png')"/>
        </div>
        <div class="textBox">
          <div class="name">Jianjun XIA</div>
          <div class="desc">Chairman of the Scientific Committee</div>
          <div class="box">
            <div class="txt">Associate Professor, School of Architecture, Tsinghua University</div>
            <div class="txt">Vice-director of Building Energy Research Center, Tsinghua University</div>
            <div class="txt">Representative of China, IEA DHC TCP</div>
          </div>
        </div>
      </div>
      <div class="card">
        <!-- <div class="imgBox">
          <img class="img" :src="require('../../../assets/img/dhc/蒋毅-副主席@3x.png')"/>
        </div>
        <div class="textBox">
          <div class="name">Yi JIANG</div>
          <div class="desc">Conference Co-Chair</div>
          <div class="box">
            <div class="txt">Member of Chinese Academy of Engineering</div>
            <div class="txt">Director of Building Energy Research Center, Tsinghua University</div>
          </div>
        </div> -->
      </div>
    </div>
    <TitleIndex title="All members" style="margin-top: 40px;margin-bottom: 32px;"/>
    <table>
      <thead>
        <tr>
          <td>Name</td>
          <td>Institution</td>
          <td>Country</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in scientific" :key="i">
          <td>
            <span>{{(item.author || '').trim()}}&nbsp;<a :href="item.authorWeb" v-if="item.authorWeb" target="_blank">→</a></span>
          </td>
          <td>
            <span v-for="(t, j) in item.title" :key="j">
              <a :href="t.url" v-if="t.url" target="_blank">{{t.title}}</a>
              <span v-else>{{t.title}}</span>
            </span>
            
            </td>
          <td>{{item.subtitle}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TitleIndex from '../../../components/title/index.vue';

export default {
  name: 'DHCScientific',
  props: {
    scientific: Array
  },
  components: {
    TitleIndex
  }
}
</script>

<style lang="less" scoped>
.dHCScientific {
  width: 1280px;
  margin: 32px auto 80px;
  .content {
    display: inline-block;
    width: 100%;
    .card {
      width: 830px;
      margin: 0 auto;
      display: flex;
    }
    .imgBox {
      width: 210px;
      height: 258px;
      img {
        width: 210px;
        height: 258px;
        object-fit: fill;
        border-radius: 10px;
        overflow: hidden;
      }
    }
    .textBox {
      padding: 10px 0 10px 16px;
      .name {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
      .desc {
        color: rgba(0, 192, 153, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        margin-top: 7px;
      }
      .box {
        margin-top: 24px;
        .txt {
          position: relative;
          color: rgba(102, 102, 102, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          padding-left: 32px;
          margin-bottom: 12px;
          &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: rgba(0, 192, 153, 1);
          }
        }
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse !important;
    border: 0px solid transparent !important;
    td { 
      border-top: 0 !important; 
      border-right: 0px solid white !important; 
      border-bottom: 0px solid white !important; 
      border-left: 0 !important; 
    }
    tbody tr:last-child td{
      border-bottom: 0 !important; 
    }
    tr td:last-child{
      border-right: 0 !important; 
    }
    thead {
      tr {
        background: rgba(0, 192, 153, 1);
        height: 72px;
        td {
          color: rgba(255, 255, 255, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          &:nth-of-type(1) {
            text-align: left;
            width: 650px;
            padding-left: 40px;
          }
          &:nth-of-type(2) {
            text-align: left;
          }
          &:nth-of-type(3) {
            width: 286px;
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        height: 72px;
        &:nth-of-type(2n + 2) {
          background: rgba(251, 250, 250, 1);
        }
        td {
          font-weight: bold;
          font-size: 20px;
          line-height: 20px;
          padding: 0;
          & > span {
            display: inline-block;
            vertical-align: middle;
          }
          a {
            text-decoration: none;
            color: rgba(0, 192, 153, 1);
          }
          &:nth-of-type(2) {
            font-family: OPPOSans;
            text-align: left;
            // color: rgba(51, 51, 51, 1);
            color: rgba(0, 192, 153, 1);
          }
          &:nth-of-type(1) {
            font-family: OPPOSans-Heavy;
            width: 650px;
            padding-left: 40px;
            text-align: left;
            color: rgba(51, 51, 51, 1);
          }
          &:nth-of-type(3) {
            font-family: OPPOSans;
            width: 286px;
            text-align: center;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
    }
  }
}
</style>
