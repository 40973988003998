<template>
  <div class="cardRegister">
    <div class="card">
      <div class="bg">
        <div class="txt" v-for="(item, j) in data" :key="j">
          <div class="txtTitle">{{item.title}}</div>
          <div class="txtContent" v-html="item.txt"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardRegister',
  props: {
    data: {
      type: Array
    }
  }
}
</script>

<style lang="less" scoped>
.cardRegister {
  width: 100%;
  .card {
    .bg {
      border-radius: 4px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      .txt {
        position: relative;
        padding-left: 40px;
        margin-top: 16px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        &::before {
          position: absolute;
          left: 0;
          top: 8px;
          display: inline-block;
          content: '';
          width: 16px;
          height: 16px;
          background: rgba(0, 192, 153, 1);
          border-radius: 50%;
        }
        .txtTitle {
          color: rgba(102, 102, 102, 1);
          font-weight: bold;
          font-size: 20px;
          line-height: 32px;
        }
        .txtContent {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 24px;
          line-height: 38px;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
