<template>
  <div class="inforContact">
    <!-- <TitleIndex title="contacts us"></TitleIndex> -->
    <div class="box">
      <div class="left">
        <CardConcat :data="leftData" class="cardBox"/>
      </div>
      <div class="right">
        <CardConcat :data="rightData" class="cardBox"/>
      </div>
    </div>
  </div>
</template>

<script>
// import TitleIndex from '../../../components/title/index.vue';
import CardConcat from '../../../components/card/concat.vue';

export default {
  name: 'InforContact',
  data() {
    return {
      leftData: [
        {
          title: 'Working time: ',
          txt: '9 AM – 6 PM (UTC+8)'
        },
        {
          title: 'General enquiries: ',
          txt: 'Mr. Dexi SUN <br> Email: contact@dhc2023.com.cn <br> Tel: +86-13261857219'
        }
      ],
      rightData: [
        // {
        //   title: 'Registration issues:',
        //   txt: 'Email: registration@dhc2023.com.cn'
        // },
        {
          title: 'Submission issues:',
          txt: 'Email: submission@dhc2023.com.cn'
        }
      ]
    }
  },
  components: {
    // TitleIndex,
    CardConcat
  }
}
</script>

<style lang="less" scoped>
.inforContact {
  width: 1280px;
  margin: 40px auto 80px;
  .box {
    width: 1112px;
    height: 300px;
    margin: 32px auto 0;
    .left {
      margin-right: 20px;
      width: 496px;
    }
    .right {
      width: 546px;
    }
    .left, .right {
      display: inline-block;
      height: 300px;
      background: rgba(251, 250, 250, 1);
      overflow: hidden;
    }
  }
}
</style>
