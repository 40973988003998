<template>
  <HeaderIndex />
  <BgMenu title="Committee" :uri="require('../../assets/img/dhc/dhc.png')" />
  <Menu :data="data" :changeTab="changeTab" :tabKey="tabKey" :tabMargin="tabMargin"></Menu>
  <div class="main">
    <Speech v-if="tabKey === 'message'" :welcome="welcome" />
    <Scientific v-if="tabKey === 'scientific'" :scientific="scientific" />
    <Organizing v-if="tabKey === 'organizing'" :organizing="organizing" />
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import Menu from '../../components/menu/index.vue';
import Speech from './speech/index.vue';
import Scientific from './scientific/index.vue';
import Organizing from './organizing/index.vue';
//
import axios from 'axios';
import { getPageInfo } from '../../api/data';

export default {
  name: 'DHCIndex',
  data() {
    return {
      welcome: new Array(2).fill({images:'', content: '', title: ''}),
      scientific: [],
      organizing: [],
      tabKey: 'message',
      tabMargin: '228.5px',
      data: [
        {
          title: 'Welcome Message',
          key: 'message'
        },
        {
          title: 'Scientific Committee',
          key: 'scientific'
        },
        {
          title: 'Organizing Committee',
          key: 'organizing'
        },
        // {
        //   title: 'Sponsors',
        //   key: 'sponsors'
        // },
        // {
        //   title: 'Conference History',
        //   key: 'history'
        // },
        // {
        //   title: 'Venues',
        //   key: 'venues'
        // },
        // {
        //   title: 'Accommodations',
        //   key: 'accommodations'
        // }
      ]
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
    Scientific,
    Organizing,
    Speech
  },
  beforeMount() {
    axios.all([
      getPageInfo('committee-welcome'),
      getPageInfo('committee-scientific', 20),
      getPageInfo('committee-organizing')
    ]).then(axios.spread((welcome, scientific, organizing) => {
      this.welcome = welcome?.records.map(_ => ({
        images: _?.images || '',
        content: _.content,
        title: _.title
      }));
      this.scientific = scientific.records.map(_ => {
        let item = [];
        try {
          item = JSON.parse(_.summary);
        } catch (err) {
          console.log(_.id, err);
        }
        return {author: _.author, title: item, subtitle: _.subtitle, authorWeb: _.web};
      });
      this.organizing = organizing.records.map(_ => ({author: _.author, title: _.title, subtitle: _.subtitle, images: _.images}));
    }));
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
    },
    unescapeHtmlEntities(str) {
      const tempEle = document.createElement("div");
      tempEle.innerHTML = str;
      return tempEle.childNodes[0].nodeValue;
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 32px auto;
}
</style>
