<template>
  <HeaderIndex />
  <BgMenu
    title="Registration"
    :uri="require('../../assets/img/register/register.png')"
  />
  <div class="registerPre">
    <TitleIndex title="Registration" />
    <div class="box">
      <div class="no-chinese" @click="goNoChineseHome">
        Non-Chinese Registrants
      </div>
      <div class="chinese" @click="goChineseHome">中国境内用户</div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from "../../components/header/index.vue";
import FooterIndex from "../../components/footer/index.vue";
import TitleIndex from "../../components/title/index.vue";
import BgMenu from "../../components/bg/menu.vue";
import { defineComponent } from "vue";

// 注册之前
export default defineComponent({
  name: "RegisterPre",
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    TitleIndex,
  },
  methods: {
    goChineseHome() {
      window.location = "https://www.china-heating.org.cn/meeting/";
    },
    goNoChineseHome() {
      // 判断用户登录状态
      const hasToken = Boolean(this.$store.state?.user?.token);
      if (hasToken) {
        // 已登录用户
        this.$router.push({ path: "/registration/next" });
      } else {
        // 未登录用户
        this.$router.push({ path: "/registration/user" });
      }
    },
  },
});
</script>

<style scoped lang="less">
.registerPre {
  width: 1280px;
  margin: 40px auto;
  .box {
    display: flex;
    .no-chinese {
      margin-right: 40px;
    }
    .chinese,
    .no-chinese {
      flex: 1;
      background: #00c099;
      color: #ffffff;
      height: 200px;
      line-height: 200px;
      border-radius: 5px;
      font-size: 30px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
