<template>
  <div class="eventSchedule">
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
    <div>
      <div v-if="schedule.length">
        <img :src="schedule[0].images">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventSchedule',
  props: {
    schedule: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="less" scoped>
  .eventSchedule {
    width: 1280px;
    margin: 32px auto 80px;
    .imgBox {
      width: 1280px;
      height: 1462px;
      background: rgba(251, 250, 250, 1);
    }
    .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
  }
</style>
