<template>
  <div class="titleIndex">
    <div :class="min ? 'title min' : 'title'">
      <span class="txt">{{title}}</span>
      <slot></slot>
    </div>
    <hr class="hr" />
  </div>
</template>

<script>
export default {
  name: 'TitleIndex',
  props: {
    title: {
      type:String 
    },
    min: Boolean
  }
}
</script>

<style lang="less" scoped>
.titleIndex {
  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    &.min {
      height: 40px;
      .txt {
        font-size: 32px;
        line-height: 40px;
      }
    }
    .txt {
      flex: 1;
      color: rgba(0, 123, 101, 1);
      font-family: OPPOSans-Heavy;
      font-size: 24px;
      line-height: 24px;
    }
    .btn {
      width: 66px;
      height: 28px;
      border-radius: 2px;
      background: rgba(0, 192, 153, 1);
      color: rgba(255, 255, 255, 1);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .hr {
    width: 100%;
    height: 2px;
    background: linear-gradient(90.03deg, rgba(0, 192, 153, 1) -1.32%, rgba(0, 192, 153, 0.04) 140.97%);
    border: none;
  }
}
</style>
