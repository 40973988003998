<template>
  <div class="paperInstruc">
    <!-- <div class="menu">
      <div
        :class="tabKey === item.key ? 'tab active' : 'tab'"
        @click="handle(item.key)"
        v-for="item in data" :key="item.key">{{item.title}}</div>
    </div> -->
    <div class="instructions" v-if="tabKey === 'instructions'">
      <div class="txtBox">
        <div class="txt">The following elements should be included in the abstract:</div>
      </div>
      <CardRegister :data="listData0" class="cardBox cardBox1" />
      <div class="txtBox2">
        <div class="txt">The abstract should be written in English using MS Word format only. Please follow the format in the abstract template below. </div>
      </div>
      <div class="tipBox">
        <div class="tipBtn">
          <img class="tipIcon" :src="require('../../../assets/po/icon_word@3x.png')"/>
          <span class="tipTxt"><a class="tipTxtA" href="./AbstractTemplate_DHC2023.docx" download="AbstractTemplate_DHC2023.docx">AbstractTemplate_DHC2023.docx</a></span>
        </div>
      </div>
      <div class="txtBox3">
        <div class="txt">You may alter and resubmit your abstract prior to the submission deadline of <span style="color: rgb(0, 192, 153);">March 20, 2023.</span></div>
        <div class="txt" style="margin-top: 12px;">All submissions will be reviewed by the Scientific Committee (SC) on the basis of relevance to topics of interest, originality, technical quality and significance. Authors should observe high standards regarding publication ethics. All submitted papers should report original, previously unpublished research results.</div>
        <div class="txt">Three best papers will be selected by the SC and awarded with <span class="color">1000 €</span>!</div>
        <div class="txt">The SC will decide on the presentation form of each paper and inform the authors: oral presentation or poster presentation. You may only report and publish your research if your abstract and full paper are accepted.</div>
      </div>
      <div class="conterTitle">Submission Method:</div>
      <div class="txtBox4" style="margin-top: 8px; margin-bottom: 40px;">
        <div class="txt">Please submit your abstract directly via: <a style="text-decoration: none;" class="green" href="mailto:submission@dhc2023.com.cn">submission@dhc2023.com.cn</a></div>
        <div class="txt">Email subject: Name of presenting author_Research topic_Title of paper</div>
        <div class="txt">Please select your research topic from <span class="green" @click="changeHereTab">here</span>.</div>
      </div>


      <!-- <div class="downBox">
        <div class="down">
          Download
          <span class="icon"></span>
        </div>
        <div class="upload" @click="toUpload">
          Upload
          <span class="icon"></span>
        </div>
      </div> -->
    </div>
    <!-- <div class="abstracts" v-if="tabKey === 'abstracts'">
      <div class="txtBox">
        <div class="txt">Abstracts can be submitted for symposium or poster presentation. It should be original work and submitted by the presenting author. The Abstracts are to be written in English and using MS Word format only. All abbreviations must be spelled out on first use. Abstracts should be no more than 300 words.</div>
      </div>
      <CardRegister :data="listData" class="cardBox"/>
    </div> -->
  </div>
</template>

<script>
import CardRegister from '../../../components/card/register.vue';

export default {
  name: 'PaperAbstract',
  props: {
    changeTab: Function
  },
  data() {
    return {
      listData0: [
        {
          title: '',
          txt: [
            'The title of the Abstract (keep it short for communication purposes)',
            'The research focus (problem description, short background)',
            'The research methods used',
            'The key results/findings of the research',
            'The main conclusions and recommendations'
          ]
        },
      ],
      listData: [
        {
          title: '',
          txt: [
            'The title of the Abstract (keep it short for communication purposes)',
            'The research focus (problem description, short background)',
            'The research methods used',
            'The key results/findings of the research',
            'The main conclusions and recommendations'
          ]
        },
      ],
      tabKey: 'instructions',
      data: [
        {
          title: 'Instructions',
          key: 'instructions'
        },
        {
          title: 'Abstracts',
          key: 'abstracts'
        }
      ]
    }
  },
  components: {
    CardRegister
  },
  methods: {
    handle (key) {
      this.tabKey = key;
    },
    toUpload() {
      this.changeTab('upload');
    },
    changeHereTab(){
      this.changeTab('research');
    }
  }
}
</script>

<style lang="less" scoped>
.paperInstruc {
  width: 1320px;
  margin: 0 auto;
  .menu {
    display: flex;
    width: 1280px;
    margin: 0 auto;
    height: 56px;
    margin-top: 28px;
    .tab {
      flex: 1;
      border-radius: 0px 8px 8px 0pxpx;
      background: rgba(0, 192, 153, 0.08);
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      height: 100%;
      line-height: 56px;
      cursor: pointer;
      &.active {
        border-radius: 8px 0px 0px 8pxpx;
        background: rgba(0, 192, 153, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .instructions {
    padding-top: 8px;
    width: 1280px;
    margin: 0 auto;
    .cardBox1 {
      margin-top: 12px;
      font-size: 18px;
      /deep/ .bg {
        padding: 20px;
        .txt {
          font-size: 18px;
          margin-bottom: 16px;
          color: rgba(102, 102, 102, 1);
          font-weight: 500;
          padding-left: 30px;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          &::before {
            width: 14px;
            height: 14px;
            left: 0;
          }
        }
      }
    }
    .green {
      color: rgba(0, 192, 153, 1);
      cursor: pointer;
      // text-decoration-line: underline;
    }
    .txt {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      // padding: 0 10px;
      // margin-bottom: 40px;
      .color {
        color: rgb(255, 153, 0);
      }
    }
    .txtBox2 {
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .txtBox3 {
      margin-top: 12px;
      .txt {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
      }
    }
    .conterTitle {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      margin-top: 32px;
    }
    .tipBox {
      margin-top: 12px;
      .tipBtn {
        display: flex;
        width: 400px;
        height: 64px;
        border-radius: 8px;
        background: rgba(251, 250, 250, 1);
        padding: 16px 20px;
        align-items: center;
        cursor: pointer;
        .tipIcon {
          display: inline-block;
          object-fit: cover;
          width: 32px;
          height: 32px;
        }
        .tipTxt {
          text-align: justify;
          color: rgba(0, 122, 255, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 18px;
          line-height: 32px;
          margin-left: 12px;
        }
      }
    }


    .downBox {
      text-align: right;
      margin-bottom: 64px;
      .down {
        .icon {
          background: url('../../../assets/img/paper/icon_下载@3x.png');
        }
      }
      .upload {
        .icon {
          background: url('../../../assets/img/paper/icon_上传@3x.png');
        }
      }
      .down, .upload {
        display: inline-block;
        width: 224px;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        .icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background-size: 100%;
          vertical-align: middle;
          margin-left: 12px;
        }
      }
      .down {
        background: rgba(255, 204, 0, 1);
        margin-right: 40px;
      }
      .upload {
        background: rgba(0, 192, 153, 1);
      }
    }
  }
  .abstracts {
    padding-top: 20px;
    .txtBox {
      width: 1280px;
      margin: 0 auto;
      .txt {
        padding: 0 10px;
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .cardBox {
      margin-top: 24px;
    }
  }
  .tipTxtA {
    text-decoration: none;
    color: #007aff;
  }
  .tipBox2 {
    .tipTxt {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      margin-top: 12px;
    }
  }
  .txtBox4 {
    margin-top: 8px;
    .txt {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
    }
  }
}
</style>
