<template>
  <div class="dHCHistoryS">
    <div :class="i === 0 ? 'box first' : 'box'" v-for="(item, i) in data" :key="i">
      <div :class="i % 2 === 1 ? 'left active' : 'left'">
        <div class="content" v-if="i % 2 === 1">
          <div class="txtBox">
            <div class="txtT">{{item.title}}</div>
            <div class="txtTx">{{item.txt}}</div>
          </div>
          <div class="imgBox">
            <img :src="item.url" alt="">
          </div>
        </div>
      </div>
      <div :class="i % 2 === 0 ? 'bleft center' : 'bright center'">
        <div class="cleft"></div>
        <div class="ccenter">
          <div class="cup"></div>
          <div class="cbox">
            <div class="ro">{{item.date}}</div>
          </div>
          <div :class="i === data.length - 1 ? 'cdown cdownend' : 'cdown'"></div>
        </div>
        <div class="cright"></div>
      </div>
      <div :class="i % 2 === 0 ? 'right active' : 'right'">
        <div class="content" v-if="i % 2 === 0">
          <div class="imgBox">
            <img :src="item.url" alt="">
          </div>
          <div class="txtBox">
            <div class="txtT">{{item.title}}</div>
            <div class="txtTx">{{item.txt}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DHCHistoryS',
  props: {
    data: Array
  }
}
</script>

<style lang="less" scoped>
.dHCHistoryS {
  margin: 68px auto 80px;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    &.first {
      .left.active, .right.active {
        background: transparent;
        .content {
          padding-top: 0;
        }
      }
      .center {
        align-items: initial;
        .cleft, .cright {
          margin-top: 53px;
        }
        .ccenter {
          width: 108px;
          .cup {
            display: none;
          }
          .cbox {
            width: 108px;
            height: 108px;
            padding: 14px;
            .ro {
              width: 80px;
              height: 80px;
              line-height: 80px;
            }
          }
        }
      }
    }
    .left.active .content .txtBox {
      text-align: right;
      margin-right: 24px;
      padding-left: 40px;
    }
    .right.active .content .txtBox {
      text-align: left;
      margin-left: 24px;
      padding-right: 40px;
    }
    .left, .right {
      flex: 1;
      .content {
        display: flex;
        padding: 24px;
        // justify-content: center;
        .imgBox {
          width: 132px;
          height: 88px;
        }
        .txtBox {
          flex: 1;
          .txtT {
            color: rgba(51, 51, 51, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
          }
          .txtTx {
            margin-top: 4px;
            color: rgba(102, 102, 102, 1);
            font-family: OPPOSans;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      &.active {
        width: 434px;
        height: 144px;
        border-radius: 8px;
        background: rgba(248, 248, 248, 1);
      }
    }
    .left {
      margin-right: 20px;
    }
    .right {
      margin-left: 20px;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 312px;
      height: 144px;
      .cleft, .cright {
        flex: 1;
        width: 116px;
        height: 2px;
        border: 1px dashed transparent;
      }
      &.bleft .cright {
        border: 1px dashed rgba(0, 192, 153, 1);
      }
      &.bright .cleft {
        border: 1px dashed rgba(0, 192, 153, 1);
      }
      .ccenter {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80px;
        height: 100%;
        .cup, .cdown {
          flex: 1;
          width: 4px;
          background:  rgba(0, 192, 153, 1);
          &.cdownend {
            background:  transparent;
          }
        }
        .cbox {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: rgba(0, 192, 153, 0.24);
          padding: 8px;
          .ro {
            width: 64px;
            height: 64px;
            line-height: 64px;
            border-radius: 50%;
            background: rgba(0, 192, 153, 1);
            text-align: center;
            color: rgba(255, 255, 255, 1);
            font-family: OPPOSans-Heavy;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
