<template>
  <div class="homeNews">
    <div class="container">
      <TitleIndex title="News">
        <el-button class="titleBtn" type="primary" @click="moreHandle">More</el-button>
      </TitleIndex>
      <div class="news">
        <div class="list" v-for="(item, i) in news" :key="i">
          <div class="imgBox">
            <div class="date1">{{momentFn(item.date, 'DD')}}</div>
            <div class="date2">{{momentFn(item.date, 'YYYY.MM')}}</div>
          </div>
          <div class="box" @click="toNewsHandle(item.id)">
            <div class="title">{{item.title}}</div>
            <div class="txt home--news__list-text" v-html="item.txt"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleIndex from '../../components/title/index.vue';
export default {
  name: 'HomeNews',
  props: {
    news: Array
  },
  data () {
    return {
      data: [
        {
          date: '2023.08.09',
          title: 'DHC2023 website is officially online online online',
          txt: 'On August 29th, Mr. Li Baodong, Secretary General of Boao Forum for Asia (BFA) met with Mr. Tsang Wai-hung, Chairman of the BFA Global Economic Development ……'
        },
        {
          date: '2023.08.10',
          title: 'DHC2023 website is officially online online online',
          txt: 'On August 29th, Mr. Li Baodong, Secretary General of Boao Forum for Asia (BFA) met with Mr. Tsang Wai-hung, Chairman of the BFA Global Economic Development ……'
        },
        {
          date: '2023.08.15',
          title: 'DHC2023 website is officially online online online',
          txt: 'On August 29th, Mr. Li Baodong, Secretary General of Boao Forum for Asia (BFA) met with Mr. Tsang Wai-hung, Chairman of the BFA Global Economic Development ……'
        },
        {
          date: '2023.08.17',
          title: 'DHC2023 website is officially online online online',
          txt: 'On August 29th, Mr. Li Baodong, Secretary General of Boao Forum for Asia (BFA) met with Mr. Tsang Wai-hung, Chairman of the BFA Global Economic Development ……'
        }
      ]
    }
  },
  components: {
    TitleIndex
  },
  methods: {
    momentFn(date, format) {
      return this.$moment(date).format(format);
    },
    moreHandle() {
      this.$router.push(
        {
          path: '/information'
        }
      );
    },
    toNewsHandle(id) {
      this.$router.push(
        {
          path: '/information_news',
          query: {
            id: id
          }
        }
      );
    }
  }
}
</script>

<style lang="less" scoped>
.homeNews {
  display: inline-block;
  width: 100%;
  .container {
    width: 1280px;
    margin: 40px auto 32px;
  }
  .titleBtn {
    width: 66px;
    height: 28px;
    border-radius: 2px;
    background: rgba(0, 192, 153, 1);
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .news {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    .list {
      display: flex;
      width: 624px;
      height: 192px;
      border-radius: 4px;
      background: rgba(251, 250, 250, 1);
      margin-top: 24px;
      padding: 24px;
      cursor: pointer;
      &:hover, &:visited {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
        background: white;
      }
      &:nth-of-type(2n + 1) {
        margin-right: 32px;
      }
      .imgBox {
        width: 72px;
        height: 72px;
        border-radius: 4px;
        background: rgba(0, 192, 153, 1);
        text-align: center;
        color: rgba(255, 255, 255, 1);
        .date1 {
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          margin-top: 16px;
        }
        .date2 {
          font-weight: bold;
          font-size: 14px;
          line-height: 14px;
          margin-top: 10px;
        }
      }
      .box {
        flex: 1;
        margin-left: 24px;
        cursor: pointer;
        .title {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 20px;
          line-height: 32px;
        }
        .txt {
          color: rgba(153, 153, 153, 1);
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
          overflow: hidden; // 溢出隐藏
          text-overflow: ellipsis; // 溢出用省略号显示
          display:-webkit-box;// 作为弹性伸缩盒子模型显示。
          -webkit-box-orient:vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
          -webkit-line-clamp: 4; // 显示的行数
        }
      }
    }
  }
}
</style>
