<template>
  <div class="login">
    <div class="title">The 18th International Symposium on District<br>Heating & Cooling</div>
    <div class="box">
      <div class="left">
        <div class="img"><img :src="img" alt=""></div>
        <div class="pointBox">
          <span :class="index === 0 ? 'point active' : 'point'" @click="pointHandle(0)"></span>
          <span :class="index === 1 ? 'point active' : 'point'" @click="pointHandle(1)"></span>
          <span :class="index === 2 ? 'point active' : 'point'" @click="pointHandle(2)"></span>
          <span :class="index === 3 ? 'point active' : 'point'" @click="pointHandle(3)"></span>
        </div>
      </div>
      <div class="right">
        <div class="up">
          <!-- <div :class="key === 'register' ? 'tab active' : 'tab'" @click="tabHandle('register')">Register</div> -->
          <div :class="key === 'login' ? 'tab active' : 'tab'" @click="tabHandle('login')">Login</div>
        </div>
        <div class="center">
          <div class="centerTitle">Get started</div>
          <!-- <div class="register" v-if="key === 'register'">
            <div class="txt">First Name</div>
            <el-input class="input"></el-input>
            <div class="txt">Email</div>
            <el-input class="input"></el-input>
            <div class="txt">Password</div>
            <el-input class="input"></el-input>
          </div> -->
          <div class="login" v-if="key === 'login'">
            <el-form class="login-form" ref="loginForm" :rules="rules"  :model="form" label-width="0">
              <div class="txt">Email</div>
              <el-form-item  prop="username">
                <el-input class="input" v-model='form.username' @keyup.enter="handleLogin"/>
              </el-form-item>
              <div class="txt">Password</div>
              <el-form-item  prop="password">
                <el-input class="input" type="password" v-model='form.password' @keyup.enter='handleLogin' />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="down">
          <div class="btn" @click='handleLogin'>sign up</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import { defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
// import { jump2Auth, getUserInfo } from '@/hooks/useWechatAuth'
// import { authStore } from '@/store/modules/auth'
// import { redirectTo, getRouteQuery } from '@/hooks/usePage'

export default defineComponent({
  name: 'LoginIndex',
  data() {
    return {
      index: 0,
      key: 'login',
      img: '',
      timer: null,
      imgs: [
        require('../../assets/po/Frame_215@3x.png'),
        require('../../assets/po/Frame_215@3x.png'),
        require('../../assets/po/Frame_215@3x.png'),
        require('../../assets/po/Frame_215@3x.png')
      ],
      form: {
        username: '',
        password: '',
        type: 'account'
      },
      rules: {
        username: [
          {required: true, message: 'Pleas Enter Your Account.', trigger: 'blur'},
          {type: 'email', message: 'Please enter the correct email address.', trigger: ['blur', 'change'] }
        ],
        password: [ {required: true, message: 'Pleas Enter Your Password.', trigger: 'blur'}]
      }
    }
  },
  beforeMount() {
    this.img = this.imgs[0];
    const that = this;
    this.timer = setInterval(function(){
      const index = that.handle(that.index);
      that.img = that.imgs[index];
      that.index = index;
    }, 3000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  mounted() {
    !function(a){
      function b(){
        let scale = a.innerWidth / 1680;
        document.documentElement.style.fontSize = `${10 * scale}px`;
      }
      window.addEventListener("DOMContentLoaded",function(){b()},!1);
      window.addEventListener("resize",function(){b()});
      b();
    }(window);
  },
  components: {},
  methods: {
    handle(curIndex) {
      const len = this.imgs.length;
      const nextIndex = curIndex + 1;
      return nextIndex > len - 1 ? 0 : nextIndex;
    },
    pointHandle(curIndex) {
      const index = this.handle(curIndex - 1);
      this.img = this.imgs[index];
      this.index = index;
    },
    tabHandle(key) {
      this.key = key;
    },
    
  },
  setup() {
    const gCI = getCurrentInstance();
    const store = useStore();
    const router = useRouter();
    const handleLogin = () => {
      store.dispatch('LoginByUsername', gCI.data.form)
      .then(() => {
        router.push({ path: "/" });
      })
      .catch(() => {
      })
    }
    return {
      handleLogin
    }
  },
  // setup() {
  //   let code = getRouteQuery().code as string
  //   // 3.如果有code，则已经授权
  //   if (code) {
  //     getUserInfo(code as string).then((res: any) => {
  //       // 记录token
  //       authStore.saveToken(res.access_token)
  //       const redirect = authStore.authState.landPageRoute || '/'
  //       // 跳转到授权前访问的页面
  //       redirectTo(redirect)
  //     })
  //   } else {
  //     // 1.记录上一个页面的地址
  //     const { redirect } = getRouteQuery()
  //     if (redirect) {
  //       authStore.setLandPage(redirect as string)
  //     }
  //     // 2.跳转授权
  //     const callbackUrl = window.location.origin + window.location.pathname
  //     jump2Auth(callbackUrl)
  //   }
  // },
})
</script>
<style lang="less" scoped>
  .login {
    .title {
      margin-top: 6.4rem;
      text-align: center;
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
      font-size: 4.8rem;
      width: 100%;
      line-height: 7.2rem;
    }
    .box {
      display: flex;
      width: 132rem;
      height: 61.7rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 5.6rem rgba(0, 0, 0, 0.08);
      margin: 4.8rem auto 0;
      .left, .right {
        flex: 1;
        height: 61.7rem;
      }
      .left {
        background: rgba(0, 192, 153, 0.12);
        padding: 2.4rem 2.4rem 0 2.4rem;
        .img {
          width: 61.2rem;
          height: 53.3rem;
        }
        .pointBox {
          margin-top: 2.4rem;
          padding: 1rem;
          text-align: center;
          .point {
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            border: 0.15rem solid rgba(0, 192, 153, 1);
            box-sizing: border-box;
            margin-left: 4rem;
            border-radius: 50%;
            cursor: pointer;
            &:nth-of-type(1) {
              margin-left: 0;
            }
            &.active {
              width: 1.6rem;
              height: 1.6rem;
              background: rgba(0, 192, 153, 1);
              border: none;
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        padding: 4rem 5rem;
        .up {
          display: flex;
          width: 100%;
          height: 6.4rem;
          border-radius: .8rem;
          background: rgba(0, 192, 153, 0.2);
          .tab {
            flex: 1;
            color: rgba(0, 192, 153, 1);
            font-family: OPPOSans;
            font-weight: bold;
            line-height: 6.4rem;
            font-size: 2.4rem;
            text-align: center;
            cursor: pointer;
            &:nth-of-type(1) {
              &.active {
                border-radius: .8rem 0rem 0rem .8rem
              }
            }
            &:nth-of-type(2) {
              &.active {
                border-radius: 0rem .8rem .8rem 0rem
              }
            }
            &.active {
              background: rgba(0, 192, 153, 1);
              color: rgba(255, 255, 255, 1);
              font-family: OPPOSans-Heavy;
              font-size: 2.8rem;
            }
          }
        }
        .center {
          flex: 1;
          padding: 3.2rem 0 0;
          .centerTitle {
            color: rgba(51, 51, 51, 1);
            font-family: OPPOSans-Heavy;
            padding-left: 2.2rem;
            font-size: 2.8rem;
            line-height: 2.8rem;
          }
          .register, .login {
            padding: 0 0 0 1.4rem;
            .txt {
              color: rgba(153, 153, 153, 1);
              font-family: OPPOSans;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 1.6rem;
              padding-left: 2rem;
              margin-top: 2.4rem;
            }
            .input {
              width: 100%;
              height: 5.2rem;
              border-bottom: .2rem solid rgba(0, 0, 0, 0.12);
              padding-left: 2rem;
              margin-top: .4rem;
              /deep/ .el-input__wrapper, /deep/ .el-input__inner {
                border: none;
                background: none;
                box-shadow: none;
                color: rgba(51, 51, 51, 1);
                font-family: OPPOSans;
                font-weight: bold;
                font-size: 2rem;
                line-height: 2.8rem;
                &:hover, &:visited {
                  border: none;
                  background: none;
                  box-shadow: none;
                }
              }
            }
          }
        }
        .down {
          text-align: center;
          .btn {
            display: inline-block;
            width: 34.7rem;
            height: 8rem;
            text-align: center;
            line-height: 8rem;
            border-radius: .8rem;
            background: rgba(0, 192, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 3.2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
