<template>
  <div class="eventSocial">
    <div class="table-box">
      <table>
        <tr class="title">
          <td colspan="3">September 3, Sunday
V-Continent Beijing Parkview Wuzhou Hotel</td>
        </tr>
        <tr>
          <td>9:00-22:00</td>
          <td>Check in</td>
          <td>Hotel lobby, First Floor</td>
        </tr>
        <tr>
          <td>18:00-21:00</td>
          <td>Dinner</td>
          <td>Brazilian Barbecue Buffet Restaurant, First Floor</td>
        </tr>
        <tr class="title">
          <td colspan="3">September 4, Monday</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Welcome Reception / IEA DHC Awards Ceremony</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Jianjun Xia (Associate Professor, Tsinghua University, <br>Deputy Director of the Heating Technology Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">18:00-21:00</td>
          <td colspan="2">Welcome Reception</td>
        </tr>
        <tr>
          <td colspan="2">IEA DHC Awards Ceremony</td>
        </tr>
      </table>
    </div>
    <!-- <div class="left">
      <TitleIndex class="titleIndex2" title="Welcome Reception" :min="true"/>
      <div class="imgBox">
        <img :src="require('../../../assets/po/Frame_124@3x.png')" alt="">
      </div>
      <div class="bg">
        <div class="txt">
          <span class="title">Date:</span><span class="word">September 04，2023</span>
        </div>
        <div class="txt">
          <span class="title">Place:</span><span class="word">Wuzhou Crown International Hotel，Beijing </span>
        </div>
      </div>
    </div>
    <div class="right">
      <TitleIndex class="titleIndex2" title="symposium Dinner" :min="true"/>
      <div class="imgBox">
        <img :src="require('../../../assets/po/Frame_124_1@3x.png')" alt="">
      </div>
      <div class="bg">
        <div class="txt">
          <span class="title">Date:</span><span class="word">September 04，2023</span>
        </div>
        <div class="txt">
          <span class="title">Place:</span><span class="word">Wuzhou Crown International Hotel，Beijing </span>
        </div>
      </div>
    </div> -->
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
  </div>
</template>

<script>
// import TitleIndex from '../../../components/title/index.vue';

export default {
  name: 'EventSocial',
  components: {
    // TitleIndex
  }
}
</script>

<style lang="less" scoped>
.eventSocial {
  width: 1280px;
  margin: 0 auto;
  .table-box {
    width: 100%;
    // background-color: red;
    table {
        margin-top: 24px;
        // border-collapse: separate;
        // border-spacing: 8px 8px; // 控制间距
        border-collapse: collapse !important;
        border: 1px solid #000 !important;
        td { 
          border-top: 0 !important; 
          border-right: 1px solid #000 !important; 
          border-bottom: 1px solid #000 !important; 
          border-left: 0 !important; 
          min-width: 140px;
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
      }
    table {
      width: 100%;
      tr {
        // padding: 0;
        // margin: 0;
        width: 100%;
        td {
          // color: rgba(153, 153, 153, .4);
          font-family: OPPOSans;
          height: 60px;
          text-align: center;
          &:last-child{
            text-align: left;
            padding-left: 10px;
          }
        }
        &.title {
          td {
            background-color: #1C7A60;
            text-align: center;
            height: 80px;
            font-size: 20px;
            color: #fff;
            font-family: OPPOSans-Heavy;
          }
        }
        &.text {
          td {
            text-align: center;
            height: 120px;
            background-color: #FBE4D5;
            font-family: OPPOSans;
          }
          div {
            &:first-child {
              font-size: 20px;
              font-family: OPPOSans-Heavy;
            }
            &:nth-child(2) {
              font-size: 18px;
              margin: 5px 0;
            }
            &:nth-child(3) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .left {
    margin-right: 20px;
  }
  .left, .right {
    width: 630px;
    display: inline-block;
    .imgBox {
      width: 600px;
      height: 368px;
      margin: 32px auto 0;
    }
    .bg {
      width: 600px;
      height: 124px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      margin: 24px auto 0;
      .txt {
        margin-top: 0;
        &:nth-last-of-type(1) {
          margin-top: 20px;
        }
        .title {
          color: rgba(102, 102, 102, 1);
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
        }
        .word {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
}
</style>
