<template>
  <div class="eventIndustrial">
    <!-- <div class="card" v-for="(item, i) in data" :key="i">
      <div class="title">{{item.title}}</div>
      <div class="box">
        <div class="imageBox">
          <img :src="item.img" alt="">
        </div>
        <div class="content">
          <div class="boxTitle">{{item.title2}}</div>
          <div class="txt">{{item.txt}}</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
    <div class="table-box">
      <table>
        <tr class="title">
          <td colspan="2">September 6, Wednesday</td>
        </tr>
        <tr class="text">
          <td colspan="2">
            <div>Industrial Tour</div>
          </td>
        </tr>
        <tr>
          <td>9:00-19:00</td>
          <td>Industrial Tour：Beijing Jingqiao Thermal Power Co., Ltd</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventIndustrial',
  data() {
    return {
      data: [
        {
          title: 'The first place to visit',
          title2: 'Beijing Heat Group Co. LTD',
          txt: 'Beijing Heat Group Co., Ltd. is a wholly state-owned company approved by the municipal government. It was formally established on June 6, 2000. Its predecessor was Beijing Gas and Heat Company established in August 1958, and in January 1974, it was rebuilt and established as Beijing Heat Company. In April 2022, it was awarded the title of Beijing Advanced Collective for the Beijing Winter Olympic and Paralympic Games.',
          img: require('../../../assets/po/Frame_124@3x.png')
        },
        {
          title: 'The first place to visit',
          title2: 'Beijing Heat Group Co. LTD',
          txt: 'Beijing Heat Group Co., Ltd. is a wholly state-owned company approved by the municipal government. It was formally established on June 6, 2000. Its predecessor was Beijing Gas and Heat Company established in August 1958, and in January 1974, it was rebuilt and established as Beijing Heat Company. In April 2022, it was awarded the title of Beijing Advanced Collective for the Beijing Winter Olympic and Paralympic Games.',
          img: require('../../../assets/po/Frame_124@3x.png')
        },
      ]
    }
  },
  components: {
  }
}
</script>

<style lang="less" scoped>
  .eventIndustrial {
    width: 1280px;
    margin: 40px auto 80px;
    .table-box {
    width: 100%;
    // background-color: red;
    table {
        margin-top: 24px;
        // border-collapse: separate;
        // border-spacing: 8px 8px; // 控制间距
        border-collapse: collapse !important;
        border: 1px solid #000 !important;
        td { 
          border-top: 0 !important; 
          border-right: 1px solid #000 !important; 
          border-bottom: 1px solid #000 !important; 
          border-left: 0 !important;
          min-width: 140px;
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
      }
    table {
      width: 100%;
      tr {
        // padding: 0;
        // margin: 0;
        width: 100%;
        td {
          // color: rgba(153, 153, 153, .4);
          font-family: OPPOSans;
          height: 60px;
          text-align: center;
          &:last-child{
            text-align: left;
            padding-left: 10px;
          }
        }
        &.title {
          td {
            background-color: #1C7A60;
            text-align: center;
            height: 80px;
            font-size: 20px;
            color: #fff;
            font-family: OPPOSans-Heavy;
          }
        }
        &.text {
          td {
            text-align: center;
            height: 80px;
            background-color: #FBE4D5;
            font-family: OPPOSans;
          }
          div {
            &:first-child {
              font-size: 20px;
              font-family: OPPOSans-Heavy;
            }
            &:nth-child(2) {
              font-size: 18px;
              margin: 5px 0;
            }
            &:nth-child(3) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
    .card {
      width: 1280px;
      height: 397.5px;
      margin-top: 40.5px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .title {
        position: relative;
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        padding-left: 32px;
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          content: '';
          width: 16px;
          height: 16px;
          background: rgba(0, 192, 153, 1);
          border-radius: 50%;
        }
      }
      .box {
        display: flex;
        margin-top: 20px;
        .imageBox {
          width: 600px;
          height: 337.5px;
          overflow: hidden;
          border-radius: 8px;
        }
        .content {
          flex: 1;
          margin-left: 24px;
          .boxTitle {
            padding: 16px 10px;
            width: 656px;
            height: 60px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            color: rgba(51, 51, 51, 1);
            font-family: OPPOSans-Heavy;
            font-size: 28px;
            line-height: 28px;
          }
          .txt {
            padding: 24px;
            // color: rgba(51, 51, 51, 1);
            font-weight: bold;
            color: #666666;
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
    .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
  }
</style>
