<template>
  <HeaderIndex :hidden="true" />
  <BgMenu title="personal</br>center" :uri="require('../../../assets/img/personal/personal.png')" />
  <div class="personalCancelOrder">
    <div class="titleBox">
      <div class="title">Hi, {{ username }}</div>
      <div class="txt">Could you tell us why you want to cancel the order？</div>
    </div>
    <div class="box">
      <el-input type="textarea" class="input" placeholder="Please describe the reason why you cancelled the order"></el-input>
    </div>
    <div class="btnBox">
      <div class="btn">Confirm & Submission</div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
  import HeaderIndex from '../../../components/header/index.vue';
  import FooterIndex from '../../../components/footer/index.vue';
  import BgMenu from '../../../components/bg/menu.vue';
  export default {
    name: 'PersonalCancelOrder',
    components: {
      HeaderIndex,
      FooterIndex,
      BgMenu
    },
    data() {
      return {
        username: ''
      }
    },
    beforeMount() {
    const hasToken = Boolean(this.$store.state.user.token);
      if (hasToken) {
        this.username = this.$store.state.user.userInfo.account;
      }
    },
  }
</script>

<style lang="less" scoped>
  .personalCancelOrder {
    width: 1280px;
    margin: 0 auto;
    .titleBox {
      width: 1320px;
      height: 88px;
      margin: 40px auto 28px;
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        margin-top: 12px;
      }
      .txt {
        color: rgba(153, 153, 153, 1);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-top: 16px;
      }
    }
    .box {
      .input /deep/ .el-textarea__inner {
        width: 1280px;
        height: 176px;
        border-radius: 4px;
        background: rgba(251, 250, 250, 1);
        padding: 16px;
        color: rgba(153, 153, 153, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
      }
    }
    .btnBox {
      margin: 64px auto 80px;
      text-align: center;
      .btn {
        width: 359px;
        height: 56px;
        margin: 0 auto;
        border-radius: 4px;
        background: rgba(0, 192, 153, 1);
        box-shadow: 0px 4px 24px rgba(0, 119, 95, 0.24);
        color: rgba(255, 255, 255, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 24px;
        line-height: 56px;
        text-align: center;
      }
    }
  }
</style>
