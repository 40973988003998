<template>
  <div class="inforHistory">
    <div class="txt">For more than three decades, the International Symposium on District Heating and Cooling (DHC) has been one of the major international event with the aim of communicating industrial and academic research in the field of District Heating and Cooling. This biennial symposium has provided an excellent platform to researchers from the academia and practitioners from the industry to interact as well as present their ideas.</div>
    <div class="txt">Historically, this symposium was first initiated by the support of Nordic District Heating Research and launched in 1987 at Lund University, Sweden. Later in 2007, the symposium became an integral part of Nordic/Baltic PhD project by the partial support of Nordic Energy Research and District Heating and Cooling industry. Subsequently, from 2016 onwards the IEA District Heating and Cooling programme (IEA DHC) supports the symposium with the aim to continue the on-going innovation and research as we step into the future. The first symposium was entirely based on the district heating technology, which later became district heating and cooling in 1999.</div>
    <div class="txt">At present, the International Energy Agency District Heating and Cooling programme (IEA DHC) owns this symposium. It is the only non-profit research programme with global outreach for this technology.</div>
  </div>
  <Historys :data="data" />
</template>

<script>
import Historys from './history.vue';

export default {
  name: 'InforHistory',
  data() {
    return {
      data: [
        {
          title: 'Beijing, China',
          txt: 'The 18th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/6.png'),
          date: '2023'
        },
        {
          title: 'Nottingham, UK',
          txt: 'The 17th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/12.png'),
          date: '2021'
        },
        {
          title: 'Hamburg, Germany',
          txt: 'The 16th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/3.png'),
          date: '2018'
        },
        {
          title: 'Seoul, South Korea',
          txt: 'The 15th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/7.png'),
          date: '2016'
        },
        {
          title: 'Stockholm, Sweden',
          txt: 'The 14th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/11.png'),
          date: '2014'
        },
        {
          title: 'Copenhagen, Denmark ',
          txt: 'The 13th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/8.png'),
          date: '2012'
        },
        {
          title: 'Tallinn, Estonia',
          txt: 'The 12th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/2.png'),
          date: '2010'
        },
        {
          title: 'Reykjavik, Iceland',
          txt: 'The 11th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/5.png'),
          date: '2008'
        },
        {
          title: 'Hannover, Germany',
          txt: 'The 10th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/3.png'),
          date: '2006'
        },
        {
          title: 'Helsinki, Finland',
          txt: 'The 9th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/10.png'),
          date: '2004'
        },
        {
          title: 'Trondheim, Norway',
          txt: 'The 8th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/4.png'),
          date: '2002'
        },
        {
          title: 'Lund, Sweden',
          txt: 'The 7th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/11.png'),
          date: '1999'
        },
        {
          title: 'Reykjavik, Iceland',
          txt: 'The 6th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/9.png'),
          date: '1997'
        },
        {
          title: 'Helsinki, Finland',
          txt: 'The 5th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/10.png'),
          date: '1995'
        },
        {
          title: 'Trondheim, Norway',
          txt: 'The 4th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/4.png'),
          date: '1993'
        },
        {
          title: 'Copenhagen, Demark ',
          txt: 'The 3th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/8.png'),
          date: '1991'
        },
        {
          title: 'Reykjavik, Iceland',
          txt: 'The 2th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/5.png'),
          date: '1989'
        },
        {
          title: 'Lund, Sweden',
          txt: 'The 1th International Symposium on District Heating & Cooling',
          url: require('../../../assets/img/city/1.png'),
          date: '1987'
        }
      ]
    }
  },
  components: {
    Historys
  }
}
</script>

<style lang="less" scoped>
.inforHistory {
  width: 1280px;
  margin: 40px auto;
  .txt {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding: 0 10px;
    &:nth-of-type(1) {
      padding-top: 10px;
    }
    &:nth-last-of-type(1) {
      padding-bottom: 10px;
    }
  }
}
</style>
