<template>
  <div class="cardRegister">
    <div class="card" v-for="(item, i) in data" :key="i">
      <div class="title" v-if="item.title">{{item.title}}</div>
      <div class="bg">
        <div class="txt" v-for="(txt, j) in item.txt" :key="j">{{txt}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardRegister',
  props: {
    data: {
      type: Array
    }
  }
}
</script>

<style lang="less" scoped>
.cardRegister {
  width: 100%;
  .card {
    padding-bottom: 24px;
    .title {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
      font-size: 32px;
      line-height: 48px;
      padding: 10px;
    }
    .bg {
      border-radius: 4px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      margin-top: 8px;
      .txt {
        position: relative;
        color: rgba(102, 102, 102, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        padding-left: 63px;
        margin-top: 20px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        &::before {
          position: absolute;
          left: 24px;
          top: 50%;
          display: inline-block;
          transform: translateY(-50%);
          content: '';
          width: 17.5px;
          height: 17.5px;
          background: rgba(0, 192, 153, 1);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
