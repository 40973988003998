<template>
  <HeaderIndex :hidden="true" />
  <BgMenu title="personal</br>center" :uri="require('../../assets/img/personal/personal.png')" />
  <div class="personalIndex">
    <div class="titleBox">
      <div class="title">Hi, {{ username }}</div>
      <div class="txt" v-if="tabKey === 'password'">This is where you can change your personal Settings</div>
      <div class="txt" v-if="tabKey === 'email'">Your current email address is<span class="color">{{ username }}</span></div>
      <div class="txt upload" v-if="tabKey === 'upload'">The file you uploaded</div>
      <div class="txt upload" v-if="tabKey === 'order'">Your order is</div>
    </div>
    <div class="index" v-if="tabKey === 'index'">
      <div class="cardBox">
        <div class="card" v-for="item in data" :key="item.key" @click="changeTab(item.key)">
          <div class="flex">
            <div class="img">
              <img :src="item.url" alt="">
            </div>
            <div class="word">
              <div class="title">{{item.title}}</div>
              <div class="txt">{{item.txt}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="password" v-if="tabKey === 'password'">
      <div class="txt">Please enter the current password</div>
      <el-input class="input" v-model="password1"></el-input>
      <div class="txt">Please enter a new password</div>
      <el-input class="input" v-model="password2"></el-input>
      <div class="txt">Please enter the new password again</div>
      <el-input class="input" v-model="password3"></el-input>
      <div class="btnBox">
        <div class="cancel" @click="cancel">Cancel</div>
        <div class="submission" @click="changePwd">Submission</div>
      </div>
    </div>
    <div class="email" v-if="tabKey === 'email'">
      <div class="form">
        <div class="txt">Please enter the current password</div>
        <el-input class="input"></el-input>
        <div class="btnBox">
          <div class="cancel">Cancel</div>
          <div class="submission">Submission</div>
        </div>
      </div>
    </div>
    <div class="order" v-if="tabKey === 'order'">
      <div class="card" v-for="(item, i) in order" :key="i">
        <div class="name">
          <div class="title">Name of activity</div>
          <div class="data">{{item.title}}</div>
        </div>
        <div class="amount">
          <div class="title">Amount(CNY)</div>
          <div class="data">{{(item.amount/100).toFixed(2)}} CNY</div>
        </div>
        <!-- <div class="sum">
          <div class="title">Sum</div>
          <div class="data">{{item.sum}}</div>
        </div> -->
        <div class="date">
          <div class="title">Order date</div>
          <div class="data">{{item.date}}</div>
        </div>
        <div class="operate">
          <div class="title">operate</div>
          <div class="data">
            <div v-if='item.state==2'>
              <span @click="toOrderInfo(item.id)" style="padding: 2px;background: #00c099; color: white;font-size: 16px;border-radius: 3px; cursor: pointer; width: 100px; text-align: center; display: inline-block;">Download</span>
              <br>
              <span @click="refundsOrder(item.id, item.amount)" style="margin-top: 2px; padding: 2px;background: #00c099; color: white;font-size: 16px;border-radius: 3px; cursor: pointer; width: 100px; text-align: center; display: inline-block;" v-if='item.refundRequest==0 || item.refundRequest==3'>Refund</span>
              <span style="margin-top: 2px; padding: 2px;background: #d8db0a; color: white;font-size: 16px;border-radius: 3px; cursor: pointer; width: 100px; text-align: center; display: inline-block;" v-if='item.refundRequest==1 || item.refundRequest==2'>Refunding</span>
            </div>
            <div v-if='item.state==5'>
              <span style="padding: 2px;background: #767676; color: white;font-size: 16px;border-radius: 3px; cursor: pointer; width: 100px; text-align: center; display: inline-block;">Refunded</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="clickTxt">If you would like to cancel your order, please <span class="click" @click="toCancelHandle">click here</span></div> -->
    </div>
    <div class="upload" v-if="tabKey === 'upload'">
      <div class="card" v-for="(item, i) in upload" :key="i">
        <div class="name">
          <div class="title">File name</div>
          <div class="data">{{item.title}}</div>
        </div>
        <div class="date">
          <div class="title">Upload the date</div>
          <div class="data">{{item.date}}</div>
        </div>
        <div class="operate">
          <div class="title">Operate</div>
          <div class="data">
            <div class="btn">preview</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterIndex />

</template>

<script>
import { h } from 'vue';
import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import {htmlDecode} from '@/utils/index'
//
import { postChangePwd } from '../../api/login';
// import { getOrderList, refundsOrderInfo } from '../../api/payment';
import { getOrderList, refundsRequest } from '../../api/payment';
import md5 from 'js-md5'

//
export default {
  name: 'PersonalIndex',
   data() {
    return {
      username: '',
      tabKey: 'index',
      data: [
        {
          title: 'Change password',
          key: 'password',
          txt: 'If you need to change your password please click here',
          url: require('../../assets/img/personal/icon_password@3x.png')
        },
        // {
        //   title: 'Your upload',
        //   key: 'upload',
        //   txt: 'If you need to change your upload please click here',
        //   url: require('../../assets/img/personal/icon_pdf@3x.png')
        // },
        {
          title: 'Change the email',
          key: 'email',
          txt: 'If you need to change your email please click here',
          url: require('../../assets/img/personal/icon_email@3x.png')
        },
        {
          title: 'Your order',
          key: 'order',
          txt: 'If you need to change your order please click here',
          url: require('../../assets/img/personal/icon_order@3x.png')
        }
      ],
      order: [
        // {
        //   title: 'The 18th International Symposium on District Heating & Cooling',
        //   amount: '1',
        //   sum: '$499',
        //   date: '2023-04-25'
        // }
      ],
      upload: [
        // {
        //   title: 'Internet big data',
        //   operate: 'url',
        //   date: '2023-04-25'
        // }
      ],
      password1: '',
      password2: '',
      password3: ''
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu
  },
  computed: {

  },
  beforeMount() {
    const hasToken = Boolean(this.$store.state.user.token);
    if (hasToken) {
      this.username = this.$store.state.user.userInfo.account;
    }
    else this.$router.push(
      {
        path: '/login'
      }
    );
    axios.all([
      getOrderList(),
    ]).then(axios.spread((orderList) => {
      this.order = orderList.data.data.records.map(_ => ({
        title: htmlDecode(_.subject),
        // amount: (_.amount/100).toFixed(2),
        amount:_.amount,
        id: _.payOrderId,
        date: _.createdAt,
        state: _.state,
        refundRequest: _.refundRequest
      }))
    }));
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
    },
    toCancelHandle() {
      this.$router.push(
        {
          path: '/personal/cancelorder'
        }
      );
    },
    cancel() {
      window.location.reload();
    },
    changePwd() {
      postChangePwd(md5(this.password1), md5(this.password2), md5(this.password3)).then(res => {
        if(res.data.code === 200) {
          ElMessage({
            type: 'success',
            message: 'Password modification successful!',
          });
          window.location.reload();
        }
      })
    },
    toOrderInfo(id) {
      this.$router.replace('/receipt?id=' + id);
    },
    refundsOrder(id) {
      ElMessageBox.confirm(
        'Are you sure you want a refund?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      .then(() => {
        // refundsOrderInfo(id, amount, "test").then(() => {
        refundsRequest(id).then(() => {
          this.order.forEach(item => {
            if(item.id === id) {
              item.refundRequest = 1
            }
          })
          ElMessage({
            message: h('p', null, [
              h('span', { style: 'color: teal' }, 'Your refund request has been submitted, please pay attention to wait for review'),
              // h('span', { style: 'color: teal' }, 'Refund successful, please pay attention to the bank statement.'),
            ]),
          })
        }).catch(() => {
          ElMessage({
            message: h('p', null, [
              h('span', null, 'Refund failed, please contact customer service.'),
            ]),
          })
        })
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: 'Refund canceled',
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personalIndex {
    .titleBox {
      width: 1320px;
      height: 88px;
      margin: 40px auto 28px;
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        margin-top: 12px;
      }
      .txt {
        color: rgba(153, 153, 153, 1);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-top: 16px;
        &.upload {
          font-size: 32px;
          line-height: 48px;
        }
        .color {
          color: rgba(255, 6, 6, 1);
          margin-left: 24px;
        }
      }
    }
  .index {
    width: 1320px;
    margin: 40px auto 80px;
    .cardBox {
      padding: 0 18px;
      .card {
        display: inline-block;
        width: 612px;
        height: 144px;
        border-radius: 8px;
        background: rgba(251, 250, 250, 1);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        margin-top: 40px;
        cursor: pointer;
        .flex {
          padding: 24px;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
        }
        &:nth-of-type(1), &:nth-of-type(2) {
          margin-top: 0;
        }
        &:nth-of-type(2n + 1) {
          margin-right: 20px;
        }
        .img {
          width: 96px;
          height: 96px;
        }
        .word {
          flex: 1;
          margin-left: 24px;
          .title {
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
          }
          .txt {
            color: rgba(153, 153, 153, 1);
            font-family: OPPOSans;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .password {
    width: 960px;
    margin: 44px auto 80px;
    .txt {
      margin: 40px 0 24px 0;
      color: rgba(102, 102, 102, 1);
      font-weight: 500;
      font-size: 24px;
    }
    .input {
      width: 960px;
      height: 72px;
      border: 1px solid rgba(203, 203, 203, 1);
      box-sizing: border-box;
      background: rgba(251, 250, 250, 1);
    }
    .btnBox {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 104px;
      .cancel, .submission {
        display: inline-block;
        width: 273px;
        height: 80px;
        border-radius: 8px;
        box-sizing: border-box;
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 32px;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
      }
      .cancel {
        border: 2px solid rgba(0, 192, 153, 1);
        color: rgba(0, 192, 153, 1);
        background: rgba(255, 255, 255, 1);
        margin-right: 128px;
      }
      .submission {
        background: rgba(0, 192, 153, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .email {
    width: 1320px;
    margin: 24px auto 80px;
    .form {
      width: 1200px;
      margin: 0 auto;
      .txt {
        margin: 0 0 24px 0;
        color: rgba(102, 102, 102, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 20px;
      }
      .input {
        width: 1200px;
        height: 48px;
        box-sizing: border-box;
        /deep/ .el-input__wrapper {
          border-radius: 4px;
          border: 1px solid rgba(203, 203, 203, 1);
          background: rgba(251, 250, 250, 1);
        }
      }
      .btnBox {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 64px;
        .cancel, .submission {
          display: inline-block;
          width: 172px;
          height: 48px;
          border-radius: 4px;
          box-sizing: border-box;
          line-height: 48px;
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          cursor: pointer;
        }
        .cancel {
          border: 2px solid rgba(0, 192, 153, 1);
          color: rgba(0, 192, 153, 1);
          background: rgba(255, 255, 255, 1);
          margin-right: 40px;
        }
        .submission {
          background: rgba(0, 192, 153, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .order {
    width: 1320px;
    margin: 52px auto 80px;
    .box {
      width: 1320px;
      height: 136px;
      margin-bottom: 88px;
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        margin: 24px 0;
      }
      .txt {
        color: rgba(153, 153, 153, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
      }
    }
    .card {
      display: flex;
      width: 1280px;
      height: 188px;
      background: rgba(251, 250, 250, 1);
      margin-top: 40px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      padding: 24px;
      .date {
        width: 300px;
        margin-right: 0px;
        .title {
          text-align: center;
        }
        .data {
          color: rgba(29, 174, 255, 1);
          font-weight: bold;
        }
      }
      .sum {
        width: 169px;
        margin-right: 0px;
        .title {
          text-align: center;
        }
        .data {
          text-align: right;
          color: rgba(255, 6, 6, 1);
          font-weight: bold;
        }
      }
      .amount {
        width: 226px;
        margin-right: 0px;
        .title {
          text-align: center;
        }
        .data {
          text-align: center;
          color: rgba(242, 189, 0, 1);
          font-weight: bold;
        }
      }
      .name {
        flex: 1;
        margin-right: 17.7px;
        .title {
          text-align: left;
        }
        .data {
          color: rgba(0, 192, 153, 1);
          width: 100%;
          font-weight: 500;
        }
      }
      .operate {
        width: 100px;
      }
      .date, .sum, .amount, .name, .operate {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
          width: 100%;
          height: 28px;
          color: rgba(102, 102, 102, 1);
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
        .data {
          align-items: center;
          display: flex;
          flex: 1;
          height: 64px;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
    .clickTxt {
      margin-top: 34px;
      color: rgba(153, 153, 153, 1);
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      .click {
        color: rgba(0, 123, 101, 1);
        font-family: OPPOSans-Heavy;
        font-size: 20px;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }
  .upload {
    width: 1320px;
    margin: 48px auto 236px;
    .card {
      display: flex;
      width: 1280px;
      height: 156px;
      background: rgba(251, 250, 250, 1);
      margin-bottom: 40px;
      padding: 24px;
      .date {
        width: 229px;
        margin-right: 97.7px;
        .title {
          text-align: center;
        }
        .data {
          color: rgba(29, 174, 255, 1);
          font-weight: bold;
          font-size: 20px;
          line-height: 48px;
        }
      }
      .operate {
        width: 177px;
        .title {
          text-align: center;
        }
        .data {
          .btn {
            width: 149px;
            height: 48px;
            border-radius: 4px;
            background: rgba(0, 192, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-weight: bold;
            font-size: 24px;
            text-align: center;
          }
        }
      }
      .name {
        flex: 1;
        margin-right: 97.7px;
        .title {
          text-align: left;
        }
        .data {
          width: 100%;
          color: rgba(0, 192, 153, 1);
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
        }
      }
      .date, .operate, .name {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
          width: 100%;
          height: 28px;
          line-height: 28px;
          color: rgba(102, 102, 102, 1);
          font-weight: 500;
          font-size: 18px;
        }
        .data {
          align-items: center;
          display: flex;
          flex: 1;
          font-family: OPPOSans;
          font-size: 28px;
          line-height: 48px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
