<template>
  <HeaderIndex />
  <BgMenu title="Cooperation" :uri="require('../../assets/img/cooperationpage.png')" />
  <div class="main">
    <div class="textBox">The 18th International Symposium on District Heating and Cooling (DHC2023) will be held in Beijing during September 3–6, 2023. It is the first DHC international symposium hosted by China as a member of the IEA DHC technology cooperation program. At the symposium, experts and scholars in the field of DHC will discuss the opportunities and prospects of the industry and promote green, low-carbon, efficient and intelligent development. The symposium aims to actively respond to the global trend of carbon neutrality and promote the efficient use of energy and the intelligent transformation of the DHC industry.</div>
    <div class="textBox">At the launch of the official website, the Organizing Committee has officially initiated the sponsorship recruitment process. Companies in the field of district heating and cooling from around the world are cordially invited to join the conference sponsorship program. We enable your company to gain exposure and global influence through on-site and online platforms, and build bridges with frontline researchers.</div>
    <div class="centerBox">
      <div class="left">
        <div class="header">Sponsor Package</div>
        <div class="txt">Sponsor fee: 100,000 CNY</div>
        <div class="txt">We offer tailored forms of advertisements for organizations and companies of any size. Sponsorship packages include but are not limited to:</div>
      </div>
      <div class="right"></div>
    </div>
    <div class="listBox">
      <div class="list">On-site booth on the technology exhibition</div>
      <div class="list">Logo signage display during the symposium</div>
      <div class="list">Logo on the symposium brochure</div>
      <div class="list">Logo at the bottom of the DHC2032 website (with a link to the company website)</div>
      <div class="list">Logo on the WeChat public account of CDHA and THUBERC in Chinese</div>
      <div class="list">On-site technology and product presentation (if time permits)</div>
    </div>
    <div class="titleBox">Entry requirements</div>
    <div class="listBox">
      <div class="list">Companies capable of assuming civil liability independently, in line with the requirements of industry operating laws and regulations.</div>
      <div class="list">Companies with good credit and no administrative penalty information or serious illegal information in the past three years.</div>
      <div class="list">Equipment suppliers or information technology and technical service companies in the field of district heating and cooling.</div>
      <div class="list">The content and form of advertisements must comply with the laws and regulations of China; the release of false and illegal advertisements must be firmly eliminated. Priority will be given to brands related to the DHC industry with a certain recognition.</div>
      <div class="list">The above qualifications will be reviewed by the Organizing Committee.</div>
    </div>
    <div class="titleBox">Contact</div>
    <div class="contactBox">
      <p>For companies in China, please contact Ms. Jingfeng Qi. Tel: +86-13681398003</p>
      <p>For overseas companies, please send an e-mail to <a href="mailto:contact@dhc2023.com.cn">contact@dhc2023.com.cn</a>.</p>
    </div>
    <div class="backhomeBox">
      <div class="backhome" @click="gohome">Back to Home</div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';

export default {
  name: 'CooperationPage',
  data() {
    return {
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
  },
  methods:{
    gohome() {
      this.$router.push({path: '/'})
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 32px auto;
  .textBox {
    color: rgba(51, 51, 51, 1);
    font-family: OPPOSans;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
  }
  .centerBox {
    display: flex;
    margin-top: 40px;
    .left, .right {
      flex: 1;
    }
    .left {
      .header {
        color: rgba(0, 123, 101, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
      }
      .txt {
        margin-top: 20px;
        color: rgba(102, 102, 102, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        margin-right: 40px;
      }
    }
    .right {
      background: url('../../assets/img/Frame_502@2x.png') no-repeat;
      background-size: 100%;
    }
  }
  .listBox {
    margin-top: 30px;
    border-radius: 8px;
    background: rgba(248, 248, 248, 1);
    padding: 20px;
    .list {
      position: relative;
      color: rgba(102, 102, 102, 1);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding-left: 32px;
      margin-top: 20px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        width: 12px;
        height: 12px;
        background: rgba(0, 192, 153, 1);
        border-radius: 50%;
      }
      .link {
        color: rgba(0, 192, 153, 1);
        font-weight: bold;
        text-decoration-line: none;
      }
    }
  }
  .titleBox {
    color: rgba(0, 123, 101, 1);
    font-family: OPPOSans;
    font-weight: bold;
    font-size: 24px;
    margin-top: 32px;
  }
  .contactBox {
    margin-top: 20px;
    p {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin: 0;
      a {
        color: rgba(0, 122, 255, 1);
        text-decoration-line: none;
      }
    }
  }
  .backhomeBox {
    margin-top: 90px;
    margin-bottom: 56px;
    text-align: center;
    .backhome {
      margin: 0 auto;
      width: 260px;
      height: 56px;
      line-height: 56px;
      border-radius: 4px;
      border: 1px solid rgba(0, 192, 153, 1);
      box-sizing: border-box;
      color: rgba(0, 123, 101, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
