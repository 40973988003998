<template>
  <div class="inforNews">
    <Card :data="data" :changeTab="changeTab" />
  </div>
</template>

<script>
import Card from '../../../components/card/inforNews.vue';
import { getPageInfo } from '../../../api/data';
export default {
  name: 'InforNews',
  data() {
    return {
      data: [
        {
          img: '',
          title: '',
          txt: '',
          date: ''
        } ]
    }
  },
  components: {
    Card
  },
  async beforeMount() {
    const result = await getPageInfo('home-news', 1000, null, 'time, sort');
    this.data = result.records.map(_ => ({
      id: _.id,
      img: _.images,
      title: _.title,
      txt: _.content.substring(0, 300),
      date: _.time
    }));
  },
  methods: {
    changeTab(id) {
      console.log(id)
      this.$router.push(
        {
          path: '/information_news',
          query: {
            id: id
          }
        }
      );
    },
  }
}
</script>

<style lang="less" scoped>
.inforNews {
  width: 1280px;
  margin: 0 auto;
}
</style>
