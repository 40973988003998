<template>
  <div class="bgmain" :style="{'background': `url(${uri})`, 'background-size': 'cover'}">
    <div class="container" v-html="title"></div>
  </div>
</template>

<script>
export default {
  name: 'BgMenu',
  props: {
    title: {
      type: String
    },
    uri: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.bgmain {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 363px;
  text-align: center;
  // filter: blur(20px);
  .container {
    display: inline-block;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1);
    font-family: OPPOSans-Heavy;
    font-size: 58px;
    padding: 24px 33px;
  }
}
</style>
