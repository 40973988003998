export default {
  '1-1-1': {
    txt: 'CNY 3400',
    txtcny: 'CNY 3400',
    cn: 3400
  },
  '1-1-2': {
    txt: 'CNY 4000',
    txtcny: 'CNY 4000',
    cn: 4000
  },
  '1-2-1': {
    txt: 'CNY 3040',
    txtcny: 'CNY 3040',
    cn: 3040
  },
  '1-2-2': {
    txt: 'CNY 3200',
    txtcny: 'CNY 3200',
    cn: 3200
  },
  '2-1-1': {
    txt: 'CNY 3060',
    txtcny: 'CNY 3060',
    cn: 3060
  },
  '2-1-2': {
    txt: 'CNY 3600',
    txtcny: 'CNY 3600',
    cn: 3600
  },
  '2-2-1': {
    txt: 'CNY 2750',
    txtcny: 'CNY 2750',
    cn: 2750
  },
  '2-2-2': {
    txt: 'CNY 2900',
    txtcny: 'CNY 2900',
    cn: 2900
  },
  '3-1-1': {
    txt: 'CNY 2040',
    txtcny: 'CNY 2040',
    cn: 2040
  },
  '3-1-2': {
    txt: 'CNY 2400',
    txtcny: 'CNY 2400',
    cn: 2400
  },
  '3-2-1': {
    txt: 'CNY 1800',
    txtcny: 'CNY 1800',
    cn: 1800
  },
  '3-2-2': {
    txt: 'CNY 1900',
    txtcny: 'CNY 1900',
    cn: 1900
  },
}