<template>
  <div class="imageGallery">
    <div :class="min ? 'min imageIndex' : 'imageIndex'">
      <div class="left" @click="carouselLeft"></div>
      <div class="right" @click="carouselRight"></div>
      <el-carousel
        class="carousel"
        :interval="5000"
        arrow="always"
        :height="min ? '600px' : '650px'"
        indicator-position="none"
        @change="handle"
        ref="carousel">
        <el-carousel-item v-for="item in data" :key="item.url">
          <div class="imageIndexBox">
            <div class="imageIndexBoxSh">
              <img :src="item.url" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div :class="min ? 'min bottom' : 'bottom'">
      <div class="size">
        <div class="box" v-for="item in bottomData" :key="item.url">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageGallery',
  data() {
    return {
      bottomData: []
    };
  },
  props: {
    data: Array,
    min: Boolean
  },
  mounted () {             
    this.bottomData = this.data;      
  }, 
  methods: {
    carouselLeft() {
      this.$refs.carousel.prev();
    },
    carouselRight() {
      this.$refs.carousel.next();
    },
    handle(curIndex) {
      const len = this.data.length;
      if (len <= 4) return;
      const data = [this.data[curIndex]];
      let nextIndex = curIndex + 1;
      const max = this.min ? 3 : 4;
      while(data.length < max) {
        nextIndex = nextIndex > len - 1 ? 0 : nextIndex;
        data.push(this.data[nextIndex])
        nextIndex = nextIndex + 1;
      }
      this.bottomData = data;       
    }
  }
}
</script>

<style lang="less" scoped>
.imageGallery {
  .imageIndex {
    position: relative;
    width: 1000px;
    height: 670px;
    margin: 0 auto;
    text-align: center;
      &.min {
        height: 600px;
        .carousel {
          height: 600px;
        }
      }
    .carousel {
      position: relative;
      width: 1000px;
      height: 670px;
      margin: 0 auto;
      /deep/ .el-carousel__arrow--left, /deep/ .el-carousel__arrow--right {
        display: none;
      }
      /deep/ .el-carousel__item.is-active {
        overflow: visible !important;
      }
      .imageIndexBox {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 8px;
        .imageIndexBoxSh {
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
          padding: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .left, .right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
    .left {
      left: -144px;
      background: url('../../assets/img/home/翻页_Property_1_向后@3x.png');
      background-size: cover;
    }
    .right {
      right: -144px;
      background: url('../../assets/img/home/翻页_Property_1_向前@3x.png');
      background-size: cover;
    }
  }
  .bottom {
    width: 1320px;
    height: 248px;
    margin-top: 42px;
    padding: 31px 4.5px;
    overflow: hidden;
    &.min {
      width: 1000px;
      margin: 0 auto 0;
      padding: 0px 4.5px;
      .box {
        margin-right: 25px;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    .size {
      width: 100%;
      height: 186px;
      overflow-y: hidden;
    }
    .box {
      display: inline-block;
      width: 312px;
      height: 186px;
      margin-right: 21px;
      padding: 5px;
      border-radius: 5px;
      border: 6.2px solid rgba(255, 255, 255, 1);
      box-sizing: border-box;
      background: rgba(239, 232, 232, 1);
      box-shadow: 0px 0px 8px 2px rgba(0, 129, 137, 0.12);
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>
