import request from '@/utils/axios'

/** 支付体验下单配置 **/
export const payTestOrder = (parameter) =>
    request({
        url: '/api/framework-payment/paytest/payOrders',
        method: 'post',
        data: parameter
    })

/** 支付下单配置 **/
export const payOrder = (parameter) =>
    request({
        url: '/api/framework-payment/order/pay',
        method: 'post',
        data: parameter
    })

/**
 * 支付接口正是环境
 * @param {*} parameter 
 * @returns 
 */
export const orderPay = (parameter) =>
    request({
        url: '/api/framework-payment/order/pay',
        method: 'post',
        data: parameter
    })

/**
 * 支付前置接口
 * @param {*} affiliation - 附属内容
 * @param {*} amount - amount
 * @param {*} country 
 * @param {*} feeType - 费用类型 1: Early bird fee 2:Regular fee
 * @param {*} givenName 
 * @param {*} materials - {url:xxx,filename="abc.xxx"}
 * @param {*} mchOrderNo 
 * @param {*} paperId 
 * @param {*} participationMethod 
 * @param {*} registrantCategory 
 * @param {*} specialDietary 
 * @param {*} specialDietaryType 
 * @param {*} surname 
 * @param {*} title 
 * @returns 
 * @description
 *  http://49.232.138.149:8800/doc.html#/default/order-item-controller/saveUsingPOST_6
 */
export function orderPayPre (
    affiliation,
    amount,
    country,
    feeType,
    givenName,
    materials,
    mchOrderNo,
    paperId,
    participationMethod,
    registrantCategory,
    specialDietary,
    specialDietaryType,
    surname,
    title,
    invitationLetter
  ) {
    return request({
        url: `/api/framework-payment/orderitem/save`,
        method: 'post',
        data: {
            affiliation,
            amount,
            country,
            feeType,
            givenName,
            materials,
            mchOrderNo,
            paperId,
            participationMethod,
            registrantCategory,
            specialDietary,
            specialDietaryType,
            surname,
            title,
            invitationLetter,
            updateTime: new Date().getTime(),
          }
    })
  }

/**
 * 用户订阅订单时的用户信息
 * @param {Object} file - 文件二进制
 * @description
 *  swagger：http://49.232.138.149:8800/doc.html#/default/%E5%AF%B9%E8%B1%A1%E5%AD%98%E5%82%A8%E7%AB%AF%E7%82%B9/putFileUsingPOST_2
 */
// export const updateOrderUserFileUrl = `/api/framework-resource/oss/endpoint/put-file`;
export const updateOrderUserFileUrl = `/api/framework-resource/oss/endpoint/put-file`;
export function updateOrderUserFile (file) {
  return request({
    url: updateOrderUserFileUrl,
    method: 'post',
    data: file
  });
}



/**
 * 获取订单列表
 * @param {Number} pageSize - 页码
 * @param {Number} pageNumber - 页面尺寸
 * @description
 * swagger: http://49.232.138.149:8800/doc.html#/default/order-controller/listUsingGET_24
 * 调用方式：http://49.232.138.149:8082/api/framework-payment/order?pageSize=10&pageNumber=2
 */
export const getOrderList = (pageSize = 1, pageNumber = 10) =>
    request({
        url: `/api/framework-payment/order/myorder?pageSize=${pageSize}&pageNumber=${pageNumber}&descs=created_at`,
        method: 'get',
    })

/**
 * 获取订单详情
 * @param {String} orderId - 订单 ID，eq: P1630528430237466626
 * @description
 *  swagger：http://49.232.138.149:8800/doc.html#/default/order-controller/detailUsingGET_21
 *  调用方式：http://49.232.138.149:8082/api/framework-payment/order/P1630528430237466626
 */
export const getOrderInfo = (orderId) => 
    request({
      url: `/api/framework-payment/order/${orderId}`,
      method: 'get',
    })

// 退单
export const refundsOrderInfo = (orderId, refundAmount, refundReason) =>
  request({
    url: `/api/framework-payment/order/refunds/${orderId}`,
    method: 'post',
    params: {
          refundAmount,
          refundReason
        }
  })

// 退款申请
export const refundsRequest = (orderId) =>
    request({
      url: `/api/framework-payment/order/refunds-request/${orderId}`,
      method: 'post'
    })
