<template>
  <div class="eventSessions">
    <div class="table-box">
      <table>
        <tr class="title">
          <td colspan="3">September 4, Monday</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 1 - RES & Waste Heat</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Dr. Hao Fang (Doctor of Heating Engineering Center of Inner Mongolia Autonomous Region)</div>
          </td>
        </tr>
        <tr>
          <td>13:30-13:45</td>
          <td>Research on Urban Energy Planning Using Industrial Waste Heat for District Heating: A Case Study in Tangshan</td>
          <td>Tsinghua University, China, Ying Kong</td>
        </tr>
        <tr>
          <td>13:45-14:00</td>
          <td>Waste Heat Recovery from Data Centers for Decarbonization of Heat Supply in District Heating Systems</td>
          <td>University of Stuttgart, Germany, Benjamin Ott</td>
        </tr>
        <tr>
          <td>14:00-14:15</td>
          <td>Case Study on Combined Heat and Water System for District Heating in Beijing by Recovering Industrial Waste Heat in Tangshan</td>
          <td>Tsinghua University, China, Xiaolin Yang</td>
        </tr>
        <tr>
          <td>14:15-14:30</td>
          <td>Annex TS5: RES DHC Technology and Application Fact Sheets</td>
          <td>PlanEnergi, Denmark, Magdalena Komoszynska</td>
        </tr>
        <tr>
          <td>14:30-14:45</td>
          <td>Research on Energy Saving and Carbon Reduction Paths for Waste Heat Heating in Inner Mongolia Industrial Park</td>
          <td>Chifeng University, China, Chunlin Wang</td>
        </tr>
        <tr>
          <td>14:45-15:00</td>
          <td>Large-Scale District Heating Systems Utilizing Multi-Stage Electric Heat Pumps and Heat Storage to Discharge Data Center Waste Heat</td>
          <td>Tsinghua University, China, Yang Jing</td>
        </tr>
        <tr>
          <td>15:00-15:15</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>15:15-15:30</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 2 - Pipeline & Network</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Haichao Wang (Associate Professor, Dalian Technology University)</div>
          </td>
        </tr>
        <tr>
          <td>13:30-13:45</td>
          <td>Data-Driven Fault Detection and Diagnosis for Secondary District Heating Networks</td>
          <td>Beijing District Heating Project Design Co. Ltd, China, Hui Gao</td>
        </tr>
        <tr>
          <td>13:45-14:00</td>
          <td>Numerical Analysis of Complex Network Hydraulics in Cold District Heating Networks: A Simulation Study of a District from A German Living Lab Project</td>
          <td>RWTH Aachen University, Germany, Rahul M Karuvingal</td>
        </tr>
        <tr>
          <td>14:00-14:15</td>
          <td>Safety Evaluation of District Heating Pipeline Network Based on Corrosion Failure Probability Analysis</td>
          <td>Yanshan University, China, Huiling An</td>
        </tr>
        <tr>
          <td>14:15-14:30</td>
          <td>INSTANDHALTUNG-FW: Preparing the Ground on the Pathway to Predictive Maintenance in District Heating</td>
          <td>AGFW, Germany, Heiko Huther</td>
        </tr>
        <tr>
          <td>14:30-14:45</td>
          <td>Research and Application of New Technology for Ductile Iron Thermal Pipes</td>
          <td>Yanshan University, China, Yifeng Ma</td>
        </tr>
        <tr>
          <td>14:45-15:00</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>15:00-15:15</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 3 - RES & Waste Heat</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Dr. Hao Fang (Doctor of Heating Engineering Center of Inner Mongolia Autonomous Region)</div>
          </td>
        </tr>
        <tr>
          <td>15:30-15:45</td>
          <td>Thermoeconomic Analysis of Coking Waste Heat District Heating and Cooling Based on Multiple Heterogeneous Heat Flows Reconfiguration</td>
          <td>Beijing University of Civil Engineering and Architecture, China,
Fangtian Sun</td>
        </tr>
        <tr>
          <td>15:45-16:00</td>
          <td>Transformation of DHC Systems to Higher Shares of RES – Technical and Organizational Solutions of Six European Regions</td>
          <td>AEE – Institute for Sustainable Technologies
Carina, Austria,
Seidnitzer-Gallien</td>
        </tr>
        <tr>
          <td>16:00-16:15</td>
          <td>Analysis of Carbon Emissions Using Biomass Energy to Replace Coal in Rural Buildings Heating Sector</td>
          <td>School of Architecture, Harbin Institute of Technology, China,
Cun Wei</td>
        </tr>
        <tr>
          <td>16:15-16:30</td>
          <td>Assessment of Industrial Waste Heat: An Investigation in Tangshan</td>
          <td>Tsinghua University, China,
Zhaoyang Liu</td>
        </tr>
        <tr>
          <td>16:30-16:45</td>
          <td>Utilizing Waste Heat from Data Centers to Supply Residential Quarters: A Simulation Study</td>
          <td>Chemnitz University of Technology, Germany,
Maximilian Stahlhut</td>
        </tr>
        <tr>
          <td>16:45-17:00</td>
          <td>Application Analysis of Data Centers as District Heating Sources</td>
          <td>Shandong Jianzhu University, China,
Junhong Liu</td>
        </tr>
        <tr>
          <td>17:00-17:15</td>
          <td>Experience Sharing of Absorption Heat Pump in Industrial Waste Heat Utilization</td>
          <td>HYTM, China,
Weiwei Yang</td>
        </tr>
        <tr>
          <td>17:15-17:30</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 4 - Pipeline & Network</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Yonghong Li (Doctor of Tsinghua University, Deputy Secretary General of Urban Energy Planning Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td>15:15-15:30</td>
          <td>Status Assessment, Ageing, Lifetime Prediction and Asset Management of District Heating Pipes</td>
          <td>AGFW, Germany,
Heiko Huther</td>
        </tr>
        <tr>
          <td>15:30-15:45</td>
          <td>Evaluation of Heat Transferred Between Facilities via An Uninsulated-Pipe Network on A Heat-Source Water Network</td>
          <td>Osaka City University, Japan
Jincheng Dai</td>
        </tr>
        <tr>
          <td>15:45-16:00</td>
          <td>Study on the Effect of Large Thrust on Lining of Heating Shield Tunnel</td>
          <td>Beijing District Heating Project Design Company, China,
Yangpeng Liu</td>
        </tr>
        <tr>
          <td>16:00-16:15</td>
          <td>Accurate Modeling of Thermal Losses in Heating Networks for Efficient Operational Planning</td>
          <td>Technische Universität Darmstadt, Germany,
Pascal Friedric</td>
        </tr>
        <tr>
          <td>16:15-16:30</td>
          <td>Assessing Machine Learning Algorithms for Leak Detection in Smart District Heating Networks</td>
          <td>Univ. Grenoble Alpes, France,
Mohammed Ali Jallal</td>
        </tr>
        <tr>
          <td>16:30-16:45</td>
          <td>Industry Application of Insulated Pipeline and Distributed Fiber Optic Temperature Measurement Technology</td>
          <td>Tangshan Xingbang Pipelien Engineering Equipmene, China,
Zihan Wang</td>
        </tr>
        <tr>
          <td>16:45-17:00</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr class="text text2">
          <td colspan="3">
            <div>Special Session 1 - New Trends in the Future Development of Urban and Rural Heating in China (CDHA: Technical Committee, Standardization Committee, Rural clean heating Committee, Urban energy Planning Committee)</div>
            <div>Meeting Room 1</div>
            <div>Chair: Rong Liu (Vice Chairman of China District Heating Association, Deputy Director of Heating Technology Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td>13:30-14:10</td>
          <td>Low Carbon Heating Technology Route for Urban Areas in China</td>
          <td>Lin Fu</td>
        </tr>
        <tr>
          <td>14:10-14:50</td>
          <td>Development Direction of Clean Heating in Rural Areas of China</td>
          <td>Xudong Yang</td>
        </tr>
        <tr>
          <td>15:00-15:15</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>15:15-15:30</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text text2">
          <td colspan="3">
            <div>Special Session 2 - Sino-Danish Perspectives on Green District Energy</div>
            <div>Meeting Room 1</div>
            <div>Chair: Jianjun Xia (Associate Professor, Tsinghua University, Deputy Director of the Heating Technology Committee of China District Heating Association)
Peng Zhang (Deputy Director engineer, New Energy Academy, Hydropower and Water Conservancy Planning and Design Institute)</div>
          </td>
        </tr>
        <tr>
          <td>15:30-15:40</td>
          <td>Welcome and Opening Remarks</td>
          <td>Danish Energy Agency, Deputy Director General, Stig Uffe Pedersen</td>
        </tr>
        <tr>
          <td>15:40-15:50</td>
          <td>Inspirational Lessons of Denmark's Heat Planning for Clean Heating in China</td>
          <td>Danish Energy Agency, Denmark,
Lipeng Zhang</td>
        </tr>
        <tr>
          <td>15:50-16:00</td>
          <td>Capturing Insights for District Heating from Power System Modelling in China</td>
          <td>Danish Energy Agency, Denmark,
Francesco Lovat</td>
        </tr>
        <tr>
          <td>16:00-16:10</td>
          <td>Renewable Energy in District Heating Systems in China: Status and Perspectives</td>
          <td>Aalborg University, Denmark,
Meng Yuan</td>
        </tr>
        <tr>
          <td>16:10-16:20</td>
          <td>GIS-Based Energy Planning for Sustainable District Heating and Cooling Systems: An Application in China</td>
          <td>UNEP-Copenhagen Climate Centre, Denmark
Zhuolun Chen</td>
        </tr>
        <tr>
          <td>16:20-16:30</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>16:30-17:40</td>
          <td colspan="2">Panel Discussion: Sino-Danish Knowledge Exchange for Green District Heating</td>
        </tr>
        <tr>
          <td>17:40-18:00</td>
          <td colspan="2">Audience Q&A session</td>
        </tr>
        <tr class="text text3">
          <td colspan="3">
            <div>Welcome Reception / IEA DHC Awards Ceremony</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Jianjun Xia (Associate Professor, Tsinghua University, Deputy Director of the Heating Technology Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">18:00-21:00</td>
          <td colspan="2">Welcome Reception</td>
        </tr>
        <tr>
          <td colspan="2">IEA DHC Awards Ceremony</td>
        </tr>
        <tr class="title">
          <td colspan="3">September 5, Tuesday</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 5 - Digitalization of DH</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Wei Huang (Member of the Urban Energy Planning Committee of China District Heating Association)
Dirk Vanhoudt (VITO - Flemish Institute for Technological Research NV)</div>
          </td>
        </tr>
        <tr>
          <td>9:00-9:15</td>
          <td>The Simulation Test Bed of Multi-Family Buildings with Hydraulic Radiator Heating System</td>
          <td>Tianjin University, China,
Zhikai Liu</td>
        </tr>
        <tr>
          <td>9:15-9:30</td>
          <td>Construction of Information Model Framework for Heating System Oriented to Digital Twin</td>
          <td> Dalian University of Technology, China,
Zhihao Wang</td>
        </tr>
        <tr>
          <td>9:30-9:45</td>
          <td>Districtlab-H: A New Tool to Optimize the Design and Operation of District Heating and Cooling Networks</td>
          <td>DistrictLab, France,
Roland Bavière</td>
        </tr>
        <tr>
          <td>9:45-10:00</td>
          <td>Application and Energy Analysis of AI-Based Digitalization of District Heating System</td>
          <td>Beijing Shuoren Times technology Co., Ltd, China,
Le He</td>
        </tr>
        <tr>
          <td>10:00-10:15</td>
          <td>A Digital Twin Platform Design of A Central Heating System in Northern China</td>
          <td>Dalian University of Technology, China,
Jianwei Qi</td>
        </tr>
        <tr>
          <td>10:15-10:30</td>
          <td>Dynamic Transport Process Analysis of District Heating Systems Considering Source-Load Uncertainty</td>
          <td>Zhejiang University, China,
Xiaojie Lin</td>
        </tr>
        <tr>
          <td>10:30-10:45</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>10:45-11:00</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 6 - 4GDH & 5GDHC / Urban Energy System</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Qinqin Deng (China Academy of Building Science Co., LTD.  Deputy Secretary-General of Rural Clean Heating Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td>9:00-9:15</td>
          <td>Numerical Analysis of Possible Heat Gains from the Soil for A Cold District Heating and Cooling Network in Germany</td>
          <td>RWTH Aachen University, Germany,
Kai Droste</td>
        </tr>
        <tr>
          <td>9:15-9:30</td>
          <td>4th Generation District Heating Model Based on Energy Prosumer Being Connected to 3rd District Heating Network</td>
          <td>Korea Institute of Energy Research, South Korea,
Jaejoon Choi</td>
        </tr>
        <tr>
          <td>9:30-9:45</td>
          <td>Economic Comparison of Heating and Cooling Supply Systems in Warm Climates – Using A Case Study</td>
          <td>Danfoss A/S, Denmark,
Oddgeir Gudmundsson</td>
        </tr>
        <tr>
          <td>9:45-10:00</td>
          <td>EQ-City - Pre-Planning Approach for An Efficient and Environmentally Friendly Heat Supply of Quarters and Cities</td>
          <td>Fraunhofer Institute for Energy Economics and Energy System Technology IEE, Germany,
Nermina Abdurahmanovic</td>
        </tr>
        <tr>
          <td>10:00-10:15</td>
          <td>Research and Case Analysis of a New Fuzzy Heating Control Theory Based on Supply and Return Temperature Changes</td>
          <td>AutoHeat, China,
Benqiang Zheng</td>
        </tr>
        <tr>
          <td>10:15-10:30</td>
          <td>Transformation and operational optimization of thermal grids for the development of hybrid grid structures</td>
          <td>Fraunhofer Institute for Energy Economics and Energy System Technology IEE, Germany,
Lina Wett</td>
        </tr>
        <tr>
          <td>10:30-10:45</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>10:45-11:00</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 7 - Digitalization of DH</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Wei Huang (Urban Energy Planning Committee of China District Heating Association)
Dirk Vanhoudt (VITO - Flemish Institute for Technological Research NV)</div>
          </td>
        </tr>
        <tr>
          <td>11:00-11:15</td>
          <td>Research on Heating Load Prediction Based on Multiple Meteorological Elements</td>
          <td>Tai'an Meteorological Bureau, China,
Dawei Zou</td>
        </tr>
        <tr>
          <td>11:15-11:30</td>
          <td>Optimization of the Control Strategy of the Low-Temperature District Heating System with the Sewage Heat Pump Based on Digital Twin Modeling</td>
          <td>Tianjin University, China,
Zhonghui Lu</td>
        </tr>
        <tr>
          <td>11:30-11:45</td>
          <td>Predicting Heat Demand for Efficient District Heating Systems Using Deep Learning Algorithms</td>
          <td>Nottingham Trent University, UK
Kevin Naik</td>
        </tr>
        <tr>
          <td>11:45-12:00</td>
          <td>Realization of the Large-Scale Smart District Heating Based on Effective Digitalization Process</td>
          <td>Tianjin University, China,
Xiaochen Yang</td>
        </tr>
        <tr>
          <td>12:00-12:15</td>
          <td>Model-Based Operation Optimization of A Geothermal Cascade Heating System</td>
          <td>Tianjin University, China,
Xinyuan Chen</td>
        </tr>
        <tr>
          <td>12:15-12:30</td>
          <td>Research on the Building Complex Heat Load Prediction and Historical-Data Dependency Based on Subspace Identification Method</td>
          <td>Harbin Institute of Technology, China,
Wei Jiang</td>
        </tr>
        <tr>
          <td>12:30-12:45</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>12:45-13:30</td>
          <td colspan="2">Lunch</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 8 - Heat pump and heat exchanger / CHP</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Tianhao Xu (Doctor of Tsinghua University)</div>
          </td>
        </tr>
        <tr>
          <td>11:00-11:15</td>
          <td>Analysis on Thermoelectric Cooperative Peak Shaving Using Electric Boiler and Heat Storage</td>
          <td>Dalian University of Technology, China,
Jianbo Han</td>
        </tr>
        <tr>
          <td>11:15-11:30</td>
          <td>Thermophysical Property Models of Lubricant Oils and Their Mixtures with Refrigerants for Comprehensive Heat Pump Analysis</td>
          <td>Chemnitz University of Technology, Germany,
Xiaoxian Yang</td>
        </tr>
        <tr>
          <td>11:30-11:45</td>
          <td>Analysis of Three-Stage Hybrid Absorption-Compression Heat Pump Cycles with Large Temperature Lift</td>
          <td>Shanghai Jiao Tong University, China,
Xi Zhang</td>
        </tr>
        <tr>
          <td>11:45-12:00</td>
          <td>Process Heat Supply by Heat Pumps and District Heating</td>
          <td>Chemnitz University of Technology, Germany,
Haochen Wang</td>
        </tr>
        <tr>
          <td>12:00-12:15</td>
          <td>Comparative Analysis of Heat and Power Supply System in China and Nordic Countries</td>
          <td>Tsinghua University, China,
Xu Luo</td>
        </tr>
        <tr>
          <td>12:30-12:45</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>12:45-13:30</td>
          <td colspan="2">Lunch</td>
        </tr>
        <tr class="text text2">
          <td colspan="3">
            <div>Special Session 3 – District Cooling</div>
            <div>Meeting Room 1</div>
            <div>Chair: Yi Jiang (Academician of Chinese Academy of Engineering, Professor of Tsinghua University, Director of Heating Technology Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td rowspan="2">9:00-11:00</td>
          <td>Difference between District Heating and District Cooling</td>
          <td>Prof. Yi Jiang</td>
        </tr>
        <tr>
          <td colspan="2">Q&A</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 9 - Urban Energy System / HES & Smart Grid</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Dr. Xiaochen Yang (Associate Professor, School of Environment, Tianjin University)</div>
          </td>
        </tr>
        <tr>
          <td>13:30-13:45</td>
          <td>Research on the Low-Carbon Transformation Path of Inner Mongolia Rural House Heating System</td>
          <td>Chifeng Academy, China,
Xinyang Li</td>
        </tr>
        <tr>
          <td>13:45-14:00</td>
          <td>Uncertainty and Sensitivity Analysis for Urban Energy System Planning</td>
          <td>Technical University of Munich, Germany
Amedeo Ceruti</td>
        </tr>
        <tr>
          <td>14:00-14:15</td>
          <td>Research on Source Storage Dynamic Procedure-Oriented Operation and Configuration Optimization of District Heating System</td>
          <td>Harbin Institute of Technology, China,
Xuran Ma</td>
        </tr>
        <tr>
          <td>14:15-14:30</td>
          <td>Production Optimization of An Existing District Heating Network with Multiple Heat Producers</td>
          <td>Fraunhofer Institute for Solar Energy Systems, Germany,
Theda Zoschke</td>
        </tr>
        <tr>
          <td>14:30-14:45</td>
          <td>Analysis of the Energy Consumption Characteristics of the Heating System of A Mega Airport Terminal Building: the Example of a Large Airport in Beijing</td>
          <td>Beijing University of Technology, China,
Chao Chen</td>
        </tr>
        <tr>
          <td>14:45-15:00</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>15:00-15:15</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 10 - Energy Storage</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Yichi Zhang (Doctor of Guangdong Midea Group)</div>
          </td>
        </tr>
        <tr>
          <td>13:30-13:45</td>
          <td>Irreversibility Evaluation of Heat Transport in Seasonal Thermal Energy Storage Units for District Heating Applications</td>
          <td>Tsinghua University, China,
Tianhao Xu</td>
        </tr>
        <tr>
          <td>13:45-14:00</td>
          <td>Simulative Investigations of A Hybrid Storage Concept for Industrial Steam Heat Supply</td>
          <td>Chemnitz University of Technology, German,
Dimitri Nefodov</td>
        </tr>
        <tr>
          <td>14:00-14:15</td>
          <td>Seasonal Thermal Energy Storage Using Natural Structures: GIS-Based Potential Assessment for Northern China</td>
          <td>Midea Building Technology, China,
Yichi Zhang</td>
        </tr>
        <tr>
          <td>14:15-14:30</td>
          <td>Swirl Loading of Hot Water Tanks – Detailed Simulation of the Flow in the Diffuser and Storage</td>
          <td>Chemnitz University of Technology, German,
Felix Oestreich</td>
        </tr>
        <tr>
          <td>14:30-14:45</td>
          <td>Research on the Modeling and Operation Strategy of the District Heating and Cooling Integrated Energy System Considering Ice Storage and Cold/Heat Load Demand</td>
          <td>Beijing Jing Neng Thermal Development Co., Ltd., China,
Tingting Qu</td>
        </tr>
        <tr>
          <td>14:45-15:00</td>
          <td>A Semi-Analytic Method for Buoyancy-Induced Thermal Stratification in Hot Water Tanks</td>
          <td>University of Warwick, UK,
Chenghao Li</td>
        </tr>
        <tr>
          <td>15:00-15:15</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td>15:15-15:30</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 11 - Policy & Market</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Dr. Xiaochen Yang (Associate Professor, School of Environment, Tianjin University)</div>
          </td>
        </tr>
        <tr>
          <td>15:15-15:30</td>
          <td>A Comparative Study of Low-Carbon Heating Pathways in China, Japan and Korea</td>
          <td>Tsinghua University, China,
Zihan Zhu</td>
        </tr>
        <tr>
          <td>15:30-15:45</td>
          <td>Analysis and Forecast of Factors Influencing the Setting of Centralized Heat Supply Prices in Northern China's Cities and Towns</td>
          <td>Chifeng University, China,
Junjie Li</td>
        </tr>
        <tr>
          <td>15:45-16:00</td>
          <td>Assessment of Heating and Cooling Sector of Türki̇ye</td>
          <td>Ministry of Energy and Natural Resources, Türkiye,
Bilal Düzgün</td>
        </tr>
        <tr>
          <td>16:00-16:15</td>
          <td>How Can Targeted Heating Solve the Pain Points of Thermal Enterprises?</td>
          <td>Sandong Lcarbo Energy Technology Co., Ltd., China,
Hao Lin</td>
        </tr>
        <tr>
          <td>16:15-16:30</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr class="text">
          <td colspan="3">
            <div>Parallel Session 12 - Energy Storage</div>
            <div>Banquet Hall B, Second Floor</div>
            <div>Chair: Dr. Yichi Zhang (Doctor of Guangdong Midea Group)</div>
          </td>
        </tr>
        <tr>
          <td>15:30-15:45</td>
          <td>Optimization Study of Solar Heating System with Coupling of Water and PCM Thermal Storage</td>
          <td>Dalian University of Technology, China,
Qingqing Gu</td>
        </tr>
        <tr>
          <td>15:45-16:00</td>
          <td>Ammonia-Based Sorption Thermal Battery for Efficient Heat Supply</td>
          <td>Zhejiang University, China,
Long Jiang</td>
        </tr>
        <tr>
          <td>16:00-16:15</td>
          <td>Thermochemical Energy Storage for Heating Applications - An Experimental Study</td>
          <td>University of Birmingham, UK,
Mingxi Ji</td>
        </tr>
        <tr>
          <td>16:15-16:30</td>
          <td>Research and Application of Heat Storage in District Heating Network of Heating Plant</td>
          <td>Beijing University of Technology, China,
Zhiwei Tang</td>
        </tr>
        <tr>
          <td>16:30-16:45</td>
          <td>Research and Application of Graphite Thermal Heat Storage Unit in the Core Area of Beijing</td>
          <td>Beijing Thermal Equipment Manufacturing Co., Ltd, China,
Lianxin Zhang</td>
        </tr>
        <tr>
          <td>16:45-17:00</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr class="text text2">
          <td colspan="3">
            <div>Special Session 4 – IEA DHC TS4</div>
            <div>Meeting Room 1</div>
            <div>Chair: Dirk Vanhoudt (VITO - Flemish Institute for Technological Research NV)
Michele Tunzi (Technical University of Denmark)</div>
          </td>
        </tr>
        <tr>
          <td rowspan="6">13:30-15:30</td>
          <td>Digitalisation in District Heating – with Data to Optimised Systems and New Business Opportunities</td>
          <td> Fraunhofer Institute for Energy Economics and Energy System Technology IEE, Germany,
Dietrich Schmidt</td>
        </tr>
        <tr>
          <td>Improving the Control and Operation of Space Heating Systems through Demand Side Digitalization</td>
          <td>Technical University of Denmark, Denmark,
Michele Tunzi</td>
        </tr>
        <tr>
          <td>Digital Transformation in District Heating Systems: A Scoping Review</td>
          <td>University of Southern Denmark, Denmark,
Zheng Grace Ma</td>
        </tr>
        <tr>
          <td>Exploiting Synergies of Data-Driven and Model-Based Approaches for Leakage Localization in District Heating Networks</td>
          <td>University of Bremen, Germany
Dennis Pierl</td>
        </tr>
        <tr>
          <td>Large-Scale Application of the STORM Controller on a Heat Network in Taiyuan, CN</td>
          <td>VITO - Flemish Institute for Technological Research NV, Belgium,
Somil Miglani</td>
        </tr>
        <tr>
          <td colspan="2">Q&A</td>
        </tr>
      </table>
    </div>
    <!-- <div class="headerTxt">Dunng the 5th andof September, theie will be Para lie! Sessions on eacii research topics of DHC2015 The sessions of tire Symposium will bnng togetfief reseafch and practice and will concerrtrate on rnnovation, development end research aiming atfacifitatingtfie implementation of effictent and susteinatMe district heating and cooling. Speakers from academia and industry will	their approsches and
 sofutions in 15-2D minute p resent a trons. A peer review process by the Scientific Committee ensures a high quality of the presentations and matching conference papers.</div>
    <TitleIndex class="titleIndex" title="Register of Instructions" />
    <div class="txtBox">
      <div class="txt">Date & Time: <span class="value">15:40 - 17:20 / September 5</span></div>
      <div class="txt">Venue: <span class="value">Wuzhou Crown International Hotel，Beijing</span></div>
      <div class="txt">Session Chair: <span class="value">Ming Sun ( Universityof Beijing)</span></div>
    </div>
    <div class="tableBox">
      <div class="title">[ TOPIC D ]Customer Relations and Market Issues</div>
      <table>
        <thead>
          <tr>
            <td>NO</td>
            <td>Title</td>
            <td>Author(a)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            <td>ASSESING THE FEASIBILITU OF USING THE HEAT DEMAND-OUTDOOR  TEMPREATURE FUNCTION FOR A LONG-TERM DISTRICT HEAT DEMAND-FORECAST</td>
            <td>Ahmad Galadanci<br>Robin Wiltshire<br>Svend Svendsen</td>
          </tr>
          <tr>
            <td>02</td>
            <td>HEAT SOURCE SHIFTING IN BUILDINGS SUPPLIED BY DISTRICT HEATING AND EXHAUST AIR HEAT PUMP</td>
            <td>Ahmad Galadanci<br>Robin Wiltshire</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tableBox">
      <div class="title">[ TOPIC D ]Customer Relations and Market Issues</div>
      <table>
        <thead>
          <tr>
            <td>NO</td>
            <td>Title</td>
            <td>Author(a)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            <td>PRACTICAL AND ACCURATE MEASUREMENT OF COGENERATED POWER</td>
            <td>Ahmad Galadanci<br>Robin Wiltshire</td>
          </tr>
          <tr>
            <td>02</td>
            <td>THE ROLE OF INTERMEDIARIES IN THE TRANSITION TO DISTRICT HEATING</td>
            <td>Ahmad Galadanci<br>Robin Wiltshire</td>
          </tr>
          <tr>
            <td>03</td>
            <td>CONSIDERATION OF HIGH EFFICIENT WASTE-TO-ENERGY WITH DISTRICT ENERGYFOR SUSTAINABLE SLOID WASTE MANAGEMENT IN BEIJING</td>
            <td>Ahmad Galadanci<br>Robin Wiltshire</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ModulesIndex /> -->
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
  </div>
</template>

<script>
// import TitleIndex from '../../../components/title/index.vue';
// import ModulesIndex from './modules.vue';

export default {
  name: 'EventSessions',
  components: {
    // TitleIndex,
    // ModulesIndex
  }
}
</script>

<style lang="less" scoped>
  .eventSessions {
    width: 1280px;
    margin: 40px auto 64px;
    .table-box {
    width: 100%;
    // background-color: red;
    table {
        margin-top: 24px;
        // border-collapse: separate;
        // border-spacing: 8px 8px; // 控制间距
        border-collapse: collapse !important;
        border: 1px solid #000 !important;
        td { 
          border-top: 0 !important; 
          border-right: 1px solid #000 !important; 
          border-bottom: 1px solid #000 !important; 
          border-left: 0 !important; 
          min-width: 140px;
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
      }
    table {
      width: 100%;
      tr {
        // padding: 0;
        // margin: 0;
        width: 100%;
        td {
          // color: rgba(153, 153, 153, .4);
          font-family: OPPOSans;
          height: 60px;
          text-align: center;
          &:nth-child(3) {
            text-align: left;
            padding-left: 10px;
          }
        }
        &.title {
          td {
            background-color: #1C7A60;
            text-align: center;
            height: 80px;
            font-size: 20px;
            color: #fff;
            font-family: OPPOSans-Heavy;
          }
        }
        &.text {
          &.text2 td {
            background-color: #DEEAF6 !important;
          }
          &.text3 td {
            background-color: #FBE4D5 !important;
          }
          td {
            text-align: center;
            height: 160px;
            background-color: #E1F7F2;
            font-family: OPPOSans;
          }
          div {
            &:first-child {
              font-size: 20px;
              font-family: OPPOSans-Heavy;
            }
            &:nth-child(2) {
              font-size: 18px;
              margin: 5px 0;
            }
            &:nth-child(3) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
    .headerTxt {
      color: rgba(51, 51, 51, 1);
      // font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
    .titleIndex {
      margin-top: 32px;
    }
    .txtBox {
      width: 1320px;
      height: 184px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      margin-top: 20px;
      .txt {
        color: rgba(102, 102, 102, 1);
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        margin-top: 32px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .value {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
    .tableBox {
      margin-top: 24px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .title {
        color: rgba(51, 51, 51, 1);
        font-family: OPPOSans-Heavy;
        font-size: 20px;
        line-height: 28px;
      }
      table {
        margin-top: 24px;
        // border-collapse: separate;
        // border-spacing: 8px 8px; // 控制间距
        border-collapse: collapse !important;
        border: 0px solid transparent !important;
        td { 
          border-top: 0 !important; 
          border-right: 8px solid white !important; 
          border-bottom: 8px solid white !important; 
          border-left: 0 !important; 
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
        thead {
          tr {
            td {
              background: rgba(0, 192, 153, 1);
              height: 80px;
              &:nth-of-type(1) {
                width: 80px;
              }
              &:nth-of-type(2) {
                width: 826px;
              }
              &:nth-of-type(3) {
                width: 398px;
              }
              color: rgba(255, 255, 255, 1);
              font-family: OPPOSans;
              font-weight: bold;
              font-size: 32px;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              background: rgba(251, 250, 250, 1);
              padding: 24px;
              color: rgba(51, 51, 51, 1);
              font-family: OPPOSans;
              font-weight: bold;
              font-size: 20px;
              line-height: 36px;
              &:nth-of-type(3) {
                text-align: center;
              }
            }
          }
        }
      }
    }
    .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
  }
</style>
