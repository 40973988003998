<template>
  <HeaderIndex />
  <BgMenu title="About Beijing" :uri="require('../../assets/img/about/about.png')" />
  <Menu :data="data" :changeTab="changeTab" :tabKey="tabKey" :tabMargin="tabMargin" />
  <div class="main">
    <Beijing v-if="tabKey === 'beijing'" />
    <Energyplanite v-if="tabKey === 'energyplan'" />
    <Energyplanite v-if="tabKey === 'host'" />
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import Menu from '../../components/menu/index.vue';
import Beijing from './beijing/index.vue';
import Energyplanite from './energyplan/index.vue';

export default {
  name: 'DHCIndex',
  data() {
    return {
      tabKey: 'beijing',
      tabMargin: '290px',
      data: [
        {
          title: 'About Beijing',
          key: 'beijing'
        },
        {
          title: 'China Energy Plan',
          key: 'energyplan'
        },
        {
          title: 'About the Host',
          key: 'host'
        }
      ]
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
    Beijing,
    Energyplanite
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 32px auto;
}
</style>
