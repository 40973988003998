<template>
  <router-view></router-view>
  <a class="totop" href="#"></a>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
#app {
  font-family: 'OPPOSans';
  min-width: 1340px;
  /* overflow: auto; */
}
.totop {
  position: fixed;
  bottom: 50px;
  right: 38px;
  width: 100px;
  height: 100px;
  background: url('./assets/img/totop/回到顶部@2x.png') no-repeat center/cover;
  z-index: 9999;
  cursor: pointer;
}
</style>
