<template>
  <div :class="{
    'header': true,
    'min': min,
    'hidden': hidden
  }">
    <div :class="['container', {'container-home': isHomePage}]">
      <div class="left">
        <div class="title">DHC2023</div>
      </div>
      <div class="right">
        <!-- <span class="txt" @click="regHandle">Register</span> -->
        <span v-if="!hasToken" class="txt" @click="logHandle">Login</span>
        <div class="loginBox" v-if="hasToken">
          <span class="set" @click="toHome">
            <span class="icon"></span>My account
          </span>
          <span class="homeTxt">Welcome，{{username}}</span>
        </div>
        <span v-if="hasToken" class="setLogout" @click="logoutHandle">Logout</span>
      </div>
    </div>
    <HeaderMenu v-if="!min && !hidden" />
  </div>
</template>

<script>
import HeaderMenu from './menu.vue';
export default {
  name: 'HeaderIndex',
  components: {
    HeaderMenu
  },
  data() {
    return {
      hasToken: false,
      username: '',
      isHomePage: false
    }
  },
  beforeMount() {
    this.hasToken = Boolean(this.$store.state.user.token);
    if (this.hasToken) {
      this.username = this.$store.state.user.userInfo.account;
    }
    this.isHomePage = this.$route.path === '/';
  },
  props: {
    login: Boolean,
    min: Boolean,
    hidden: Boolean
  },
  methods: {
    regHandle() {
      this.$router.push(
        {
          path: '/registration'
        }
      );
    },
    logHandle() {
      this.$router.push(
        {
          path: '/login'
        }
      );
    },
    logoutHandle() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload();
      });
    },
    toHome() {
      if (this.$route.path === '/personal') window.location.reload();
      else this.$router.push(
        {
          path: '/personal'
        }
      );
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: inline-block;
  width: 100%;
  height: 172px;
  &.hidden {
    height: 96px;
  }
  &.min {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 120px;
    z-index: 1200;
    .container-home {
      .right {
        color: rgba(255, 255, 255, 1) !important;
        .set {
          background: rgba(255, 255, 255, 0.16) !important;
        }
        .homeTxt {
          color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
    .container {
      color: white;

      margin: 27.5px auto;
      .left {
        .title {
          color: rgba(255, 255, 255, 1);
          font-family: OPPOSans-Heavy;
          font-size: 48px;
        }
      }
      .right {
        margin-left: auto;
        height: 46px;
        border-radius: 8px;
        color: #00c099;
        .txt {
          display: inline-block;
          width: 142px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.12);
          backdrop-filter: blur(4px);
        }
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    width: 1320px;
    height: auto;
    margin: 16.5px auto;
    color: rgba(0, 192, 153, 1);
  }
  .left {
    width: 247px;
    height: 63px;
    .title {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
      font-size: 48px;
      font-weight: bold;
    }
  }
  .right {
    margin-left: auto;
    display: flex;
    .txt {
      font-weight: 500;
      font-size: 24px;
      cursor: pointer;
      &:nth-of-type(1) {
        margin-right: 24px;
      }
    }
    .homeTxt {
      width: 374px;
      height: 48px;
      line-height: 48px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.16);
      color: #00c099;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      margin-left: 26px;
      backdrop-filter: blur(4px);
    }
    .loginBox {
      display: flex;
      .set {
        width: 166px;
        height: 48px;
        line-height: 48px;
        border-radius: 4px;
        text-align: center;
        background: #00c099;
        color: rgba(255, 255, 255, 1);
        backdrop-filter: blur(4px);
        cursor: pointer;
        .icon {
          vertical-align: middle;
          display: inline-block;
          width: 28px;
          height: 28px;
          background: url('../../assets/img/home/Frame@3x_2.png');
          background-size: 100%;
          margin-right: 12px;
        }
      }
    }
  }
  .setLogout {
    width: 80px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    text-align: center;
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(4px);
    margin-left: 25px;
    cursor: pointer;
  }
}
</style>
