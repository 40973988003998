<template>
  <div class="eventOpening">
    <!-- <TitleIndex title="Opening ceremony" />
    <div class="txtBox">
      <div class="txt"><span class="icon"></span>September 5，2023</div>
      <div class="txt"><span class="icon"></span>Beijing Wuzhou Crown International Hotel</div>
    </div>
    <div class="imgBg">
      <img :src="require('../../../assets/po/图片@3x的副本.png')" alt="">
    </div>
    <TitleIndex class="titleIndex" title="Keynote speak" />
    <div class="cardBox">
      <div class="card" v-for="(item, i) in data1" :key="i">
        <div class="imgBox"><img :src="item.url" alt=""></div>
        <div class="txtContent">
          <div class="title">{{item.name}}&nbsp;&nbsp;&nbsp;&nbsp;<span class="subTitle">{{ item.title }}</span> </div>
          <div class="desc">{{item.desc}}</div>
          <div class="content">
            <div class="txt" v-for="(txt, j) in item.txt" :key="j">{{txt}}</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
    <div class="table-box">
      <table>
        <tr class="title">
          <td colspan="4">September 4, Monday</td>
        </tr>
        <tr class="text">
          <td colspan="4">
            <div>Opening Ceremony & Main Forum</div>
            <div>Banquet Hall A, Second Floor</div>
            <div>Chair: Jianjun Xia (Associate Professor, Tsinghua University, <br>Deputy Director of the Heating Technology Committee of China District Heating Association)</div>
          </td>
        </tr>
        <tr>
          <td rowspan="5">Opening Ceremony</td>
          <td>9:00-9:10</td>
          <td colspan="2">Introduce the Attending Leaders, Guests, and Conference Themes</td>
        </tr>
        <tr>
          <td>9:10-9:15</td>
          <td colspan="2">Welcome Speech by Leader of China District Heating Association</td>
        </tr>
        <tr>
          <td>9:15-9:20</td>
          <td colspan="2">Congratulatory Speech by Leader of Chinese Ministry of Housing and Urban-Rural Development Urban Construction Department</td>
        </tr>
        <tr>
          <td>9:20-9:25</td>
          <td colspan="2">Congratulatory Speech by Mr. Stig Uffe Pedersen the Deputy Director of the Danish Energy Agency</td>
        </tr>
        <tr>
          <td>9:25-9:30</td>
          <td colspan="2">Welcome Speech by Mr. Robin Wiltshire the Chairman of the IEA-DHC</td>
        </tr>
        <tr>
          <td rowspan="5">Main Forum</td>
          <td>9:30-10:10</td>
          <td>Keynote 1 – Carbon Neutral Development and Implementation Pathway for Greater Copenhagen DH System</td>
          <td>HOFOR A/S, Denmark,Thomas Engberg Pedersen</td>
        </tr>
        <tr>
          <td>10:10-10:25</td>
          <td colspan="2">Tea break</td>
        </tr>
        <tr>
          <td>10:25-11:05</td>
          <td>Keynote 2 – The Role and Perspectives of IEA District Heating & Cooling TCP</td>
          <td style="text-align: left; padding-left: 10px;">Chair of IEA-DHC TCP, UK,Dr. Robin Wiltshire</td>
        </tr>
        <tr>
          <td>11:05-11:45</td>
          <td>Keynote 3 – Pathway to Carbon Neutrality in China’s Urban Energy Supply System</td>
          <td style="text-align: left; padding-left: 10px;">Academician of CAE Member, China,Prof. Yi Jiang</td>
        </tr>
        <tr>
          <td>11:45-12:00</td>
          <td colspan="2">Q&A</td>
        </tr>
        <tr>
          <td></td>
          <td>12:00-13:30</td>
          <td colspan="2" style="text-align: center;">Lunch</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import TitleIndex from '../../../components/title/index.vue';

export default {
  name: 'EventOpening',
  components: {
    // TitleIndex
  },
  data() {
    return {
      data1: [
        {
          url: require('../../../assets/po/Frame_371@3x.png'),
          desc: 'Associate Dean for Research',
          name: 'Ming Sun',
          title: 'title',
          txt: [
            'Member of Engineering Physics and Science Research Council (EPSRC), Peer Review College',
            'Member of Editorial Board, Engineering',
            'Member of RICS Education Trust Assessment Panel',
            'Member of Editorial Board, Construction Management'
          ]
        },
        {
          url: require('../../../assets/po/Frame_371@3x.png'),
          desc: 'Deputy Vice-Chancellor - Research and Innovation',
          name: 'Nigel Wright',
          title: 'title',
          txt: [
            'Member of Engineering Physics and Science Research Council (EPSRC), Peer Review College',
            'Member of Editorial Board, Engineering',
            'Member of RICS Education Trust Assessment Panel',
            'Member of Editorial Board, Construction Management'
          ]
        },
        {
          url: require('../../../assets/po/Frame_371@3x.png'),
          desc: 'Researcher',
          name: 'Ahmad Galadanci ',
          title: 'title',
          txt: [
            'Member of Engineering Physics and Science Research Council (EPSRC), Peer Review College',
            'Member of Editorial Board, Engineering',
            'Member of RICS Education Trust Assessment Panel',
            'Member of Editorial Board, Construction Management'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
  .eventOpening {
    width: 1320;
    margin: 48px auto 72px;
    .table-box {
    width: 100%;
    // background-color: red;
    table {
        margin-top: 24px;
        // border-collapse: separate;
        // border-spacing: 8px 8px; // 控制间距
        border-collapse: collapse !important;
        border: 1px solid #000 !important;
        td { 
          border-top: 0 !important; 
          border-right: 1px solid #000 !important; 
          border-bottom: 1px solid #000 !important; 
          border-left: 0 !important;
          min-width: 140px;
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
      }
    table {
      width: 100%;
      tr {
        // padding: 0;
        // margin: 0;
        width: 100%;
        td {
          // color: rgba(153, 153, 153, .4);
          font-family: OPPOSans;
          height: 60px;
          text-align: center;
          &:nth-child(4) {
            text-align: left;
            padding-left: 10px;
          }
        }
        &.title {
          td {
            background-color: #1C7A60;
            text-align: center;
            height: 80px;
            font-size: 20px;
            color: #fff;
            font-family: OPPOSans-Heavy;
          }
        }
        &.text {
          td {
            text-align: center;
            height: 120px;
            background-color: #E1F7F2;
            font-family: OPPOSans;
          }
          div {
            &:first-child {
              font-size: 20px;
              font-family: OPPOSans-Heavy;
            }
            &:nth-child(2) {
              font-size: 18px;
              margin: 5px 0;
            }
            &:nth-child(3) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
    .txtBox {
      width: 1320px;
      height: 136px;
      background: rgba(251, 250, 250, 1);
      padding: 24px;
      .txt {
        color: rgba(51, 51, 51, 1);
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        &:nth-of-type(1) {
          margin-bottom: 16px;
        }
        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: rgba(0, 192, 153, 1);
          vertical-align: middle;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
    .imgBg {
      margin: 24px auto 40px;
      width: 800px;
      height: 480px;
      border-radius: 1px;
      overflow: hidden;
      img {
        border-radius: 1px;
      }
    }
    .titleIndex {
      margin: 32px auto 16px;
    }
    .titleIndex2 {
      color: rgba(0, 192, 153, 1);
      font-weight: bold;
      font-size: 40px;
      line-height: 40px;
      margin: 40px 0;
    }
    .cardBox {
      .card {
        display: flex;
        margin-top: 25px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .imgBox {
          width: 214px;
          height: 264px;
          border-radius: 4px;
          margin-right: 24px;
          overflow: hidden;
        }
        .txtContent {
          flex: 1;
          .subTitle {
            color: #666666;
            font-size: 18px;
          }
          .title{
            color: rgba(0, 192, 153, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 24px;
            line-height: 40px;
          }
          .desc {
            margin-top: 9px;
            color: rgba(51, 51, 51, 1);
            font-family: OPPOSans;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
          }
          .content {
            width: 1042px;
            height: 168px;
            border-radius: 8px;
            background: rgba(251, 250, 250, 1);
            padding: 12px;
            margin-top: 16px;
            .txt {
              position: relative;
              margin-top: 16px;
              padding-left: 40px;
              color: rgba(102, 102, 102, 1);
              font-family: OPPOSans;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              &:nth-of-type(1) {
                margin-top: 0;
              }
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: rgba(0, 192, 153, 1);
              }
            }
          }
        }
      }
    }
    .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
  }
</style>
