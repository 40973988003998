<template>
  <HeaderIndex />
  <BgMenu title="Papers" :uri="require('../../assets/img/paper/paper.png')" />
  <Menu v-if="tabKey !== 'upload'"
    :data="data"
    :changeTab="changeTab"
    :tabKey="tabKey"
    :tabMargin="tabMargin" />
  <div class="main">
    <Instructions v-if="tabKey === 'instructions'" :changeTab="changeTab" />
    <Abstract v-if="tabKey === 'abstract'" />
    <Research v-if="tabKey === 'research'" />
    <!-- <Scientific v-if="tabKey === 'scientific'" /> -->
    <Upload v-if="tabKey === 'upload'" />
    <Publication v-if="tabKey === 'publication'" :publication="publication" />
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import Menu from '../../components/menu/index.vue';
import Instructions from './instructions/index.vue';
import Abstract from './abstract/index.vue';
import Research from './research/index.vue';
// import Scientific from './scientific/index.vue';
import Upload from './upload/index.vue';
import Publication from './publication/index.vue';
import { useRouter } from "vue-router";
import { onMounted, getCurrentInstance } from 'vue';
// import router from '@/router/index.js';
import { getPageInfo } from '../../api/data';

export default {
  name: 'DHCIndex',
  data() {
    return {
      tabKey: 'instructions',
      tabMargin: '164px',
      data: [
        {
          title: 'Submission Instructions',
          key: 'instructions'
        },
        {
          title: 'Abstract',
          key: 'abstract'
        },
        {
          title: 'Research Topics',
          key: 'research'
        },
        {
          title: 'Publication',
          key: 'publication'
        }
        // {
        //   title: 'Scientific Committee',
        //   key: 'scientific'
        // },
        // {
        //   title: 'Upload Paper',
        //   key: 'upload'
        // }
      ],
      publication: []
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
    Instructions,
    Abstract,
    Research,
    // Scientific,
    Publication,
    Upload
  },
  setup() {
    const router = useRouter();
    const ins = getCurrentInstance();
    onMounted(() => {
      const queryObj = router.currentRoute.value.query;
      if (queryObj && queryObj.key) {
        ins.data.tabKey = queryObj.key;
      }
    });
    return {
    }
  },
  async beforeMount() {
    const publication = await getPageInfo('papers-publication');
    this.publication = publication.records.map(_ => ({subtitle: _.subtitle, title: _.title, summary: _.summary, covers: _.covers}))
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
      this.$router.replace('/papers?key=' + text);
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 28px auto 40px;
}
</style>
