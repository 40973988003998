<template>
  <div class="aboutBeijing">
    <div class="text1">As the captain of China, Beijing has been the most popular city of china.So more and more people w ovisit Beijing.I think the best time to visit Beijing is spring ,for the weather of that time is very fine ,neithe ot nor too cold . The warm wind will make you fell happy.Many people visit Beijing for its beautiful sence ultural inheritance .If you want to ha-ve a enjoyable journey ,I suggest you to pay a visit to the Great Wal Summer Palace ,the Olympic Park and many other place. When you feel tired after one days journey ,you car aste the "Zha Jiang Mian",a kind of traditional food in Beijing.There are many other things you can do in 
Beijing.as shopping in the big shopping mall,visit the different kinds park ,visit the"Si He Yuan"and so Beijin is Chinas second largest city, after Shanghai. It is a major transportation hub, with dozens of railways, roads a notorways passing through the city. It is also the focal point of many international flights to China. Beijing is recognised as the  political, educational, and cultural center of the Peoples Republic of China, while Shanghai and Hong Kong predominate in economic fields. The city will host the 20xx Summer Olympics.</div>
    <div class="imageBox">
      <ImageGallery :data="data" />
    </div>
    <div class="text2">As the captain of China, Beijing has been the most popular city of china.So more and more people w ovisit Beijing.I think the best time to visit Beijing is spring ,for the weather of that time is very fine ,neithe ot nor too cold . The warm wind will make you fell happy.Many people visit Beijing for its beautiful sence ultural inheritance .If you want to ha-ve a enjoyable journey ,I suggest you to pay a visit to the Great Wal Summer Palace .</div>
  </div>
</template>

<script>
import ImageGallery from '../../../components/image/gallery.vue';

export default {
  name: 'AboutBeijing',
  data() {
    return {
     data: [
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/5a6c5c9476a6ea10.jpeg',
          title: '1'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/8f664b54edbc0bec.jpg',
          title: '2'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/3272abbc43985ae4.jpg',
          title: '3'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/1882b5a7725466e6.png',
          title: '4'
        },
        {
          url: 'https://s3.bmp.ovh/imgs/2022/08/27/e0550375c1d4077c.jpg',
          title: '5'
        }
      ],
    };
  },
  components: {
    ImageGallery
  }
}
</script>

<style lang="less" scoped>
.aboutBeijing {
  .text1, .text2 {
    color: rgba(51, 51, 51, 1);
    font-family: OPPOSans;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    padding: 0 10px;
  }
  .imageBox {
    width: 1320px;
    height: 1012px;
    margin: 24px auto 40px;
  }
}
</style>
