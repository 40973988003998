<template>
  <HeaderIndex />
  <BgMenu title="Venues" :uri="require('../../assets/img/paper/paper.png')" />
  <div class="venues">
    <!-- <div class="soonBox">
      <img class="soonImg" :src="require('../../assets/po/用于programs@3x.png')" alt="">
      <div class="soonTxt">Coming soon…</div>
    </div> -->
    <div class="header">
      <div class="left" @click="carouselLeft"></div>
      <div class="right" @click="carouselRight"></div>
      <el-carousel
        class="carousel"
        :interval="3000"
        arrow="always"
        height="650px"
        indicator-position="none"
        ref="carousel">
        <el-carousel-item v-for="item in data1" :key="item.url">
          <div class="imageIndexBox">
            <div class="imageIndexBoxSh">
              <img :src="item.url" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <TitleIndex title="V-Continent Beijing Parkview Wuzhou Hotel" style="margin-top: 32px;"></TitleIndex>
    <div class="box1">
      <div class="text">8 North 4th Ring Middle Road, Chaoyang District, Beijing</div>
      <div class="text">V-Continent Beijing Parkview Wuzhou Hotel is the flagship hotel of Beijing North Star V-Continent Hotel Management Company.</div>
      <div class="text">A deluxe hotel conveniently located in core area of Asian Olympic business district of the north Fourth Ring Road Beijing, adjacent to Beijing International Convention Center， China National Convention Center, and within walking distance to Beijing Olympic Park and much celebrated Bird’s Nest Stadium. The hotel is only 25 minutes’ drive from Beijing Capital International Airport, and 60 minutes’ drive from Beijing Daxing International Airport. It is convenient to Tiananmen Square, the Forbidden City, the Summer Palace, the Yuanmingyuan, and Badaling Great Wall. An ideal place for business and leisure travelers.</div>
    </div>
    <!--  -->
    <!-- <div class="card shadow">
      <div class="imgBox">
        <img :src="require('../../assets/po/图片@3x.png')" alt="">
      </div>
      <div class="txtBox">
        <div class="txt">Beijing Wuzhou Crown International Hotel is carefully selected in the Asian Games Village area, adjacent to the Olympic Park and Beijing International Conference, Zhongguancun Science and Technology Park and China National Convention Center are within easy reach. The hotel is ideally located, only 25 minutes' drive from the Capital International Airport, 3 subway stations are within walking distance, and it is very convenient to travel to the downtown, Silk Street, Tiananmen Square, Forbidden City, Summer Palace and other prosperous business districts and scenic spots. the ideal home.
        </div>
      </div>
    </div> -->
    <TitleIndex title="Accommodations" style="margin-top: 32px;"></TitleIndex>
    <div class="card shadow" style="margin-top: 14px;">
      <div class="txtBox">
        <div class="txt">We have negotiated preferential room rates with V-Continent Beijing Parkview Wuzhou Hotel, which is in the same location as the conference venue, making it the most convenient option for you.</div>
        <div class="txt">V-Continent Beijing Parkview Wuzhou Hotel has 459 well-designed guest rooms and suites of different types. Interactive television, high-speed broad band, and wireless Internet access are provided. For business travelers, the hotel’s V-Club which is located on the 15th to 17th floor offers tailor-made services in meeting executive style needs.</div>
        <div class="txt">Chinese attendees may book a room by dialing the reservation number for domestic guests. Tel: 15001364943, Manager Yu <br> 中方参会代表可拨打中宾订房电话预约订房：15001364943于经理</div>
        <div class="txt">Non-Chinese attendees may email your room reservation information (Including your check-in time, room type, departure time) to the address below to book a room. Email: <a href="mailto:marry.yu@v-continent.com">marry.yu@v-continent.com</a></div>
      </div>
    </div>
    <div class="header header2">
      <div class="left" @click="carouselLeft2"></div>
      <div class="right" @click="carouselRight2"></div>
      <el-carousel
        class="carousel"
        :interval="3000"
        arrow="always"
        height="650px"
        indicator-position="none"
        ref="carousel2">
        <el-carousel-item v-for="item in data2" :key="item.url">
          <div class="imageIndexBox">
            <div class="imageIndexBoxSh">
              <img :src="item.url" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <TitleIndex title="Traffic guide" style="margin-top: 32px;"></TitleIndex>
    <div class="card" style="margin-top: 14px;">
      <div class="txtBox">
        <div class="text">It is recommended that you take a taxi from the airport to the conference hotel.</div>
        <div class="text">Beijing Capital International Airport -- Conference Hotel: 27.7 km, approx. 33 minutes’ drive</div>
        <div class="text">Beijing Daxing International Airport -- Conference Hotel: 66.7 km, approx. 1 hour 4 minutes’ drive</div>
      </div>
    </div>
    <div class="card" style="margin-top: 14px;">
      <img :src="require('../../assets/img/venue/地点示意图.png')" alt="" style="width: 960px; margin: 0 auto;display: block;">
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import TitleIndex from '../../components/title/index.vue';

export default {
  name: 'VenuesIndex',
  data() {
    return {
      data1: [
        {
          url: require('../../assets/img/venue/111.png'),
          title: '1'
        },
        {
          url: require('../../assets/img/venue/222.png'),
          title: '2'
        },
        {
          url: require('../../assets/img/venue/333.png'),
          title: '3'
        },
      ],
      data2: [
        {
          url: require('../../assets/img/venue/444.png'),
          title: '1'
        },
        {
          url: require('../../assets/img/venue/555.png'),
          title: '1'
        },
        {
          url: require('../../assets/img/venue/777.png'),
          title: '1'
        },
        {
          url: require('../../assets/img/venue/888.png'),
          title: '1'
        },
        {
          url: require('../../assets/img/venue/999.png'),
          title: '1'
        },
      ]
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    TitleIndex
  },
  // beforeMount() {
  //   axios.all([
  //     getPageInfo('committee-welcome'),
  //     getPageInfo('committee-scientific'),
  //     getPageInfo('committee-organizing')
  //   ]).then(axios.spread((welcome, scientific, organizing) => {
  //     this.welcome = {
  //       images: welcome?.records[0]?.images || '',
  //       content: this.unescapeHtmlEntities(welcome?.records[0]?.content || ''),
  //       title: welcome?.records[0]?.title
  //     };
  //     this.scientific = scientific;
  //     this.organizing = organizing.records.map(_ => ({author: _.author, title: _.title, subtitle: _.subtitle}));
  //   }));
  // },
  methods:{
    carouselLeft() {
      this.$refs.carousel.prev();
    },
    carouselRight() {
      this.$refs.carousel.next();
    },
    carouselLeft2() {
      this.$refs.carousel2.prev();
    },
    carouselRight2() {
      this.$refs.carousel2.next();
    },
  }
}
</script>

<style lang="less" scoped>
.venues {
  width: 1280px;
  margin: 40px auto 112px;
  line-height: 0;
  .header {
    position: relative;
    width: 1000px;
    height: 670px;
    margin: 0 auto;
    text-align: center;
    .left, .right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .left {
      left: -100px;
      background: url('../../assets/img/infor/翻页_Property_1_向后@3x.png');
      background-size: cover;
    }
    .right {
      right: -100px;
      background: url('../../assets/img/infor/翻页_Property_1_向前@3x.png');
      background-size: cover;
    }
  }
  .header2 {
    margin-top: 40px;
    .center {
      width: 802px;
      height: 581px;
      background: url('../../assets/img/venue/333.png') no-repeat 100%;
      background-size: contain;
      background-position-x: left;
    }
  }
  .box1 {
    .text {
      margin-top: 10px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
    }
  }
  // 
  // 
  .soonBox {
      display: flex;
      width: 1280px;
      height: 560px;
      margin-top: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .soonImg {
        display: inline-block;
        width: 308px;
        height: 320px;
      }
      .soonTxt {
        text-align: center;
        color: rgba(153, 153, 153, .4);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 60px;
        line-height: 60px;
        margin-top: 21px;
      }
    }
  .card {
    &.shadow .imgBox {
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 24px 8px rgba(0, 129, 137, 0.12);
    }
    &:nth-last-of-type(1) .imgBox {
      padding: 0;
      img {
        border-radius: 0;
      }
    }
    .imgBox {
      width: 802px;
      height: 581px;
      img {
        border-radius: 8px;
      }
    }
    .txtBox {
      padding: 0;
      .text, .txt {
        padding: 5px 0;
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
        &:nth-of-type(1) {
          padding: 0 0 5px;
        }
        a {
          text-decoration: none;
          color: #007b65;
        }
      }
    }
  }
  .carousel {
      position: relative;
      width: 1000px;
      height: 670px;
      margin: 0 auto;
      overflow-y: hidden;
      /deep/ .el-carousel__arrow--left, /deep/ .el-carousel__arrow--right {
        display: none;
      }
      /deep/ .el-carousel__item.is-active {
        overflow: visible !important;
      }
      .imageIndexBox {
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 8px;
        .imageIndexBoxSh {
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
          padding: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
}
</style>
