<template>
  <HeaderIndex />
  <BgMenu title="Information<br/>Center" :uri="require('../../assets/img/infor/infor.png')" />
  <Menu :data="data" :changeTab="changeTab" :tabKey="tabKey" :tabMargin="tabMargin" />
  <div class="main">
    <Download v-if="tabKey === 'download'" :download="download" />
    <Gallery v-if="tabKey === 'gallery'" />
    <NewsList v-if="tabKey === 'newslist'" :changeTab="changeTab" />
    <News v-if="tabKey === 'news'" />
    <Contact v-if="tabKey === 'contact'" />
    <History v-if="tabKey === 'history'" />
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import Menu from '../../components/menu/index.vue';
import Download from './download/index.vue';
import Gallery from './gallery/index.vue';
import Contact from './contact/index.vue';
import History from './history/index.vue';
import NewsList from './news_list/index.vue';
import News from './news/index.vue';
//
import axios from 'axios';
import { getPageInfo } from '../../api/data';

export default {
  name: 'InforIndex',
  data() {
    return {
      tabKey: 'newslist',
      tabMargin: '127.3px',
      data: [
        {
          title: 'News',
          key: 'newslist'
        },
        {
          title: 'Download Center',
          key: 'download'
        },
        {
          title: 'Conference History',
          key: 'history'
        },
        {
          title: 'Gallery',
          key: 'gallery'
        },
        {
          title: 'Contact',
          key: 'contact'
        }
      ],
      download: [],
      gallery: []
    };
  },
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    Menu,
    Download,
    Gallery,
    NewsList,
    Contact,
    History,
    News
  },
  beforeMount() {
    const text = this.$route.query.text;
    if (text) {
      this.tabKey = text;
    }
    axios.all([
      getPageInfo('information-download'),
      getPageInfo('information-gallery')
    ]).then(axios.spread((download, gallery) => {
      this.gallery = gallery;
      this.download = download.records.map(_ => ({title: _.title, images: _.images, files: _.files}));
    }));
  },
  methods:{
    changeTab(text){
      this.tabKey = text;
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 1320px;
  margin: 32px auto;
}
</style>
