<template>
  <div class="registerPrice">
    <div class="price">
      <div class="left">
        <div class="space"></div>
        <div class="up">On-site</div>
        <div class="down">Online</div>
      </div>
      <div class="right">
        <div class="title1">Category</div>
        <div class="title2">Early bird fee</div>
        <div class="title3">Regular fee</div>
        <div class="card" v-for="(item, i) in data" :key="i">
          <div class="text1">{{item[0]}}</div>
          <div class="text2">{{item[1]}}</div>
          <div class="text3">{{item[2]}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="other">
      <div class="title">Other Items</div>
      <div class="box">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div> -->
    <!-- <div class="titleBottom">Once you have read the full instructions, please <router-link class="link" to="/registration/order">click here</router-link> to register.</div> -->
  </div>
</template>

<script>
export default {
  name: 'RegisterPrice',
  data() {
    return {
      data: [
        [
          'General participant',
          'CNY 3400',
          'CNY 4000'
        ],
        [
          'Presenter',
          'CNY 3060',
          'CNY 3600'
        ],
        [
          'Student',
          'CNY 2040',
          'CNY 2400'
        ],
        [
          'General participant',
          'CNY 3040',
          'CNY 3200'
        ],
        [
          'Presenter',
          'CNY 2750',
          'CNY 2900'
        ],
        [
          'Student',
          'CNY 1800',
          'CNY 1900'
        ],
      ]
    }
  },
  props: {}
}
</script>

<style lang="less" scoped>
.registerPrice {
  width: 1280px;
  margin: 40px auto 0;
  line-height: 0;
  .price, .other, .titleBottom {
    display: flex;
    width: 1200px;
    margin: 0 auto;
  }
  .other {
    flex-direction: column;
  }
  .left {
    width: 148px;
    .space {
      width: 100%;
      display: inline-block;
      height: 72px;
      background: rgba(0, 192, 153, 1);
    }
    .up {
      width: 100%;
      display: inline-block;
      height: 216px;
      line-height: 216px;
      text-align: center;
      background: rgba(0, 192, 153, 0.12);
      font-weight: bold;
      font-size: 20px;
      color: #666666;
    }
    .down {
      width: 100%;
      display: inline-block;
      height: 216px;
      line-height: 216px;
      text-align: center;
      background: rgba(0, 122, 255, 0.12);
      font-weight: bold;
      font-size: 20px;
      color: #666666;
    }
  }
  .right {
    flex: 1;
    .title1, .title2, .title3 {
      display: inline-block;
      height: 72px;
      background: rgba(0, 192, 153, 1);
      vertical-align: top;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
      font-size: 20px;
    }
    .title1, .title2, .title3 {
      line-height: 72px;
    }
    // .title2 {
    //   line-height: 32px;
    // }
    .card {
      width: 1052px;
      height: 72px;
      &:nth-of-type(2n + 1) {
        background: rgba(251, 250, 250, 1);
      }
      .text1, .text2, .text3 {
        display: inline-block;
        height: 100%;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        line-height: 72px;
      }
      .text1 {
        color: rgba(0, 192, 153, 1);
      }
      .text2 {
        color: orange;
      }
      .text3 {
        color: rgba(0, 122, 255, 1);
      }
    }
  }
  .title1, .text1 {
    width: 332px;
  }
  .title2, .text2 {
    width: 360px;
  }
  .title3, .text3 {
    width: 360px;
  }
  .other {
    margin-top: 24px;
    .title {
      width: 1200px;
      height: 72px;
      background: rgba(0, 192, 153, 1);
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 72px;
      text-align: center;
    }
    .box {
      display: flex;
      width: 1200px;
      height: 72px;
      .left {
        width: 148px;
        height: 72px;
        background: rgba(0, 122, 255, 0.12);
      }
      .right {
        flex: 1;
        height: 72px;
        background: rgba(251, 250, 250, 1);
      }
    }
  }
  .titleBottom {
    color: rgba(102, 102, 102, 1);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
    .link {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
      font-size: 24px;
      text-decoration-line: none;
      margin: 0 5px;
    }
  }
}
</style>
