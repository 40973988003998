<template>
  <div class="container" id="receipt_container">
    <div class="header"> 
      <div class="left">
        <div class="title">DHC2023</div>
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="text">The 18th International Symposium<br/>on District Heating & Cooling</div>
      </div>
      <div class="right">
        <div class="right-l"></div>
        <div class="right-r">
          <div class="title">INVOICE</div>
          <div class="box" style="margin-top: 16px;">
            <!-- <div class="l">Invoice#</div>
            <div class="r">85147</div> -->
          </div>
          <div class="box" style="margin-top: 8px;">
            <div class="l">Date</div>
            <div class="r">{{ formatdate(info.createdAt) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <!-- <div class="desc">INOICE TO:</div>
      <div class="text" style="margin-top: 8px;">DeXi Sun</div>
      <div class="text">30 Shuangqing Road, Haidian District,</div>
      <div class="text">Beijing, China</div> -->
    </div>
    <div class="order">
      <div class="desc">
        <span style="margin-right: 8px;">Number:</span>
        <span>{{ info.payOrderId }}</span>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <td>Item Description</td>
              <td>Type</td>
              <td>Price</td>
              <td>Qty.</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="max-width: 100px;">{{ info.subject }}</td>
              <td>{{ info.mchType == 1 ? 'On-site' : ''}}</td>
              <td>{{ info.amount }}{{ info.currency }}</td>
              <td>x1</td>
              <td>{{ info.amount }}{{ info.currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="total">
        <span class="title">Total:</span>
        <span class="count">{{ info.amount }}{{ info.currency }}</span>
      </div>
      <div class="info">
        <div class="title">Payment Info:</div>
        <div class="hr"></div>
        <div class="box">
          <span>Account：</span>
          <span>{{ account }}</span>
          <span>Payment ：</span>
          <span>{{ info.payOrderId }}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="content">
        <div class="left">
          <div class="item"><span class="icon"></span><span class="txt">China District Heating Association </span></div>
          <div class="item"><span class="icon"></span><span class="txt">No.2 XibaHenan Road,<br/>Chaoyang District，<br/>Bejing</span></div>
        </div>
        <div class="right">
          <div class="item"><span class="icon"></span><span class="txt">DHC2023.com.cn</span></div>
          <div class="item"><span class="icon"></span><span class="txt">+86-13261857219</span></div>
          <div class="item"><span class="icon"></span><span class="txt">contact@dhc2023.com.cn</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { getOrderInfo } from '../../api/payment';
import {htmlDecode} from '@/utils/index'

export default {
  name: 'ReceiptIndex',
  data() {
    return {
      info: {
        subject: '',
        mchType: 1,
        amount: '',
        currency: '',
        payOrderId: '',
        mchName: ''
      },
      loaded: false,
      mounted: false,
      account: ''
    }
  },
  beforeMount() {
    if (this.$store.state.user && this.$store.state.user.userInfo && this.$store.state.user.userInfo.account) {
      this.account = this.$store.state.user.userInfo.account
    }
    const hasToken = Boolean(this.$store.state.user.token);
    if (hasToken) {
      this.username = this.$store.state.user.userInfo.account;
    }
    else this.$router.push(
      {
        path: '/login'
      }
    );
    const queryObj = this.$router.currentRoute.value.query;
    if (!queryObj || !queryObj.id) return this.$router.push(
      {
        path: '/personal'
      }
    );
    getOrderInfo(queryObj.id).then(res => {
      let _ =res.data.data
      this.info = {
        subject: htmlDecode(_.subject),
        mchType: _.mchType,
        amount: (_.amount/100).toFixed(2),
        currency: _.currency,
        payOrderId: _.payOrderId,
        mchName: _.mchName,
        createdAt: _.createdAt
      }
      this.loaded = true
    })
  },
  watch: {
    loaded(value){
      if (!value || !this.mounted) return
      this.$nextTick(() => {
        this.screen()
      })
    },
    mounted(value){
      if (!value || !this.loaded) return
      this.$nextTick(() => {
        this.screen()
      })
    }
  },
  mounted() {
    this.mounted = true
  },
  methods: {
    formatdate(str) {
      const date = new Date(str)
      return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    },
    screen() {
      //对指定容器进行截屏
      html2canvas(document.getElementById("receipt_container"), {
        backgroundColor: "#ffffff",
        allowTaint: true,  //开启跨域
        useCORS: true,
        scrollY: 0,
        scrollX: 0,
        foreignObjectRendering: true,
      }).then((canvas) => {
        // 返回一个 canvas 对象，在dom中渲染 canvas 对象
        //document.body.appendChild(canvas);
        // 转化成 dataurL
        let canvasImg = canvas.toDataURL("image/png");
        //调用下载方法
        this.downLoadFile(this.info.payOrderId + '.png', canvasImg);
      });
    },
    downLoadFile(fileName, canvasImg) {
      //创建一个a标签
      var a = document.createElement("a");
      //指定下载文件名称
      a.href = canvasImg;
      a.download = fileName;
      //模拟点击
      a.click();
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 595px;
  .header {
    display: flex;
    width: 595px;
    height: 172px;
    background: rgba(212, 249, 241, 1);
    .left {
      width: 247px;
      height: 172px;
      padding: 32px 0 0 40px;
      .title {
        color: rgba(0, 192, 153, 1);
        font-family: OPPOSans-Heavy;
        font-size: 40px;
      }
      .line1 {
        width: 206px;
        height: 2.4px;
        background: rgba(0, 192, 153, 0.8);
      }
      .line2 {
        margin-top: 4px;
        width: 206px;
        height: 3.2px;
        background: rgba(0, 192, 153, 1);
      }
      .text {
        color: rgba(0, 192, 153, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        margin-top: 3px;
      }
    }
    .right {
      display: flex;
      width: 348px;
      height: 149px;
      .right-l {
        width: 118px;
        background-image: linear-gradient(-125deg, rgba(0, 192, 153, 1) 50%, rgba(255, 255, 255, 0) 50%);
      }
      .right-r {
        width: 230px;
        background: rgba(0, 192, 153, 1);
        .title {
          color: rgba(255, 255, 255, 1);
          font-family: OPPOSans;
          font-weight: bold;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: 10%;
          margin-top: 26px;
          text-align: center;
        }
        .box {
          width: 188px;
          display: flex;
          .l, .r {
            color: rgba(255, 255, 255, 1);
            font-family: OPPOSans;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
          }
          .l {
            text-align: left;
          }
          .r {
            text-align: right;
            margin-left: auto;
          }
        }
      }
    }
  }
  .center {
    width: 515px;
    margin: 32px auto;
    .desc {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
    .text {
      color: rgba(0, 78, 62, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .order {
    width: 515px;
    margin: 0 auto;
    .desc {
      span {
        color: rgba(0, 78, 62, 1);
        font-family: OPPOSans;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .table {
      margin-top: 8px;
      border-radius: 2px;
      overflow: hidden;
      table {
        width: 100%;
        border-collapse: collapse !important;
        border: 0px solid transparent !important;
        td { 
          border-top: 0 !important; 
          border-right: 0px solid white !important; 
          border-bottom: 0px solid white !important; 
          border-left: 0 !important; 
          color: rgba(0, 78, 62, 1);
          font-family: OPPOSans;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          &:nth-of-type(1) {
            padding-left: 24px;
            text-align: left;
          }
          &:nth-of-type(5) {
            padding-right: 24px;
            text-align: right;
          }
        }
        tbody tr:last-child td{
          border-bottom: 0 !important; 
        }
        tr td:last-child{
          border-right: 0 !important; 
        }
        tr {
          height: 44px;
          background: rgba(0, 192, 153, 0.04);
        }
        thead tr {
          border-bottom: 1px solid rgba(0, 192, 153, 1);
        }
      }
    }
    .total {
      display: flex;
      margin-top: 12px;
      width: 515px;
      height: 48px;
      border-radius: 2px;
      background: rgba(0, 192, 153, 1);
      padding: 18px 24px 0;
      .title, .count {
        color: rgba(255, 255, 255, 1);
        font-family: OPPOSans;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
      }
      .count {
        margin-left: auto;
      }
    }
  }
  .info {
    margin-top: 40px;
    .title {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
    }
    .hr {
      margin-top: 7.2px;
      width: 100%;
      height: 1px;
      transform: rotate(-0.376946deg);
      border: 1px solid rgba(0, 0, 0, 0.24);
    }
    .box {
      margin-top: 15.5px;
      // margin-bottom: 131.3px;
      span {
        display: inline-block;
        color: rgba(0, 78, 62, 1);
        font-family: OPPOSans;
        font-size: 14px;
        &:nth-of-type(2n+1) {
          font-weight: 500;
          line-height: 20px;
        }
        &:nth-of-type(2n+2) {
          font-weight: bold;
          line-height: 16px;
          text-align: right;
        }
        &:nth-of-type(1) {
          width: 73px;
          margin-right: 16px;
        }
        &:nth-of-type(2) {
          width: 119px;
          margin-right: 40px;
        }
        &:nth-of-type(3) {
          width: 81px;
          margin-right: 8px;
        }
        &:nth-of-type(4) {
          width: 178px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 104px;
    background: rgba(0, 192, 153, 1);
    padding: 12px 0;
    margin-top: 131.3px;
    .content {
      display: flex;
      flex-wrap: wrap;
      width: 443px;
      height: 80px;
      margin: 0 auto;
      .left, .right {
        display: flex;
        flex-direction: column;
      }
      .left {
        width: 234px;
        margin-right: 22px;
        .item {
          &:nth-of-type(1) .icon {
            background: url('../../assets/img/receipt/icon_buding@3x.png') no-repeat;
            background-size: 100%;
          }
          &:nth-of-type(2) .icon {
            background: url('../../assets/img/receipt/icon_loctione@3x.png') no-repeat;
            background-size: 100%;
          }
        }
      }
      .right {
        flex: 1;
        .item {
          &:nth-of-type(1) .icon {
            background: url('../../assets/img/receipt/icon_global@3x.png') no-repeat;
            background-size: 100%;
          }
          &:nth-of-type(2) .icon {
            background: url('../../assets/img/receipt/icon_phone2@3x.png') no-repeat;
            background-size: 100%;
          }
          &:nth-of-type(3) .icon {
            background: url('../../assets/img/receipt/icon_mail2@3x.png') no-repeat;
            background-size: 100%;
          }
        }
      }
      .item {
        display: flex;
        margin-bottom: 6px;
        .icon, .txt {
          display: inline-block;
          vertical-align: top;
        }
        .icon {
          width: 18px;
          height: 18px;
          margin: 1px 9px 0 1px;
        }
        .txt {
          flex: 1;
          color: rgba(255, 255, 255, 1);
          font-family: OPPOSans;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          margin-top: 1px;
        }
      }
    }
  }
}
</style>
