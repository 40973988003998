<template>
  <div class="menuIndex">
    <div
      :class="tabKey === item.key ? 'active title' : 'title'"
      v-for="(item, i) in data"
      :key="i"
      :style="{'margin-left': i === 0 ? 0 : tabMargin}"
      @click="changeTabKey(item.key)" >{{item.title}}</div>
  </div>
</template>

<script>
export default {
  name: 'MenuIndex',
  props: {
    data: Array,
    tabKey: String,
    tabMargin: String,
    changeTab: Function
  },
  methods: {
    changeTabKey(key){
      this.changeTab(key);
    }
  }
}
</script>

<style lang="less" scoped>
.menuIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1280px;
  height: 64px;
  margin: 40px auto 0;
  background: rgba(0, 192, 153, 0.08);
  .title {
    color: rgba(51, 51, 51, 1);
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 63px;
    cursor: pointer;
    &:nth-of-type(1) {
      margin-left: 0;
    }
    &.active {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
    }
  }
}
</style>
