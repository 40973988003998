<template>
  <HeaderIndex />
  <BgMenu title="Registration" :uri="require('../../assets/img/register/register.png')" />
  <div class="registerOrder">
    <div class="header">
      <div class="text">Please fill out this form in English. Any question regarding registration, please contact conference secretary via <span class="link">registration@ieee-icct.org</span>.</div>
    </div>
    <div class="titleBox">
      <div class="text">If you attend the conference now, you can enjoy early bird rates.</div>
    </div>
    <div class="radioContent">
      <div class="title">1.In what capacity did you attend the meeting</div>
      <el-radio-group class="radioBox" v-model="radiokey1">
        <el-radio :label="item.key"
          v-for="item in radiodata1" :key="item.key">{{item.title}}</el-radio>
      </el-radio-group>
    </div>
    <div class="radioContent">
      <div class="title">2.select a mode to participate in the meeting</div>
      <el-radio-group class="radioBox" v-model="radiokey2">
        <el-radio :label="item.key"
          v-for="item in radiodata2" :key="item.key">{{item.title}}</el-radio>
      </el-radio-group>
    </div>
    <div class="radioContent">
      <div class="title">3.</div>
      <el-radio-group class="radioBox" v-model="radiokey3">
        <el-radio :label="item.key"
          v-for="item in radiodata3" :key="item.key">{{item.title}}</el-radio>
      </el-radio-group>
    </div>
    <div class="textBox">
      <div class="textBoxFlex">
        <span class="text">Depending on your choice, the fee you need to pay is</span>
        <span class="price">{{ price[`${radiokey1}-${radiokey2}-${radiokey3}`].txt }}</span>
      </div>
    </div>
    <TitleIndex title="Notice" />
    <div class="listBox">
      <div class="list">Accommodation and meals included in your payment</div>
      <div class="list">If you want a refund, please go to the <router-link class="link" :to="hasToken ? '/personal' : '/login'">personal center</router-link></div>
      <div class="list">If you have any further questions please contact our working email </div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancel">Cancel</div>
      <div class="submission" @click="submission">Submission</div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import TitleIndex from '../../components/title/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import { payOrder } from '@/api/payment';
import priceData from './price.js';

export default {
  name: 'RegisterIndex',
  data() {
    return {
      radiokey1: 1,
      radiodata1: [
        {
          title: 'General participant',
          key: 1
        },
        {
          title: 'Presenter',
          key: 2
        },
        {
          title: 'Student',
          key: 3
        },
      ],
      radiokey2: 1,
      radiodata2: [
        {
          title: 'On-site',
          key: 1
        },
        {
          title: 'Online',
          key: 2
        }
      ],
      radiokey3: 1,
      radiodata3: [
        {
          title: 'Early bird fee',
          key: 1
        },
        {
          title: 'Regular fee',
          key: 2
        }
      ],
      hasToken: false,
      price: priceData
    }
  },
  props: {},
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    TitleIndex
  },
  beforeMount() {
    this.hasToken = Boolean(this.$store.state.user.token);
  },
  methods: {
    submission() {

      // const that = this
      payOrder({
        // wayCode: 'ICBC_FOREIGN', // 工商银行支付
        wayCode: 'PP_PC', // Paypal支付
        amount: 0.01, // 支付金额
        appId: '6377d154edaa11a645657787', // appId
        mchOrderNo: this.randomOrderNo(), // 订单编号
        payDataType: 'payurl', // 支付参数（二维码，条码）
        authCode: '', // 条形码
        divisionMode: 0, // 订单分账模式,
        orderTitle: 'The 18th International Symposium on District Heating & Cooling'
      }).then(res => {
        console.log(res);
        console.log(res.data.data.payData);

        if (res.data.data.payData.indexOf('<form') >= 0) {
          const pathInfo = this.$router.resolve({
            path: '/registration/postpage',
            query: {
                formData: res.data.data.payData
            }
          })
          window.open(pathInfo.href, '_blank')
        } else {
            window.open(res.data.data.payData)
        }

          
        // that.$refs.payTestModal.showModal(this.currentWayCode, res) // 打开弹窗
        // that.randomOrderNo() // 刷新订单号
      }).catch(() => {
        // that.$refs.payTestBarCode.processCatch()
        // that.randomOrderNo() // 刷新订单号
      })
      // this.$router.push(
      //   {
      //     path: '/registration/user'
      //   }
      // );
    },
    cancel() {
      this.$router.push(
        {
          path: '/registration'
        }
      );
    },
    // 刷新订单号
    randomOrderNo () {
       return 'M' + new Date().getTime() + Math.floor(Math.random() * (9999 - 1000) + 1000)
    }
  }
}
</script>

<style scoped lang="less">
.registerOrder {
  width: 1280px;
  margin: 40px auto;
  .header {
    .text {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      .link {
        color: rgba(0, 192, 153, 1);
        // text-decoration-line: underline;
      }
    }
  }
  .titleBox {
    margin-top: 32px;
    .text {
      color: rgba(235, 87, 87, 1);
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
    }
  }
  .radioContent {
    margin-top: 32px;
    .title {
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
      font-size: 24px;
    }
    .radioBox {
      margin-top: 20px;
      /deep/ .el-radio {
        margin-right: 58px;
        &.is-checked {
          .el-radio__label {
            color: rgba(0, 192, 153, 1);
          }
          .el-radio__inner {
            position: relative;
            background: transparent;
            overflow: hidden;
            &::after {
              position: absolute;
              top: 2px;
              left: 6px;
              content: '';
              width: 5px;
              height: 9px;
              border-color: rgba(0, 192, 153, 1);
              border-style: solid;
              border-width: 0 1.5px 1.5px 0;
              transform: rotate(40deg);
              border-radius: 0;
            }
          }
        }
        .el-radio__label {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
        }
        .el-radio__inner {
          width: 20px;
          height: 20px;
          border: 1px solid rgba(0, 192, 153, 1);
        }
      }
    }
  }
  .textBox {
    margin: 40px 0;
    width: 1280px;
    height: 96px;
    background: rgba(251, 250, 250, 1);
    padding: 24px;
    .textBoxFlex {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      .text {
        color: rgba(153, 153, 153, 1);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
      .price {
        margin-left: auto;
        color: rgba(235, 87, 87, 1);
        font-family: OPPOSans-Heavy;
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
  .listBox {
    margin-top: 30px;
    .list {
      position: relative;
      color: rgba(102, 102, 102, 1);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding-left: 32px;
      margin-top: 40px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        width: 12px;
        height: 12px;
        background: rgba(0, 192, 153, 1);
        border-radius: 50%;
      }
      .link {
        color: rgba(0, 192, 153, 1);
        font-weight: bold;
        text-decoration-line: none;
      }
    }
  }
  .btnBox {
    margin: 64px 0;
    text-align: center;
    .cancel, .submission {
      display: inline-block;
      width: 172px;
      height: 48px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 20px;
      line-height: 48px;
      text-align: center;
    }
    .cancel {
      border: 2px solid rgba(0, 192, 153, 1);
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 192, 153, 1);
      margin-right: 40px;
    }
    .submission {
      background: rgba(0, 192, 153, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
