import { useAxios } from "@/hooks/useAxios";

/**
 * 
 * @param {*} type 
 * @returns 
 */
export function getPageInfo (type, size, ascs, descs) {
  let uri = `/api/framework-article/article/list?category=${type}&isHidden=0`;
  if (size) {
    uri += `&size=${size}`
  }
  if(ascs) {
    uri += `&ascs=${ascs}`
  }
  if(descs) {
    uri += `&descs=${descs}`
  }
  
  return useAxios.get(uri);
}

/**
 * 
 * @param {*} type 
 * @returns 
 */
 export function getDetail(id) {
  let uri = `/api/framework-article/article/detail?id=${id}`;
  return useAxios.get(uri);
}


export function postRegisterUser (email, pwd) {
  return useAxios.post(`/api/framework-user/business/register`, {
    account: email,
    email,
    password: pwd,
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'User-Type': 1
    }
  });
}

export function postLoginUser (email, pwd) {
  return useAxios.post(`/api/framework-user/business/register`, {
    account: email,
    email,
    password: pwd,
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'User-Type': 1
    }
  });
}

/**
 * 用户订阅订单时的用户信息
 * @param {Object} file - 文件二进制
 * @description
 *  swagger：http://49.232.138.149:8800/doc.html#/default/%E5%AF%B9%E8%B1%A1%E5%AD%98%E5%82%A8%E7%AB%AF%E7%82%B9/putFileUsingPOST_2
 */
export const updateOrderUserFileUrl = `/api/framework-resource/oss/endpoint/put-file`;
// export const updateOrderUserFileUrl = `/api/framework-resource/oss/endpoint/put-file-attach-by-name`;
export function updateOrderUserFile (file) {
  return useAxios.post(updateOrderUserFileUrl, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'User-Type': 1
    }
  });
}
