<template>
  <div class="homePic">
    <div class="ro1">
      <div class="ro2">
        <div class="txt">DHC2023</div>
      </div>
    </div>
    <div class="box">
      <div class="up">
        <div class="card" style="left: 75px;">
          <div class="date">November 31<br />2022</div>
          <div class="content">
            <div class="line"></div>
            <div class="txt">Call for Abstracts</div>
          </div>
        </div>
        <div class="card" style="left: 363px;">
          <div class="date">April 5<br />2023</div>
          <div class="content">
            <div class="line"></div>
            <div class="txt" style="line-height: 25px;margin-top: 15px;">Deadline for Notification <br> on Acceptance</div>
          </div>
        </div>
        <div class="card" style="left: 657px;">
          <div class="date">August<br />2023</div>
          <div class="content">
            <div class="line"></div>
            <div class="txt">Deadline for Registration</div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="line"></div>
        <div class="point" style="left: 66px;"></div>
        <div class="point" style="left: 212px;"></div>
        <div class="point" style="left: 356px;"></div>
        <div class="point" style="left: 504px;"></div>
        <div class="point" style="left: 650px;"></div>
        <div class="point" style="left: 794px;"></div>
      </div>
      <div class="down">
        <div class="card" style="left: 220px;">
          <div class="content">
            <div class="line"></div>
            <div class="txt">Deadline for Abstracts</div>
          </div>
          <div class="date">March 28<br />2023</div>
        </div>
        <div class="card" style="left: 512px;">
          <div class="content">
            <div class="line"></div>
            <div class="txt">Deadline for Papers</div>
          </div>
          <div class="date">June 12<br />2023</div>
        </div>
        <div class="card" style="left: 802px;">
          <div class="content">
            <div class="line"></div>
            <div class="txt">Symposium Date</div>
          </div>
          <div class="date">September 3-6<br />2023</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePic',
  props: {}
}
</script>

<style lang="less" scoped>
.homePic {
  display: flex;
  margin-top: 40px;
  padding: 40px 0 40px 40px;
  width: 1280px;
  height: 400px;
  line-height: 400px;
  border-radius: 4px;
  vertical-align: middle;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 192, 153, 0.08) 0%, rgba(0, 192, 153, 0.12) 100%);
  .ro1 {
    display: inline-block;
    width: 240px;
    height: 240px;
    line-height: 0;
    text-align: center;
    background: linear-gradient(315deg, rgba(179, 255, 171, 0.2) 0%, rgba(18, 255, 247, 0.2) 100%);
    border-radius: 50%;
    .ro2 {
      margin-top: 35px;
      display: inline-block;
      width: 172px;
      height: 172px;
      line-height: 172px;
      text-align: center;
      border-radius: 50%;
      background: linear-gradient(315deg, rgba(62, 255, 197, 1) 0%, rgba(0, 192, 153, 1) 85.42%);
      .txt {
        display: inline-block;
        color: rgba(255, 255, 255, 1);
        font-family: HarmonyOS Sans;
        font-weight: bold;
        font-size: 32px;
      }
    }
  }
  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 760px;
    .up {
      .date {
        top: 0;
      }
      .down {
        bottom: 0;
      }
      .card {
        padding-top: 59px;
        .line {
          background: linear-gradient(180deg, rgba(0, 192, 153, 1) 0%, rgba(0, 192, 153, 0) 100%);
        }
      }
    }
    .down {
      .card {
        padding-bottom: 59px;
        .line {
          background: linear-gradient(180deg, rgba(0, 192, 153, 0) 0%, rgba(0, 192, 153, 1) 100%);
        }
      }
    }
    .up, .down {
      height: 142px;
      position: relative;
      .card {
        position: absolute;
        top: 0;
        display: inline-block;
        vertical-align: top;
        height: 142px;
        .date {
          position: absolute;
          left: 0;
          height: 59px;
          min-width: 130px;
          border-radius: 4px;
          border: 2px solid rgba(0, 192, 153, 1);
          box-sizing: border-box;
          background: rgba(255, 255, 255, 1);
          color: rgba(0, 192, 153, 1);
          font-family: OPPOSans-Heavy;
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          padding: 4px 8px;
        }
        .content {
          flex: 1;
          display: flex;
          .line {
            width: 2px;
            height: 87px;
          }
          .txt {
            color: rgba(0, 192, 153, 1);
            font-weight: bold;
            font-size: 18px;
            line-height: 87px;
            margin-left: 18px
          }
        }
      }
    }
    .center {
      position: relative;
      display: flex;
      align-items: center;
      width: 867px;
      height: 32px;
      border-top: 4px solid white;
      border-bottom: 4px solid white;
      background: rgba(232, 232, 232, 1);
      .line {
        width: 100%;
        height: 2px;
        border: 2px dashed rgba(255, 255, 255, 1);
      }
      .point {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 1);
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12.5px;
          height: 12.5px;
          background: rgba(0, 192, 153, 1);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
