import request from '@/utils/axios'
import website from '@/config/website'
const tenantId = '000000'
export const loginByUsername = (username, password, type, key, code) =>
    request({
        url: '/api/framework-auth/oauth/token',
        method: 'post',
        headers: {
            'Tenant-Id': tenantId,
            'Captcha-Key': key,
            'Captcha-Code': code,
            'User-Type': 'web'
        },
        params: {
            tenantId,
            username,
            password,
            grant_type: website.captchaMode ? 'captcha' : 'password',
            scope: 'all',
            type
        }
    })

export const refreshToken = (refresh_token) =>
    request({
        url: '/api/framework-auth/oauth/token',
        method: 'post',
        headers: {
            'Tenant-Id': tenantId
        },
        params: {
            tenantId,
            refresh_token,
            grant_type: 'refresh_token',
            scope: 'all'
        }
    })

export const registerGuest = (form, oauthId) =>
    request({
        url: '/api/framework-user/register-guest',
        method: 'post',
        params: {
            tenantId: form.tenantId,
            name: form.name,
            account: form.account,
            password: form.password,
            oauthId
        }
    })

export const getButtons = () =>
    request({
        url: '/api/framework-system/menu/buttons',
        method: 'get'
    })

export const getCaptcha = () =>
    request({
        url: '/api/framework-auth/oauth/captcha',
        method: 'get'
    })

export const getSmsCode = () =>
    request({
        url: '/api/framework-auth/oauth/smscode',
        method: 'get'
    })

export const logout = () =>
    request({
        url: '/api/framework-auth/oauth/logout',
        method: 'get'
    })

export const getUserInfo = () =>
    request({
        url: '/api/framework-auth/oauth/user-info',
        method: 'get'
    })

export const sendLogs = (list) =>
    request({
        url: '/api/framework-auth/oauth/logout',
        method: 'post',
        data: list
    })

export const clearCache = () =>
    request({
        url: '/api/framework-auth/oauth/clear-cache',
        method: 'get'
    })


export const postChangePwd = (pwd1, pwd2, pwd3) => 
    request({
        url: '/api/framework-user/business/update-password',
        method: 'post',
        params: {
            oldPassword: pwd1,
            newPassword: pwd2,
            confirmPassword: pwd3,
          }
    })

// export function postChangePwd (pwd1, pwd2, pwd3) {
//     return request(``, {
//       oldPassword: pwd1,
//       newPassword: pwd2,
//       confirmPassword: pwd3,
//     }, {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'User-Type': 1
//       }
//     });
//   }
  