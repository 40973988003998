/**
 * 全局配置文件
 */
export default {
    title: 'DHC2023',
    logo: 'S',
    key: 'dhc-web', //配置主键,目前用于存储
    indexTitle: 'DHC2023',
    clientId: 'dhc-web', // 客户端id
    clientSecret: 'I{4}:@}Rh.GK.P5x&gt;T@eYZzBl%3q-!', // 客户端密钥
    tenantMode: false, // 是否开启租户模式
    tenantId: '000000', // 管理组租户编号
    captchaMode: false, // 是否开启验证码模式
    switchMode: false, // 是否开启部门切换模式
    lockPage: '/lock',
    tokenTime: 1200, // 定时刷新token 单位：秒
    tokenHeader: 'Framework-Auth',
    //http的status默认放行列表
    statusWhiteList: [],
    // 第三方系统授权地址
    authUrl: 'http://localhost:8000/framework-auth/oauth/render',
}
