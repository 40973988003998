import { setToken, setRefreshToken, removeToken, removeRefreshToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import { setStore, getStore } from '@/utils/store'
import { validatenull } from '@/utils/validate'
import { loginByUsername, getUserInfo, logout, refreshToken } from '@/api/login'
import md5 from 'js-md5'

const user = {
    state: {
        userInfo: getStore({ name: 'userInfo' }) || [],
        permission: getStore({ name: 'permission' }) || {},
        token: getStore({ name: 'token' }) || '',
        refreshToken: getStore({ name: 'refreshToken' }) || ''
    },
    actions: {
        //根据用户名登录
        LoginByUsername({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                loginByUsername(userInfo.username, md5(userInfo.password), userInfo.type, userInfo.key, userInfo.code)
                    .then((res) => {
                        const data = res.data
                        if (data.error_description) {
                            ElMessage({
                                message: data.error_description,
                                type: 'error'
                            })
                        } else {
                            commit('SET_TOKEN', data.access_token)
                            commit('SET_REFRESH_TOKEN', data.refresh_token)
                            commit('SET_USER_INFO', data)
                        }
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        //根据手机号登录
        LoginByPhone({ commit }, userInfo) {
            return new Promise((resolve) => {
                loginByUsername(userInfo.phone, userInfo.code).then((res) => {
                    const data = res.data.data
                    commit('SET_TOKEN', data)
                    resolve()
                })
            })
        },
        //获取用户信息
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo()
                    .then((res) => {
                        const data = res.data.data
                        commit('SET_TOKEN', data)
                        resolve(data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        //刷新token
        refreshToken({ state, commit }, userInfo) {
            window.console.log('handle refresh token')
            return new Promise((resolve, reject) => {
                refreshToken(state.refreshToken, state.tenantId, !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id, !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id)
                    .then((res) => {
                        const data = res.data
                        commit('SET_TOKEN', data.access_token)
                        commit('SET_REFRESH_TOKEN', data.refresh_token)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve, reject) => {
                logout()
                    .then(() => {
                        commit('SET_TOKEN', '')
                        removeToken()
                        removeRefreshToken()
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        //注销session
        FedLogOut({ commit }) {
            return new Promise((resolve) => {
                commit('SET_TOKEN', '')
                removeToken()
                removeRefreshToken()
                resolve()
            })
        },
       
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            setToken(token)
            state.token = token
            setStore({ name: 'token', content: state.token })
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            setRefreshToken(refreshToken)
            state.refreshToken = refreshToken
            setStore({ name: 'refreshToken', content: state.refreshToken })
        },
        SET_USER_INFO: (state, userInfo) => {
            if (validatenull(userInfo.avatar)) {
                userInfo.avatar = '/img/bg/img-logo.png'
            }
            state.userInfo = userInfo
            setStore({ name: 'userInfo', content: state.userInfo })
        }
       
    }
}
export default user
