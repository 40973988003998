import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from "./router";
import  { store } from './store/'
import './assets/css/normalize.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import VueFlags from "@growthbunker/vueflags"
import CountryFlag from 'vue-country-flag-next';
import moment from "moment";
moment.locale('zh-cn');

const app = createApp(App);
app.config.globalProperties.$moment = moment
// app.use(VueFlags)
app.use(CountryFlag);
app.use(ElementPlus);
app.use(store);
setupRouter(app);
app.mount('#app');
