<template>
  <HeaderIndex />
  <BgMenu title="Registration" :uri="require('../../assets/img/register/register.png')" />
  <div class="registerOrder">
    <div class="header">
      <div class="text"></div>
    </div>
    <div class="radioContent">
      <div class="title">1.Registrant category<sup class="sup">*</sup></div>
      <el-radio-group class="radioBox" v-model="radiokey1">
        <el-radio :label="item.key"
          v-for="item in radiodata1" :key="item.key">{{item.title}}</el-radio>
      </el-radio-group>
      <div class="note">Note: For student presenters, please select "Student".</div>
    </div>
    <div class="radioContent">
      <div class="title">2.Participation method<sup class="sup">*</sup></div>
      <el-radio-group class="radioBox" v-model="radiokey2">
        <el-radio :label="item.key"
          v-for="item in radiodata2" :key="item.key">{{item.title}}</el-radio>
      </el-radio-group>
    </div>
    <div class="radioContent radioContent3">
      <div class="title">3.Personal information</div>
      <div class="center" style="margin-left: 20px;">
        <div class="text">Given name<sup class="sup">*</sup></div>
        <el-input class="input" v-model="givenName" style="width: 200px;"/>
      </div>
      <div class="center" style="margin-left: 20px;">
        <div class="text">Surname<sup class="sup">*</sup></div>
        <el-input class="input" v-model="surName" style="width: 200px;"/>
      </div>
      <div class="center" style="margin-left: 20px;">
        <div class="text">Title<sup class="sup">*</sup></div>
        <el-select v-model="title" placeholder="Please choose" style="width: 200px;">
        <el-option
          v-for="item in optiondata3"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </div>
      <div class="center" style="margin-left: 20px;">
        <div class="text">Affiliation<sup class="sup">*</sup></div>
        <el-input class="input" v-model="affiliation" style="width: 200px;"/>
      </div>
      <div class="center" style="margin-left: 20px;">
        <div class="text">Country<sup class="sup">*</sup></div>
        <el-select v-model="country" placeholder="Please choose" style="width: 200px;">
        <el-option
          v-for="item in countryData"
          :key="item.numberCode"
          :label="item.ENname"
          :value="item.numberCode">
        </el-option>
      </el-select>
      </div>
    </div>
    <div class="radioContent radioContent4">
      <div class="title">4.Other information</div>
      <div class="center" v-if="radiokey1 !== 1" style="margin-left: 20px;">
        <div class="text">Paper ID</div>
        <el-input class="input" v-model="paperId" style="width: 200px;"/>
        <div class="note">If you are a paper presenter, including a student presenter, please fill in the ID of your paper provided by the SC.</div>
      </div>
      <div class="center" v-if="radiokey1 === 3" style="margin-left: 20px;">
        <div class="text" style="width: 500px;">Upload student certification materials<sup class="sup">*</sup></div>
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :http-request="upload"
          :on-exceed="handleExceed"
          :file-list="fileList">
          <el-button size="small" type="primary">Upload</el-button>
        </el-upload>
        <div class="note">Please submit an electronic copy of a valid letter of enrollment, student ID, or other supporting documents.</div>
      </div>
      <div class="center" v-if="radiokey2 === 1" style="margin-left: 20px;">
        <div class="text">Special dietary requirements</div>
        <el-select v-model="optionkey4" placeholder="Please choose" style="width: 200px;">
        <el-option
          v-for="item in optiondata4"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input class="input" style="margin-left: 40px; width: 200px;" v-model="specialDietary" v-if="optionkey4 === 3"/>
      </div>
    </div>
    <div class="radioContent radioContent5" v-if="radiokey2 === 1">
      <div class="title">5.Invitation letter for visa application</div>
      <div class="center" style="margin-left: 20px;">
        <el-select v-model="letter" placeholder="Please choose" style="width: 200px;">
          <el-option
            v-for="item in letterData"
            :key="item.label"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <div class="note">
          Please refer to https://www.dhc2023.com.cn/information_news?id=1659084898348236802 for more information about visa application. It is recommended that you apply for a Tourism Visa (category L), which does not require an invitation letter, but only itinerary documents. If you need to apply for the Academic Exchange (category F) visa, which requires an invitation letter, please select "Yes", and send the relevant information mentioned in https://www.dhc2023.com.cn/information_news?id=1659084898348236802 to contact@dhc2023.com.cn. We will assist you with the invitation letter and come back to you shortly.
        </div>
      </div>
    </div>
    <div class="textBox">
      <div class="textBoxFlex">
        <span class="text">Your registration fee is</span>
        <span class="price">{{ price[`${radiokey1}-${radiokey2}-${radiokey3}`].txtcny }}</span>
      </div>
    </div>
    <TitleIndex title="Notice" />
    <div class="listBox">
      Please confirm the information you fill in. If the attendee status you selected does not match the actual situation, the organizing committee will contact you to refund and register again. Otherwise, your registration may be invalid. Clicking the button below means you have read this reminder. You will submit your information and proceed to the payment page.
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancel">Cancel</div>
      <div class="submission" @click="submission">Submission</div>
    </div>
  </div>
  <FooterIndex />
</template>

<script>
import HeaderIndex from '../../components/header/index.vue';
import FooterIndex from '../../components/footer/index.vue';
import TitleIndex from '../../components/title/index.vue';
import BgMenu from '../../components/bg/menu.vue';
import priceData from './price.js';
import countryData from './country.min.js';
import { payOrder } from '@/api/payment';
// import { updateOrderUserFile } from '@/api/data';
import { orderPayPre, updateOrderUserFile } from '@/api/payment';

export default {
  name: 'RegisterNext',
  data() {
    return {
      radiokey1: 1,
      radiodata1: [
        {
          title: 'General participant',
          key: 1
        },
        {
          title: 'Presenter',
          key: 2
        },
        {
          title: 'Student',
          key: 3
        },
      ],
      radiokey2: 1,
      radiodata2: [
        {
          title: 'On-site',
          key: 1
        },
        {
          title: 'Online',
          key: 2
        }
      ],
      // 2023-06-30 1688083200000
      // 2023-07-15 23:59 1689436740000
      // test time 2023-06-29 23:59 1688054340000
      radiokey3: new Date().getTime() > 1689436740000 ? 2 : 1,
      title: 1,
      optiondata3: [{
          value: 1,
          label: 'Prof.'
        }, {
          value: 2,
          label: 'Dr.'
        }, {
          value: 3,
          label: 'Mr.'
        }, {
          value: 4,
          label: 'Ms.'
      }],
      country: '156',
      countryData,
      optionkey4: void 0,
      optiondata4: [{
        value: 1,
        label: 'Vegetarian.'
      }, {
        value: 2,
        label: 'Muslim.'
      }, {
        value: 3,
        label: 'Others.'
      }],
      letter: void 0,
      letterData: [{
        value: 1,
        label: 'Yes.'
      }, {
        value: 2,
        label: 'No.'
      }],
      hasToken: false,
      price: priceData,
      //
      givenName: '',
      surName: '',
      affiliation: '',
      paperId: '',
      specialDietary: '',
      // 上传
      fileList: [],
      materials: '',
      payType: ''
    }
  },
  props: {},
  components: {
    HeaderIndex,
    FooterIndex,
    BgMenu,
    TitleIndex
  },
  beforeMount() {
    this.hasToken = Boolean(this.$store.state.user.token);
    this.payType = this.$router.currentRoute.value.query.payType
  },
  methods: {
    cancel() {
      this.$router.push({ path: '/registration/pre'});
    },
    submission() {
      console.log('this.materials', this.materials)
      if (this.radiokey1 === 3 && !Object.keys(this.materials).length) return;
      if (!this.givenName || !this.affiliation || !this.surName) return;
      //
      // const mchOrderNo = this.randomOrderNo();
      let amount = this.price[`${this.radiokey1}-${this.radiokey2}-${this.radiokey3}`].cn;
      orderPayPre(
        this.affiliation,
        amount * 100,
        this.country,
        new Date().getTime() > 1689436740000 ? 2 : 1,
        this.givenName,
        this.materials,
        '', //mchOrderNo
        this.paperId,
        this.radiokey2,
        this.radiokey1,
        this.specialDietary,
        this.optionkey4,
        this.surName,
        this.title,
        this.letter
      ).then(resp => {
        console.log(resp)
        let mchOrderNo= resp.data.data
        payOrder({
          // wayCode: 'ICBC_FOREIGN', // 工商银行支付
          wayCode: this.payType=='paypal' ? 'PP_PC' : 'ICBC_FOREIGN', // Paypal支
          amount: this.payType=='paypal' ? amount : amount, // 支付金额
          appId: '6377d154edaa11a645657787', // appId
          mchOrderNo, // 订单编号
          payDataType: 'payurl', // 支付参数（二维码，条码）
          authCode: '', // 条形码
          divisionMode: 0, // 订单分账模式,
          orderTitle: 'The 18th International Symposium on District Heating & Cooling'
        }).then(res => {
          console.log(res);
          console.log(res.data.data.payData);
          
          if (res.data.data.payData.indexOf('<form') >= 0) {
            const pathInfo = this.$router.resolve({
              path: '/registration/postpage',
              query: {
                  formData: res.data.data.payData
              }
            })
            window.open(pathInfo.href, '_blank')
          } else {
              window.open(res.data.data.payData)
          }
          // that.$refs.payTestModal.showModal(this.currentWayCode, res) // 打开弹窗
          // that.randomOrderNo() // 刷新订单号
        }).catch(() => {
          // that.$refs.payTestBarCode.processCatch()
          // that.randomOrderNo() // 刷新订单号
        })
      })
    
    },
    // 刷新订单号
    randomOrderNo () {
       return 'M' + new Date().getTime() + Math.floor(Math.random() * (9999 - 1000) + 1000)
    },
    // 上传相关
    upload(param) {
      const formData = new FormData();
      formData.append('file', param.file);
      updateOrderUserFile(formData).then(res => {
        console.log('uploading success', res)
        this.materials = JSON.stringify({url: res.data.data.link, filename: res.data.data.originalName})
        console.log(this.materials);

      }).catch(response => {
        console.log('uploading fail', response)
      })
    },
    handleRemove(file, _FILELIST) {
      console.log(file, _FILELIST);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, _FILELIST) {
      console.log('upload files', files, _FILELIST);
      this.$message.warning(`Currently limit uploading one file.`);
    },
    beforeRemove(file, _FILELIST) {
      console.log(_FILELIST)
      return this.$confirm(`Definitive removal ${ file.name }?`);
    }
  }
}
</script>

<style scoped lang="less">
.registerOrder {
  width: 1280px;
  margin: 40px auto;
  .upload-demo {
    margin: 20px;
  }
  .header {
    .text {
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      .link {
        color: rgba(0, 192, 153, 1);
        // text-decoration-line: underline;
      }
    }
  }
  .titleBox {
    margin-top: 32px;
    .text {
      color: rgba(235, 87, 87, 1);
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
    }
  }
  .radioContent {
    margin-top: 32px;
    .note {
      font-size: 18px;
      margin-top: 10px;
      background: #00c09a1a;
      padding: 20px;
      border-radius: 5px;
      line-height: 1.8;
    }
    .center {
      margin-top: 20px;
      .text {
        color: #333333;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        margin-right: 20px;
        // width: 200px;
      }
      .text, .input {
        display: inline-block;
      }
      .input {
        width: 150px;
      }
    }
    .title {
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
      font-size: 24px;
    }
    .radioBox {
      margin-top: 20px;
      /deep/ .el-radio {
        margin-right: 58px;
        &.is-checked {
          .el-radio__label {
            color: rgba(0, 192, 153, 1);
          }
          .el-radio__inner {
            position: relative;
            background: transparent;
            overflow: hidden;
            &::after {
              position: absolute;
              top: 2px;
              left: 6px;
              content: '';
              width: 5px;
              height: 9px;
              border-color: rgba(0, 192, 153, 1);
              border-style: solid;
              border-width: 0 1.5px 1.5px 0;
              transform: rotate(40deg);
              border-radius: 0;
            }
          }
        }
        .el-radio__label {
          color: rgba(51, 51, 51, 1);
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
        }
        .el-radio__inner {
          width: 20px;
          height: 20px;
          border: 1px solid rgba(0, 192, 153, 1);
        }
      }
    }
  }
  .textBox {
    margin: 40px 0;
    width: 1280px;
    height: 96px;
    background: rgba(251, 250, 250, 1);
    padding: 24px;
    .textBoxFlex {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      .text {
        color: rgba(153, 153, 153, 1);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
      .price {
        margin-left: auto;
        color: rgba(235, 87, 87, 1);
        font-family: OPPOSans-Heavy;
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
  .listBox {
    margin-top: 30px;
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  .btnBox {
    margin: 64px 0;
    text-align: center;
    .cancel, .submission {
      display: inline-block;
      width: 172px;
      height: 48px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 20px;
      line-height: 48px;
      text-align: center;
    }
    .cancel {
      border: 2px solid rgba(0, 192, 153, 1);
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 192, 153, 1);
      margin-right: 40px;
      cursor: pointer;
    }
    .submission {
      background: rgba(0, 192, 153, 1);
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
  .sup {
    color: red;
  }
  .radioContent3 > .center > .text, .radioContent4 > .center > .text {
    width: 300px;
  }
}
</style>
