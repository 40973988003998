<template>
  <div class="homeDatas">
    <!-- <TitleIndex title="Supporter" />
    <div class="supporter">
      <div class="card" v-for="(item, i) in supporter" :key="i">
        <img :src="item.images" alt="" />
      </div>
    </div> -->
    <TitleIndex title="Sponsor" />
    <div class="news">
      <div class="content">
        <div class="prev" @click="prev">
          <img :src="require('../../assets/img/home/翻页_Property_1_向后@3x.png')" alt="">
        </div>
        <div class="next" @click="next">
          <img :src="require('../../assets/img/home/翻页_Property_1_向前@3x.png')" alt="">
        </div>
        <div class="box">
          <div class="card" v-for="(item, i) in imgsList" :key="i">
            <img :src="item.images" alt="" @click="toWebHome(item.src)">
          </div>
        </div>
      </div>
      <!-- <div class="soon">
        <img class="soonImg" :src="require('../../assets/po/用于首页赞助商@3x.png')" alt="">
        <div class="soonTxt">Coming soon…</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import TitleIndex from '../../components/title/index.vue';
export default {
  name: 'HomeDatas',
  props: {
    sponsor: Array,
    supporter: Array
  },
  data () {
    return {
      imgs: [
        require('../../assets/po/image_9@3x.png'),
        require('../../assets/po/image_7@3x.png'),
        require('../../assets/po/image_8@3x.png')
      ],
      imgsList: [
      ],
      index: 0,
      setIntervalId: void 0
    }
  },
  components: {
    TitleIndex
  },
  watch: {
    sponsor: {
      handler(_n) {
        if (_n.length) {
          this.imgsList = _n.slice(0, 3);
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeMount() {
    this.imgsList = this.sponsor.slice(0, 3);
  },
  mounted() {
    this.setIntervalId = setInterval(() => {
      this.next();
    }, 3000);
  },
  unmounted() {
    clearInterval(this.setIntervalId);
  },
  methods: {
    toWebHome(src) {
      window.open(src || 'https://google.com')
    },
    buildImgData(curIndex) {
      const len = this.sponsor.length;
      if (len <= 3) return;
      const data = [this.sponsor[curIndex]];
      let nextIndex = curIndex + 1;
      while(data.length < 3) {
        nextIndex = nextIndex > len - 1 ? 0 : nextIndex;
        data.push(this.sponsor[nextIndex])
        nextIndex = nextIndex + 1;
      }
      this.imgsList = data;
    },
    prev() {
      let nextIndex = this.index - 1;
      nextIndex = nextIndex >= 0 ? nextIndex : this.sponsor.length - 1;
      this.index = nextIndex;
      this.buildImgData(nextIndex);
    },
    next() {
      let nextIndex = this.index + 1;
      nextIndex = nextIndex > this.sponsor.length - 1 ? 0 : nextIndex;
      this.index = nextIndex;
      this.buildImgData(nextIndex);
    }
  }
}
</script>

<style lang="less" scoped>
.homeDatas {
  width: 1280px;
  height: auto;
  margin: 20px auto 40px;
  .supporter {
    margin-top: 24px;
    margin-bottom: 40px;
    .card {
      width: 405px;
      height: 200px;
      border-radius: 4px;
      border: 0px solid rgba(0, 192, 153, 1);
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 16px rgba(0, 109, 103, 0.12);
    }
  }
  .news {
    text-align: center;
  }
  .content {
    position: relative;
    display: inline-block;
    width: 1024px;
    height: 200px;
    margin: 40px auto 0;
    .prev, .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .prev {
      left: -76px;
    }
    .next {
      right: -76px;
    }
    .box {
      width: 100%;
      height: 210px;
      overflow: hidden;
      .card {
        display: inline-block;
        width: 320px;
        height: 200px;
        border-radius: 4px;
        border: 0px solid rgba(0, 192, 153, 1);
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 16px rgba(0, 109, 103, 0.12);
        margin-right: 32px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding: 20px;
          cursor: pointer;
        }
        &:nth-of-type(3) {
          margin-right: 0;
        }
      }
    }
  }
  .soon {
    display: flex;
    width: 1280px;
    height: 226px;
    border-radius: 4px;
    border: 0px solid rgba(0, 192, 153, 1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 16px rgba(0, 109, 103, 0.12);
    margin-top: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .soonImg {
      display: inline-block;
      width: 56px;
      height: 56px;
    }
    .soonTxt {
      text-align: center;
      color: rgba(153, 153, 153, .4);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      margin-top: 7px;
    }
  }
}
</style>
