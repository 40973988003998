<template>
  <div class="paperResearch">
    <div class="txt0">The Scientific Committee of DHC2023 welcomes researchers to submit abstracts on all aspects of district heating and cooling. Topics of interest for submission include, but are not limited to:</div>
    <!-- <div class="card" v-for="(item, i) in data" :key="i">
      <div class="title">{{item.title}}</div>
      <div class="txt">{{item.txt}}</div>
    </div> -->
    <div class="card2" v-for="(item, i) in data2" :key="i">
      <div class="title">{{item}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaperResearch',
  data() {
    return {
      data: [
        {
          title: 'A. Urban energy systems, planning and development',
          txt: 'How does city planning influence the development of district hearing and cooling sysiems and vice versa? Papers in This section explore district heating and cooling as a driving force for city planning and development including development of large or regional heat networks.'
        },
        {
          title: 'B. Resource efficiency and environmental performance',
          txt: 'How can district heating and cooling contribute to an efficient and low carbon energy supply? Papers in this section will consider performance and environmental issues in district heating and cooling, including the integration of renewables, primary energy savings, reduction of C02 emissions, combined heat and power and The use of thermal storage.'
        },
        {
          title: 'C. Key elemenis in District Heating and Cooling systems',
          txt: 'What are the new developments in district heating and cooling technology?\nPapers in this section consider steps towards the next generation of district heating and cooling. They focus on improving current district heating and cooling solutions and key elements of the technology: for example piping systems, sub stations and metering techniques. This area includes issues regarding cost reduction^ the increase of service life】 demand side management: and low Temperature technologies.'
        },
        {
          title: 'D.  Modelling and Simulation of DHC Systems',
          txt: 'How does city planning influence the development of district hearing and cooling sysiems and vice versa? Papers in This section explore district heating and cooling as a driving force for city planning and development including development of large or regional heat networks.'
        },
        {
          title: 'E. Digitalisation of DHC systems',
          txt: 'How does city planning influence the development of district hearing and cooling sysiems and vice versa? Papers in This section explore district heating and cooling as a driving force for city planning and development including development of large or regional heat networks.'
        }
      ],
      data2: [
        'Urban energy systems, planning and development',
        'Efficiency and environmental performance',
        'DHC systems aiming at carbon peaking and carbon neutrality',
        'Modelling and simulation of DHC systems',
        'RES and waste heat sources for DH',
        'CHP transformation, co-operated heat and power systems',
        'Hybrid energy systems and smart grids',
        'Digitalization of DHC systems',
        'Policy, regulation, and market issues',
        '4GDH concepts, future DH systems',
        'GIS for energy systems, heat planning and DH',
        'Others'
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.paperResearch {
  width: 1280px;
  margin: 32px auto 0;
  .card2 {
    position: relative;
    width: 1280px;
    height: 64px;
    border-radius: 4px;
    background: rgba(251, 250, 250, 1);
    padding: 22px 60px;
    margin-top: 20px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: rgba(0, 192, 153, 1);
    }
    .title {
      color: rgba(51, 51, 51, 1);
      font-family: OPPOSans;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }
  }
  .txt0 {
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    padding: 10px;
    margin-bottom: 32px;
  }
  .card {
    margin-top: 10px;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    .title {
      color: rgba(0, 192, 153, 1);
      font-family: OPPOSans-Heavy;
      font-size: 18px;
      line-height: 36px;
      padding: 10px;
    }
    .txt {
      width: 1280px;
      padding: 24px;
      background: rgba(251, 250, 250, 1);
      color: rgba(102, 102, 102, 1);
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      margin-top: 8px;
    }
  }
}
</style>
